/* ERROR-SERVICE
   -------------------------------
   - Fehlerverarbeitung
*/

import { Injectable } from '@angular/core';
import { Subscriber } from 'rxjs';

import * as Sentry from "@sentry/angular";

import { SnackbarService } from './snackbar.service';


@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    public errorMessages: any;

    constructor(
        public snackbar: SnackbarService,
    ) {
        this.errorMessages = {
            0: 'undefinierter Fehlercode',
            1051: 'App-Variant nicht definiert',
            1062: 'Variant-function.js konnte nicht geladen werden',
            1100: 'AppConfig.getConfig() - service has not finished loading the config. It should never occur.',
            1101: 'App-Konfiguration konnte nicht aus der Datenbank geladen werden',
            1102: 'Custom-Konfiguration nicht vorhanden oder Fehler beim Auslesen der Custom-Konfiguration',
            1103: 'Fehler beim Auslesen (Parsen) der App-Konfiguration',
            1104: 'Fehler beim Entschlüsseln der Konfiguration',
            1106: 'Fehler beim Laden der Benutzergruppen-Konfiguration: keine gültige Benutzergruppe übergeben.',
            1107: 'Fehler beim Laden der Benutzergruppen-Konfiguration: Konnte nicht ausgelesen werden',
            1108: 'Fehler beim Laden der Benutzer-Konfiguration: Konnte nicht ausgelesen werden',
            1110: 'Fehler beim Auslesen der Tabellen-Konfiguration',
            1111: 'Fehler beim Auslesen der Tabellen-Konfiguration (config)',
            1113: 'Fehler in der Tabellen-Konfiguration (keine Spalten definiert)',
            1114: 'Fehler in der Tabellen-Konfiguration (Feldtyp nicht definiert)',
            1115: 'Fehler in der Tabellen-Konfiguration (Unbekannter Feldtyp)',
            1120: 'Fehler beim Auslesen der Formular-Konfiguration',
            1121: 'Fehler beim Auslesen der Formular-Konfiguration (config)',
            1123: 'Fehler in der Formular-Konfiguration (keine Steps definiert)',
            1124: 'Fehler in der Formular-Konfiguration (Step-Typ nicht definiert)',
            1125: 'Fehler in der Formular-Konfiguration (Unbekannter Steptyp)',
            1126: 'Fehler in der Formular-Konfiguration (StepTable nicht definiert)',
            1127: 'Fehler in der Formular-Konfiguration (angegebenes Feld ist nicht in der Tabelle definiert)',
            1128: 'Fehler in der Formular-Konfiguration (keine Felder für Zusammenfassung gefunden)',
            1130: 'Fehler beim Auslesen der Ansichts-Konfiguration',
            1131: 'Fehler beim Auslesen der Ansichts-Konfiguration (config)',
            1132: 'Fehler in der Ansichts-Konfiguration (keine Wrapper definiert)',
            1133: 'Fehler in der Ansichts-Konfiguration (Wrapper-Typ nicht definiert)',
            1134: 'Fehler in der Ansichts-Konfiguration (kein Inhalt definiert)',
            1135: 'Fehler in der Ansichts-Konfiguration (Inhaltstyp nicht definiert)',
            1136: 'Fehler in der Ansichts-Konfiguration (unbekannter Inhaltstyp)',
            1137: 'Fehler in der Ansichts-Konfiguration (unbekannter Inhaltstyp)',
            1161: 'Limits falsch / unvollständig definiert',
            1171: 'Kein Login-Pfad für Gastlogin angegeben',
            1201: 'Keine App-Berechtigung (checkAuth = false) aus auth.service',
            1202: 'Keine App-Berechtigung (checkAuth = false) aus data.service init()',
            1203: 'Keine App-Berechtigung (checkAuth = false) aus data.service checkInitAndAuth()',
            1210: 'Diese Operation benötigt Schreibrechte, aber es sind nur Leserechte vorhanden',
            1211: 'Diese Operation ist nur mit vollen Schreibrechten verfügbar.',
            1220: 'Diese Operation ist im anonymen Modus nicht verfügbar.',
            1230: 'Diese Operation ist im Gastmodus nicht verfügbar.',
            1241: 'Das Anlegen von neuen Datensätzen in dieser Tabelle ist für Demo-User nicht erlaubt.',
            1311: 'Userdaten konnten nicht korrekt aus LocalStorage ausgelesen werden. (Fehler bei JSON parse)',
            2001: 'Aufgerufene Tabelle ist nicht definiert',
            2050: 'Übergebener Filter nicht vorhanden',
            2051: 'Filter kann nicht gelöscht werden, weil er anscheinend nicht vorhanden ist',
            2716: 'Konfigurationsfehler, kein gültiger Wert für autoIncrementRow',
            2718: 'BatchDateAdd: Keine Daten in batchDates.',
            2751: 'Konfigurationsfehler, erlaubte Dateitypen nicht definiert',
            2755: 'Fehler beim Upload',
            2756: 'Fehler beim Upload',
            2772: 'Ungültigen Datensatz zum Zusammenführen ausgewählt',
            2791: 'Ungültige Dateiendung für Importdatei',
            2793: 'Fehlende Spalte ‚id‘ in mindestens einer Zeile. Betreffende Zeilen können nicht importiert werden.',
            2801: 'Aufgerufene Tabelle ist nicht definiert.',
            2802: 'Die Anzeige einzelner Einträge dieser Tabelle ist deaktiviert.',
            2810: 'Keine ID übergeben.',
            2811: 'Aufgerufene ID konnte nicht gefunden werden.',
            2821: 'Konfigurationsfehler: Verknüpfte Tabelle (JDT) besitzt keine zugehörige Spalte',
            2831: 'Konfigurationsfehler: Statistik besitzt keine zugehörige Spalte',
            2880: 'Keine ID übergeben.',
            2911: 'Anwesenheitsliste kann nur für Tabellen Anwesenheit oder Anwesenheit Trainer angezeigt werden.',
            2912: 'Keine Gruppen vorhanden. Es müssen Gruppen definiert sein, um eine Anwesenheitsliste anzuzeigen.',
            2913: 'Konnte Gruppen nicht laden.',
            2920: 'Fehlerhafter Aufruf, keine ID übergeben',
            2921: 'Fehlerhafter Aufruf, keine Gruppen-ID übergeben',
            2922: 'Aufgerufene ID konnte nicht gefunden werden.',
            2930: 'Spielerbewertung ist nicht aktiviert.',
            2931: 'Daten konnten nicht gelesen werden oder keine Spielerdaten vorhanden.',
            2932: 'Bitte wähle eine*n Spieler*in aus!',
            2933: 'Aufgerufene ID konnte nicht gefunden werden.',
            3501: 'TableModel von BoxTable nicht gefunden.',
            3502: 'Keine ausreichende Berechtigung für diese Tabelle.',
            5011: 'Es wurde eine GuestId übergeben, obwohl kein GuestStep definiert ist',
            5020: 'Dieses Formular ist nur für angemeldete Benutzer / Gäste verfügbar.',
            5101: 'Konfigurationsfehler: Weder GuestStep noch MainStep definiert',
            5104: 'Konfigurationsfehler: Tabelle von Step nicht (korrekt) definiert',
            5132: 'Konfigurationsfehler: MainTable für SelectStep nicht (korrekt) definiert',
            5133: 'Konfigurationsfehler: MainTableCol für SelectStep nicht (korrekt) definiert',
            5142: 'Konfigurationsfehler: MainTable für ListStep nicht (korrekt) definiert',
            5143: 'Konfigurationsfehler: MainTableCol für ListStep nicht (korrekt) definiert',
            5151: 'Konfigurationsfehler: ListTable für ChecklistStep nicht (korrekt) definiert',
            5160: 'Konfigurationsfehler: Email Daten nicht korrekt definiert',
            5161: 'Konfigurationsfehler: EmailTo ist keine gültige Email-Adresse',
            5210: 'Auswahloptionen (SelectStep) konnten nicht geladen werden',
            5270: 'SelectStep Filter benötigt GuestUuid, es ist aber kein Gast angemeldet.',
            5330: 'Die Benutzerdaten konnten nicht gespeichert werden. Der Auftrag wurde abgebrochen.',
            5331: 'Die Benutzerdaten konnten nicht aktualisiert werden. Der Auftrag wurde abgebrochen.',
            5411: 'Es muss eine ID zum Bearbeiten eines Datensatzes übergeben werden.',
            5412: 'Es konnte kein Datensatz mit der angegeben ID gefunden werden. Beim Speichern wird ein neuer Datensatz angelegt.',
            5413: 'Es konnte kein Datensatz mit der angegeben ID gefunden werden. Das Formular ist daher nicht verfügbar.',
            5414: 'Es konnte kein aktiver Datensatz mit der angegeben ID gefunden werden. Beim Speichern wird ein neuer Datensatz angelegt.',
            5415: 'Es konnte kein aktiver Datensatz mit der angegeben ID gefunden werden. Das Formular ist daher nicht verfügbar.',
            5430: 'Die Benutzerdaten wurden gespeichert. Die sonstigen Formulardaten konnten nicht gespeichert werden.',
            5431: 'Die gesandten Daten konnten nicht gespeichert werden.',
            5432: 'Die Benutzerdaten wurden gespeichert. Die sonstigen Formulardaten konnten nicht aktualisiert werden.',
            5433: 'Die Daten konnten nicht gespeichert / aktualisiert werden.',
            5510: 'Daten für ListStep konnten nicht geladen werden.',
            5610: 'Daten für ChecklistStep konnten nicht geladen werden.',
            5630: 'Fehler beim Speichern in Tabelle (ChecklistStep)',
            6010: 'DataService nicht initialisiert',
            6015: 'Fehler beim initialen Laden der Daten in den Cache',
            6020: 'Fehler beim Warten auf Cache',
            6030: 'Verbindung durch Datenbankserver unterbrochen, bitte erneut anmelden!',
            6031: 'Keine Berechtigung, bitte anmelden!',
            6051: 'MakeDataArrays abgebrochen, weil cachingStatus[table] oder cachedData[table] nicht vorhanden.',
            6100: 'Fehler beim Lesen der Daten aus der Datenbank',
            6101: 'Fehler beim Lesen der Daten aus Cache',
            6102: 'Fehler beim Lesen der Daten, ID nicht gefunden',
            6151: 'MultiSelect: JSON-Wert aus DB konnte nicht geparsed werden. ',
            6161: 'Fehler beim Lesen der Limit-Daten',
            6162: 'Fehler beim Lesen der Limit-Zählers',
            6181: 'makeTemplatesAndAutoOptions: Tabelle wird gerade gecached, Abbruch',
            6200: 'Fehler beim Schreiben in die Datenbank',
            6201: 'Tabellen-Daten konnten nicht geladen werden, daher ist auch das Schreiben neuer Daten nicht möglich.',
            6261: 'Limit-Eintrag konnte nicht geschrieben werden',
            6300: 'Fehler beim Updaten der Daten in der Datenbank',
            6301: 'Tabellen-Daten konnten nicht geladen werden, daher ist auch das Bearbeiten der Daten nicht möglich.',
            6361: 'Limit-Eintrag konnte nicht upgedatet werden',
            6362: 'Limit-Zähler konnte nicht upgedatet werden',
            6400: 'Fehler beim Löschen der Daten aus der Datenbank',
            6401: 'Tabellen-Daten konnten nicht geladen werden, daher ist auch das Löschen der Daten nicht möglich.',
            6451: 'Fehler beim Löschen: Zugehörige Dateien konnten nicht gelöscht werden, weil Eintrag im Cache nicht gefunden wurde.',
            6461: 'Limit-Eintrag konnte nicht gelöscht werden',
            6462: 'Limit-Eintrag nicht vorhanden und konnte daher nicht gelöscht werden',
            6600: 'Fehler bei Zugriff auf kwi_users',
            6601: 'Fehler bei Zugriff auf kwi_users (Demo-User)',
            6602: 'Fehler bei Abfrage des API-Benutzers (me endpoint)',
            6603: 'Konflikt bei der Benutzer-ID. Bitte neu anmelden!',
            6611: 'Fehler beim Keepalive. Konnte nicht in Datenbank schreiben.',
            6612: 'Fehler beim Demo-User-Keepalive. Konnte nicht in Datenbank schreiben.',
            6710: 'Fehler bei der Anmeldung: Anmelde-Link ungültig',
            6711: 'Fehler bei der Anmeldung: Konto nicht aktiv',
            6712: 'Fehler bei der Anmeldung: Konto noch nicht aktiv',
            6713: 'Fehler bei der Anmeldung: Konto abgelaufen',
            6721: 'Fehler beim Keepalive: Gast nicht (mehr) gefunden',
            6911: 'Upload: Filemanager deaktiviert (in config.json)',
            6912: 'Upload: Keine API Session ID vorhanden',
            6919: 'Unbekannter Fehler beim ImageUpload – aus filemanager.postFile()',
            6921: 'Keine gültigen Daten der zu löschenden Dateien vorhanden.',
            6922: 'Info: Fehlerhafter Aufruf, keine Dateien zum Löschen gefunden',
            6923: 'Datei(en) konnten nicht gelöscht werden',
            6928: 'Info: Datei wurde nicht gelöscht, da sie sich nicht auf diesem Server befindet. (absolute URL)',
            6929: 'Unbekannter Fehler beim Löschen einer Datei - aus filemanager.deleteFile()',
            6971: 'Fehler bei Abfrage aus Dateiverzeichnis (checkFileRef)',
            6973: 'Fehler beim Schreiben von Eintrag in Dateiverzeichnis',
            6977: 'Eintrag konnte nicht aus Dateiverzeichnis gelöscht werden',
            6978: 'Keine Daten an DeleteFileFilter übergeben',
            6979: 'Fehler bei der Abfrage von DeleteFileFilter',
        };
    }

    public throw(code: number, options?: AppErrorOptions): AppError {
        /* - Gibt eine Fehlermeldung an die Konsole
           - Wenn observer angegeben: gibt an den observer einen .error() mit den error-Infos (als AppError) weiter
           - gibt den Fehler für ev. weiteren Gebrauch zurück
         */
        if (!options) options = {};
        const err = this.getError(code, options.msg);
        const snackbarMsg = options.snackbarMsg || options.msg || err.msg;
        console.error('Error ' + err.code + ': ' + err.msg);
        if (options?.observer) options.observer.error(err);
        if (options.snackbar) this.snackbar.showMessage(snackbarMsg, null, options.snackbarDuration);
        if (!options.noSentryCapture) {
            Sentry.captureMessage(`Error ${err.code}: ${err.msg}` + (options.snackbar ? ' | snackbar: ' + snackbarMsg : ''));
        }
        return err;
    }

    public getError(code: number, msg?: string): AppError {
        // Wenn keine Nachricht angegeben: versuchen, Nachricht aus Liste errorMessages zu holen:
        if (!msg) msg = this.errorMessages[code] || '';
        return { code, msg };
    }
}
export class AppError {
    msg: string;
    code? = 0;
}

interface AppErrorOptions {
    msg?: string;
    observer?: Subscriber<any>;
    snackbar?: boolean;
    snackbarMsg?: string;
    snackbarDuration?: number,
    noSentryCapture?: boolean;
}

