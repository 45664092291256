// Angular sanitizer übergehen, um z.B. <input> in [innerHTML] zu erlauben
// largely copied from https://stackoverflow.com/questions/38303065/inject-input-in-innerhtml-angular-2

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'safeHtml',
    standalone: false
})
export class SafeHtmlPipe implements PipeTransform {


  constructor(private _sanitizer: DomSanitizer) {
  }

  transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }

}
