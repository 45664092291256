<div class="menu-wrapper" mat-elevation-z8>
	<ng-container *ngIf="appMenu.menugroups.length > 0 && !appMenu.invalidMenu; else invalid">
		<div *ngFor="let group of appMenu.menugroups" class="menu-container menu">
			<mat-card *ngFor="let menupoint of group" (click)="navigate(menupoint)"
				[ngClass]="['element-menu-link', menupoint.mobile ? '' : 'hide-M-down', menupoint.color ? 'col-' + menupoint.color : '']">
				<mat-card-title>
					<mat-icon>{{ menupoint.icon }}</mat-icon>
					{{ menupoint.name }}
				</mat-card-title>
			</mat-card>
		</div>
	</ng-container>
	<ng-template #invalid>
		<h3 class="empty-result">
			Fehlerhafte Menükonfiguration <i class="material-icons">face</i>
		</h3>
	</ng-template>
	<app-background-image></app-background-image>
</div>