import { Component, OnInit } from '@angular/core';

import { LoadingService } from '../loading.service';
import { AppConfig } from 'src/app/config/app.config';
import { IAppConfig } from 'src/app/config/app-config.model';

@Component({
    selector: 'app-not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
    standalone: false
})
export class NotFoundComponent implements OnInit {
  appConfig: IAppConfig;

  constructor(
    private appConfigService: AppConfig,

    private rootLoad: LoadingService
  ) {
    this.appConfig = this.appConfigService.getConfig().appConfig;
  }

  ngOnInit(): void {
    this.rootLoad.set(false);
  }

}
