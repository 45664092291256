/* SNACKBAR - Simples Anzeigen von Status-Nachrichten
   derzeit keine Interaktion vorgesehen - Klick auf Action-Button blendet die Nachricht lediglich aus
   Interaktionen wären natürlich möglich (z.B. "Undo")
   siehe https://material.angular.io/components/snack-bar/overview
   Dafür wird das Snackbar-Objekt zurückgegeben.
*/

import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  public isActive = false;

  constructor(
    private snackbar: MatSnackBar,
  ) { }

  showMessage(message: string, action?: string, duration?: number): MatSnackBarRef<TextOnlySnackBar> {
    this.isActive = true;
    if (!action) action = 'x';      // Text für Action-Button, standardmäßig x (Schließen)
    if (duration === -1) duration = undefined;   // Wie lange bleibt die Meldung sichtbar? -1 => bis zum manuellen Schließen
    else if (!duration) duration = 5000;         // Standard 5 sec
    else duration = duration * 1000;
    setTimeout(() => { this.isActive = false; }, duration);
    return this.snackbar.open(message, action, { duration });
  }
}
