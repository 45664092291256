/* enthält AuthenticationService, AuthGuardUser, UnauthorizedInterceptor */

import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, Subscriber } from 'rxjs';
import { tap } from 'rxjs/operators'

import moment from 'moment';
import * as AES from 'crypto-js/aes';
import * as Utf8 from 'crypto-js/enc-utf8';
import { v1 as uuidv1 } from 'uuid';

import { environment } from '../../environments/environment';
import { GlobalConfig, IAppConfig } from '../config/app-config.model';
import { AppConfig, aesSecretKey } from '../config/app.config';
// import { SnackbarService } from '../shared/snackbar.service';
import { ErrorService } from '../shared/error.service';
import { LoadingService } from '../shared/loading.service';
import { DataService } from '../shared/data.service';



/* AuthenticationService managed den internen User-Login, -Logout und -Status */
@Injectable()
export class AuthenticationService implements OnDestroy {
  appConfig: IAppConfig;
  public apiSessionId: string;  // von API gesetzte Session ID (wird aus Cookie ausgelesen)
  public userLoggedin = false;
  public user = new User();
  public currentWriteUsers: string[] = [];  // Usernamen des Users (der User), die derzeit Vollzugriff haben 
  public forceReadonly = false; // Benutzer hat Lesezugriff bewusst abgegeben
  public guestLoggedin = false;
  public guest = new User(true);    // Hier verwenden wir auch User, weil wir außer session für Guest das Gleiche benötigen
  public userlogin$: Subject<void>;     // Verständigung bei UserLogin
  public cleanup$: Subject<void>;   // Cleanup bei UserLogout
  public currentRouteHasUnsavedChanges: boolean;  // wird von AppComponent bei jeder Navigation zurückgesetzt
  constructor(
    private router: Router,
    private appConfigService: AppConfig,
    private appError: ErrorService,
    // private rootLoad: LoadingService
  ) {
    this.getUserDataFromLocalStorage();
    // Gastlogin wird nur in SessionStorage gespeichert, da die API PHP Session ja auch abläuft
    if (sessionStorage.getItem('currentGuest')) {
      this.guest = Object.assign(this.guest, this.decrypt(sessionStorage.getItem('currentGuest')));
      this.guestLoggedin = true;
    }
    // AppConfig muss hier geladen werden, da es vorher offenbar noch nicht zur Verfügung steht und erst hier die UserRights geladen sind.
    this.appConfig = this.appConfigService.getConfig(this.user.userConfig).appConfig;

    this.getApiSessionCookie();
    this.userlogin$ = new Subject();
    this.cleanup$ = new Subject();
  }

  getUserDataFromLocalStorage() {
    if (localStorage.getItem('currentUser')) {
      this.user = Object.assign(this.user, this.decrypt(localStorage.getItem('currentUser')));
      this.forceReadonly = localStorage.getItem('forceReadonly') === 'true';
      this.userLoggedin = true;
    }
  }

  ngOnDestroy() {
    this.cleanup$.complete();
    this.userlogin$.complete();
  }

  userlogin(apiData): void {
    this.userLoggedin = true;
    this.user.name = apiData.username;
    this.user.id = apiData.id;
    this.user.userRights = apiData.user_rights ? apiData.user_rights.toString() : '';   // noch nicht in Verwendung / umgesetzt
    if (apiData.is_demo) {
      this.user.readonly = true;
      this.user.isDemo = true;
    }
    this.user.isAdmin = !!apiData.is_admin;
    if (apiData.user_config) {
      if (!isNaN(apiData.user_config)) {  // UsergroupConfig
        this.user.userGroup = parseInt(apiData.user_config);
        this.getUsergroupConfig().then((usergroupConfig) => {
          this.user.userConfig = usergroupConfig;
          this.finishUserlogin();
        }, (error) => {
          this.user.userConfig = '';
          this.finishUserlogin();
        });
      }
      else {  // userConfig direkt auslesen:

        // Funktion appConfig.decryptConfig() kann hier nicht verwendet werden, weil alle Config-Daten in einer einzigen Spalte stehen
        try {
          // Einzelne Spalten aus DB einlesen, entschlüsseln und speichern:
          const bytes = AES.decrypt(apiData.user_config.toString(), aesSecretKey[environment.variant]);
          const jsonData = bytes.toString(Utf8);
          this.user.userConfig = JSON.parse(jsonData);
          console.log('User Config fetched:', this.user.userConfig);
        } catch {
          this.appError.throw(1108, { snackbar: true });
        }
        this.finishUserlogin();
      }
    } else {
      this.user.userConfig = '';
      this.finishUserlogin();
    }
  }

  public finishUserlogin() {
    localStorage.setItem('currentUser', this.encrypt(this.user));
    // AppConfig muss hier geladen werden, da erst hier die UserRights geladen sind. (und es vorher offenbar auch noch nicht zur Verfügung steht)
    this.appConfig = this.appConfigService.getConfig(this.user.userConfig).appConfig;
    this.getApiSessionCookie();
    this.userlogin$.next();
  }

  public getUsergroupConfig(): Promise<GlobalConfig> {
    // UserGroup-Config auslesen
    return new Promise((resolve, reject) => {
      if (!this.user.userGroup || this.user.userGroup < 10) {
        reject(this.appError.throw(1106, { snackbar: true }));
      }
      this.appConfigService.getConfigFromApi(this.user.userGroup).then((userGroupConfig: GlobalConfig) => {      // userGroupConfig laden
        resolve(userGroupConfig);
      }, (error) => {
        reject(this.appError.throw(1107, { snackbar: true }));
      });
    })
  }

  userlogout(): void {
    // console.log('User Logout (App)');
    /* inklusive cleanup hier und cleanup trigger für andere components / services */
    this.userLoggedin = false;
    this.user = new User();
    localStorage.clear();         // LocalStorage löschen
    this.apiSessionId = undefined;
    this.cleanup$.next();         // weiteren cleanup triggern
    this.appConfig = this.appConfigService.getConfig('').appConfig; // '' ist wichtig, damit AppConfig zurückgesetzt wird

    // Testweise(?): Nach Logout einfach immer auf /login leiten. Macht vielleicht einfach am meisten Sinn...
    const url = this.router.url;
    this.router.navigate(['/login'], { queryParams: { returnUrl: url } })

    /* Alter Code - dynamische Weiterleitung:
      const url = this.router.url;
      this.router.onSameUrlNavigation = 'reload';  // Seite jedenfalls neuladen, auch wenn sich URL nicht ändert
      // Ist die aktuelle URL (möglicherweise) auch öffentlich zugänglich, auf diese weiterleiten:
      if (url.startsWith('/login') || url.startsWith('/guestlogin') || url.startsWith('/form') || url.startsWith('/view')) this.router.navigateByUrl(url);
      // ansonsten auf die Login-Seite weiterleiten (aktuelle URL als returnUrl übergeben)
      else this.router.navigate(['/login'], { queryParams: { returnUrl: url } });
      setTimeout(() => {
        this.router.onSameUrlNavigation = 'ignore';  // Standardeinstellung wieder herstellen
        this.rootLoad.set(false);
      }, 10); 
      */
  }

  checkAuth(showErrorInSnackbar?: boolean, allowGuest?: boolean, observer?: Subscriber<any>): boolean {
    // observer kann übergeben werden, um Fehlermeldung von appError weiterzuleiten
    if (!this.userLoggedin && !(allowGuest && this.guestLoggedin)) {
      this.appError.throw(1201, { snackbar: showErrorInSnackbar, noSentryCapture: true, observer });
      return false;
    }
    else return true;
  }

  guestlogin(id, name): void {
    this.guestLoggedin = true;
    this.guest.id = id;
    this.guest.name = name;
    sessionStorage.setItem('currentGuest', this.encrypt(this.guest));
  }

  guestlogout(): void {
    this.guestLoggedin = false;
    this.guest = new User(true);
    localStorage.clear();         // LocalStorage löschen
    sessionStorage.removeItem('currentGuest');
    this.cleanup$.next();         // weiteren cleanup triggern (DataService Cache löschen, etc.).
  }

  getApiSessionCookie() {
    // API Session ID aus Cookie laden via dem in der API und hier definierten Session Name:
    document.cookie.split(';').forEach((cookie) => {
      const data = cookie.split('=');
      if (data[0].trim() === 'kwimo_session') this.apiSessionId = data[1].trim();
    });
  }

  saveUserData() {
    localStorage.setItem('currentUser', this.encrypt(this.user));
    localStorage.setItem('forceReadonly', JSON.stringify(this.forceReadonly));
  }

  public encrypt(input: any): string {
    return AES.encrypt(JSON.stringify(input), aesSecretKey[environment.variant]).toString();
  }

  public decrypt(input: string): any {
    /* Entschlüsselt den String in input, verwandelt das JSON in ein Objekt und gibt es zurück: */
    const bytes = AES.decrypt(input, aesSecretKey[environment.variant]);
    const json = bytes.toString(Utf8);
    try {
      return JSON.parse(json);
    } catch (e) {
      this.appError.throw(1311);
    }
  }
}

export class User {
  name: string;
  id: string;
  session?: string;
  keepalive: string;
  readonly = false;
  isDemo = false;
  isAdmin = false;
  userConfig: GlobalConfig | '';   // UserConfig, die die AppConfig ( + TableConfig, etc.) nach Vorgabe in AllowUserConfig anpassen kann
  userGroup: number;
  userRights: string;   // Todo / noch nicht umgesetzt: durch Setzen von userRights kann der Zugriff auf Tabellen (zumindest via der App) eingeschränkt werden (analog zu guestRights / anonRights) 

  keepaliveNow() {
    this.keepalive = moment().format('YYYY-MM-DD HH:mm:ss');
  }
  constructor(isGuest?: boolean) {
    if (!isGuest) this.session = uuidv1();
    this.keepaliveNow();
  }
}


/* AuthGuardUser liefert AppRouting die Information, auf welche Seiten zugegriffen werden darf */
@Injectable()
export class AuthGuardUser {
  appConfig: IAppConfig;
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private appConfigService: AppConfig,
  ) {
    this.appConfig = this.appConfigService.getConfig().appConfig;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    // console.log('AuthGuardUser', route.url, this.auth.userLoggedin);
    // Eingeloggte User nicht auf die Login-Seite lassen:
    if (route.url[0]?.path === 'login') {
      if (this.auth.userLoggedin) {
        this.router.navigate(['']);
        return false;
      }
      else return true;
    }
    // Sonstige Seiten:
    if (this.auth.userLoggedin) return true;
    else {
      if (this.appConfig.guest?.table) this.router.navigate(['/guestlogin']);  // Wenn es einen Gästebereich gibt (Gästetabelle definiert), zu Guest-Login umleiten
      else {
        let returnUrl;
        if (!state.url.includes('/login') && !state.url.includes('/login')) returnUrl = state.url;
        this.router.navigate(['/login'], { queryParams: { returnUrl } });   // Sonst zu User-Login
      }
      return false;
    }
  }
}

/* AuthGuardAdmin erlaubt den Zugriff nur Admin-Usern */
@Injectable()
export class AuthGuardAdmin {
  constructor(
    private router: Router,
    private auth: AuthenticationService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    if (this.auth.userLoggedin && this.auth.user.isAdmin) return true;
    else {
      this.router.navigate(['menu']);
      return false;
    }
  }
}

/* RedirectGuest leitet an eingestelltes Login-Form oder -View weiter */
@Injectable()
export class RedirectGuest {
  appConfig: IAppConfig;
  constructor(
    private router: Router,
    private appError: ErrorService,
    private appConfigService: AppConfig,
  ) {
    this.appConfig = this.appConfigService.getConfig().appConfig;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    console.log('RedirectGuest', this.appConfig.guest.loginRoute, route.params.guestId);
    if (this.appConfig?.guest?.loginRoute) {
      this.router.navigate([this.appConfig.guest.loginRoute, route.params.guestId]);
      return false;
    }
    else {
      this.appError.throw(1171, { snackbar: true, noSentryCapture: true });
      return true;
    }
  }
}

/* UnauthorizedInterceptor überwacht die HttpRequests
   Wenn ein Error Status 401 (Not Authorized) von der API-Schnittstelle zurück kommt,
   wird der User ausgeloggt und die App zurückgesetzt.
*/
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    // private router: Router,
    private auth: AuthenticationService,
    // private snackbar: SnackbarService,
    private dataService: DataService,
    private appError: ErrorService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap({
      next: event => {
        // if (event instanceof HttpResponse) // do stuff with response if you want
      },
      error: (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401 && !err.url.endsWith('/me')) { // /me - Abfrage wird auch vor dem Login gemacht und 401-Response wird dort behandelt
            if (this.auth.userLoggedin) {
              this.auth.userlogout();
              this.appError.throw(6030, { snackbar: true });    // 'Verbindung durch Datenbankserver unterbrochen, bitte erneut anmelden!'
            }
            else {
              // Sollte eigentlich nicht vorkommen, weil das sollte von AppRouting und AuthGuardUser abgefangen werden
              this.appError.throw(6031, { snackbar: true });    // 'Keine Berechtigung, bitte anmelden!');
            }
          }
          // Bei 404 - Antwort (Not Found) zur Sicherheit überprüfen, ob der Benutzer in der Datenbank noch angemeldet ist:
          if (err.status === 404) {
            // User überprüfen:
            // (nicht wenn Fehler bei der User-Abfrage auftritt, um Endlosschleife zu verhindern)
            if (!err.url.includes(`${environment.apiEndpoint}/records/kwi_users`)) this.dataService.checkUserRights();
          }
        }
      }
    })
    );
  }
}

@Injectable()
export class UnsavedChanges {
  constructor(
    private auth: AuthenticationService,
    private rootLoad: LoadingService,
  ) { }

  canDeactivate() {
    // console.log('currentRouteHasUnsavedChanges:', this.auth.currentRouteHasUnsavedChanges);

    // const navigateAwaySelection$ = new Subject() as Subject<boolean | UrlTree>;
    if (this.auth.currentRouteHasUnsavedChanges) {
      window.setTimeout(() => { this.rootLoad.set(false); }, 100); // Zur Sicherheit.
      return window.confirm('Sie haben Änderungen auf dieser Seite vorgenommen. Wollen Sie die Seite trotzdem verlassen?');
    }
    else return true;
  }
}
