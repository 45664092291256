/* LOGIN-SEITE */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl } from '@angular/forms';

import { IAppConfig } from '../config/app-config.model';
import { AppConfig } from '../config/app.config';
import { AuthenticationService } from './authentication.service';
import { DataService } from '../shared/data.service';
import { LoadingService } from '../shared/loading.service';
import { CVD } from '../shared/formcontrol.directive';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})

export class LoginComponent implements OnInit {
  appConfig: IAppConfig;
  returnUrl: string;
  username = '';
  password = '';
  errorMsg = '';
  isChecking = false;
  isSignup = false;
  email = '';
  pw1 = ''; // PW auswählen
  pw2 = ''; // PW wiederholen
  isVerified = false;
  isPWChanged = false;
  isMailSent = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private auth: AuthenticationService,
    private rootLoad: LoadingService,
    public cvd: CVD,
    private appConfigService: AppConfig
  ) {
    this.appConfig = this.appConfigService.getConfig().appConfig;

    if (this.appConfig.allowPasswordChange) {
      this.route.queryParams.subscribe(params => {
        if (params['signup']) this.isSignup = true;
        if (params['user']) this.username = params['user'];
        if (params['mail']) this.email = params['mail'];
        if (params['verified']) this.isVerified = true;
        if (params['pwchanged']) this.isPWChanged = true;
      });
    }
  }

  ngOnInit() {
    // Lade ReturnUrl aus Query Parameter, falls vorhanden:
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.returnUrl.startsWith('/login') || this.returnUrl.startsWith('/menu')) this.returnUrl = '/'; // fixed issue #28
    // zufälliges Hintergrundbild laden
    window.setTimeout(() => {
      this.rootLoad.set(false);
    }, 10);
  }

  changeMode(activateSignup: boolean) {
    /* zwischen Login und Signup-Modus hin- und herwechseln
       außer PasswordChange ist nicht erlaubt oder es läuft gerade eine Abfrage
       div. Variablen beim Wechsel zurücksetzen;
    */
    if (!this.appConfig.allowPasswordChange || this.isChecking) return;
    this.isSignup = activateSignup;
    this.errorMsg = '';
    this.pw1 = '';
    this.pw2 = '';
    this.isVerified = false;
    this.isPWChanged = false;
    this.isMailSent = false;
  }

  checkPwMatch(control: AbstractControl) {
    if (this.pw2 === '') return; // wenn Pw2 leer, wird Fehler automatisch angezeigt.
    // Password Match - Fehler manuell setzen oder aufheben:
    if (this.pw1 === this.pw2) control.setErrors(null);
    else control.setErrors({ pwMatch: true });
  }

  login(valid: boolean): void {
    if (!valid) {
      this.errorMsg = 'Bitte geben Sie Benutzername und Passwort ein!'; // UI Feedback
      return;
    }
    else this.errorMsg = '';
    this.isChecking = true;   // Während Login-Daten überprüft werden
    this.dataService.login(this.username, this.password).subscribe({
      next: (apiData: any) => {
        if (apiData.username) {   // Wenn Login erfolgreich war:
          const login$ = this.auth.userlogin$.subscribe(() => { // Zuerst subscriben, dann starten
            // console.log('User-Login fertig');
            login$.unsubscribe();
            this.router.navigateByUrl(this.returnUrl);
          });
          this.auth.userlogin(apiData);

        }
        else this.errorMsg = 'Fehler beim Anmelden';
        this.isChecking = false;
      },
      error: (error) => {
        // console.log(err);
        if (error && error.status === 403) this.errorMsg = 'Falscher Benutzername / Passwort! Bitte nochmals probieren!';
        else this.errorMsg = 'Fehler beim Anmelden';
        this.isChecking = false;
      }
    });
  }

  signup(valid: boolean): void {
    this.isMailSent = false;
    if (!valid) {
      this.errorMsg = 'Bitte gib alle erforderlichen Daten ein!'; // UI Feedback
      return;
    } else if (this.pw1 === this.username || this.pw1 === this.email) {
      this.errorMsg = 'Das Passwort darf nicht gleich sein wie der Benutzername oder die E-Mail-Adresse!'; // UI Feedback
      return;
    } else this.errorMsg = '';
    this.isChecking = true;   // Während Login-Daten überprüft werden
    this.dataService.signup(this.username, this.email, this.pw1).subscribe({
      next: (apiData: any) => {
        if (apiData.sentMail !== null) {   // Wenn Signup erfolgreich war:
          this.isMailSent = true;
        }
        else {
          this.errorMsg = 'Es sind Fehler aufgetreten, kein E-Mail verschickt.';
          for (const err of apiData.errors) {
            this.errorMsg += ' Fehler ' + (err.errcode ? err.errcode + 7100 : 7190) + ': ' + err.errmsg;
          }
        }
        this.isChecking = false;
      },
      error: (err) => {
        console.log(err);
        this.errorMsg = 'Es sind Fehler aufgetreten, kein E-Mail verschickt. Fehler 7191: ' + err.errmsg;
        this.isChecking = false;
      }
    });
  }

}
