/* HAUPTMENÜ */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoadingService } from '../shared/loading.service';
import { MenuService } from './menu.service';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    standalone: false
})

export class MainMenuComponent implements OnInit {

  constructor(
    private router: Router,
    private rootLoad: LoadingService,
    public appMenu: MenuService,
  ) { }

  ngOnInit() {
    // Lade-Animationen ausblenden:
    window.setTimeout(() => {
      this.rootLoad.set(false);
    }, 10);
  }




  navigate(menupoint) {
    this.rootLoad.set(true);
    window.setTimeout(() => { // Timeout, damit Warteanimation nach Möglichkeit gerendert wird
      this.router.navigate([menupoint.route]);
    }, 150);
  }
}