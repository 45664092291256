/* APP CONFIG - STRUKTUR
   Werte kommen aus der Datenbank
*/

import { MenuPoint } from '../menu/menu.service';
import { Filter } from '../shared/filter/filter';
import { BewertungsCols as BewertungsCol } from '../zusatz/spielerbewertung/spielerbewertung.component';

export interface IAppConfig {
    variant: 'kwimo' | 'sport' | 'ptf';     // wird automatisch aus environment.variant ausgelesen, muss nicht in app-config.json stehen.
    variantName: string;                    // wird automatisch aus environment.variant ausgelesen, muss nicht in app-config.json stehen.
    infoSite: string;                       // Für "powered by"-Link. Wird automatisch aus environment.variant ausgelesen, muss nicht in app-config.json stehen.
    appTitle: string;
    appShortTitle: string;
    allowPasswordChange: boolean;
    logo: string;               // Logo-Dateiname. Muss eine Grafikdatei in /assets/images sein. Ohne Angabe wird Kwimo-Logo verwendet.
    menu: MenuPoint[][];        // menu: [ [ { MenuPoint }, ... ], ... ] (zweite Ebene sind die MenuGroups)
    responsive: {
        breakpointL: number;    // =< bpL: adds kwi-size-L to <body> (main toolbar: hide appShortTitle)
        breakpointM: number;    // =< bpM: adds kwi-size-M to <body> (render mobile menu instead of standard menu)
        breakpointS: number;    // =< bpM: adds kwi-size-S to <body> (weitere Mobile-Anpassungen)
    };
    // Folgende Einstellungen müssen auch für die API in der dbauth.php gesetzt werden!
    guest: {
        table: string;            // muss gesetzt werden, wenn guestMode in View oder Form aktiviert ist.  
        activationCols: string[]; // Im Gastdatensatz müssen alle diese Spalten auf true gesetzt sein, damit der Gast Zugriff erhält. [] => Funktion deaktiviert
        activeFromCol: string;    // Im Gastdatensatz muss der Wert dieser Spalte heute oder danach sein, damit der Gast Zugriff erhält. '' => Funktion deaktiviert
        activeUntilCol: string;   // Im Gastdatensatz muss der Wert dieser Spalte heute oder davor sein, damit der Gast Zugriff erhält. '' => Funktion deaktiviert
        appTitle: string;
        templateId: string;       // - " - (für Anzeige des Gastnamens)
        menu: MenuPoint[][];      // Struktur wie menu
        loginRoute: string;       // Route, zu der weitergeleitet werden soll, wenn ein GastId in root übergeben wird, z.B. "/form/anwesenheit/"
        // Sollte immer angegeben werden!
        loginCustomMessage: string; // wird im Gastlogin-Fenster als erstes angezeigt (HTML möglich)
        hideResendAnmeldeLink: boolean;    // Funktion "Anmelde-Link noch einmal zuschicken lassen" ausblenden (auf guestlogin)
    }

    filemanager: {
        active: boolean,
        uploadPath: string,     // muss gleich sein wie in upload.php / kwi-custom.php (Standard: "uploads/")
        images: {
            allowedFiletypes: string[],     // allowedFiletypes müssen in kwi-custom.php für upload.php noch einmal eingestellt werden!
            maxWidth: number,
            maxHeight: number,
            thumbWidth: number,
            thumbHeight: number,
            jpegQuality: number,
        },
        videos: {
            allowedFiletypes: string[],
        },
        files: {
            allowedFiletypes: string[],
        }
    };
    dbLock: {
        active: boolean
    };
    session: {
        maxUsers: number,
        maxWriteUsers: number,
        showWriteUserName: boolean,    // Wenn ein anderer Benutzer schon angemeldet ist, dessen Namen anzeigen.
        maxDemoSessions: number,       // Gleichzeitige Anmeldungen pro Demo-User
        readonlyMode: boolean,
        keepaliveInterval: number,
        timeout: number,            // andere User können nach x Minuten ohne Keepalive bei Bedarf ausgeloggt werden.
        phpTimeout: number,         // Auto-Logout nach x Minuten ohne Keepalive, darf nicht größer als PHP session.cache_expire sein (für GuestLogin)
        idleTimeout: number,        // Anzahl Minuten ohne Aktivität, nach denen der User automatisch ausgeloggt wird.
    };
    // stdLoginPage: 'guestlogin' | 'login';    // ev. für v3
    pagination: {
        pageSize: number,
    };
    clipboard: {
        separator: string
    };
    dateFormat: {
        pipe: string,         // Dateformat für Angular DatePipe - Standard 'dd.MM.yyyy'
        moment: string,       // Dateformat für moment.js - Standard: 'DD.MM.YYYY'
    };
    sentryActive: boolean;    // Error reports via Sentry inkl. Erfassen von Analysedaten aktivieren
    bgPics: string[];
    bgPicsCredit: string;
    bgPicsLink: string;

    allowCustomReadonlyUserUpdateRightsForModules: string[];  // Alle Module (eigentlich erste Pfadebene: z.B. "form", "table", "view", "menu"), für die customReadonlyUserRights freigeschalten sind
    // Hinweis: im Moment ist eine Freischaltung von Updates nur für "form" vorgesehen. Damit das auch für table funktioniert, müssten in TableComponent noch weitere Änderungen vorgenommen werden.

    allowUserConfig: AllowUserConfig;

    /* PTF / Kwimo Sport spezifisch: */

    uebungSingle: {
        infoCols: string[],       // Spalten, die in der Infozeile bei jeder Übung (Header, ganz oben) angezeigt werden sollen (ohne Übungsnummer, die ist fix)
        kategorieCol: string,     // Spalte, die neben dem Titel ("bezeichnung") als Kategorisierung angezeigt werden soll
    };
    mte: {
        anzahlUebungen: number,
        summenCols: string[],     // Alle Cols, für die eine Summe gebildet und im Header angezeigt werden soll (nicht mehr als 4 wegen Anzeigebreite)
        infoCols: string[],       // Spalten, die in der Infozeile bei jeder Übung (Header, ganz oben) angezeigt werden sollen (ohne Übungsnummer, die ist fix)
        kategorieCol: string,     // Spalte, die neben dem Titel ("bezeichnung") als Kategorisierung angezeigt werden soll
        // für infoCols und kategorieCol gilt: wenn nicht angegeben, wird Wert aus uebungSingle übernommen
        viewSelection: string[],  // Wie soll die Seite angezeigt werden? Hier wird definiert, welche Auswahlmöglichkeiten es geben soll
        // Optionen (derzeit): "normal-5" (Standard) => 5 Übungen pro Seite, "medium-3" => 3 pro Seite, "big-2" => 2 pro Seite 
        showAnmerkungEinheit: boolean,
        demoUser: {
            allowCreate: boolean  // Action "new" für Demo-User anzeigen und erlauben 
        }
    };
    spielerbewertung: {
        active: boolean,
        spielerTemplate: string,    // für Anzeige in Auswahlbox
        extraFields: string[],      // FormFields vor Tabelle (müssen in config definiert sein)
        bewertungsCols: BewertungsCol[],  // Tabellenspalten (id und label). Eine letzte Spalte "Anmerkungen" gibt es immer noch dazu.
        headingFields: string[];    // Die Zeilen, die als Überschrift formatiert werden (müssen in config definiert sein; caption ist nötig, tooltip wird auch verwendet. type und system=true nicht vergessen!)
        hideRateFieldFor: string[]; // Die Zeilen, in denen kein RateField angezeigt werden soll.
        showTextFieldFor: string[]; // Die Zeilen, für die ein TextField angezeigt werden soll.
        bewertungszahl: number,     // Standard: 10;
    }
    anwesenheitList: {
        gruppenTemplate: string,    // Template aus Tabelle Gruppen
        sortGruppen: boolean;       // Gruppen alphanumerisch (nach Label) sortieren
        filters: Filter[];
        trainingAllTemplate: string,// Zusatzinfo (hinter Datum) für die Anzeige des Trainings, wenn "alle Gruppen" ausgewählt ist. Template aus Tabelle anwesenheit(_trainer)
        spielerTemplate: string,    // Template aus Tabelle anwesenheit für die Anzeige der Spieler
        trainerTemplate: string,    // Template aus Tabelle anwesenheit_trainer für die Anzeige der Trainer
    }
    matchplan: {
        active: boolean,
        titleTemplate: string,      // Template für Seitentitel
    }
    uebungEinheitV23: boolean;      // Änderungen in Übungen/Einheit für Rudi/TFV Herbst 2023.
}

export class AllowUserConfig {
    /* true: der betreffende Wert kann durch user_config abgeändert werden
       false: nicht

       funktioniert nicht für folgende Einstellungen:
       - allowUserConfig (natürlich, kann sich nicht selbst abschaffen)
       - sentryActive (aus programmtechnischen Gründen)
    */
    appConfig = {
        appTitle: false,
        appShortTitle: false,
        menu: false,
        responsive: false,
        filemanager: false,
        pagination: false,
        clipboard: false,
        dateFormat: false,
        bgPics: false,
        bgPicsCredit: false,
        bgPicsLink: false,
    };

    tableConfig: any;       // ein Objekt mit je einer boolean property pro Tabelle / Form / View, die bestimmt, 
    formConfig: any;        // ob die Einstellungen der Tabelle / Form / View per user_config geändert werden kann
    viewConfig: any;        // Bsp: tableConfig: { table1: true, table2: false }
}

export class GlobalConfig {
    appConfig: IAppConfig;
    tableConfig: any;
    formConfig: any;
    viewConfig: any;
}
