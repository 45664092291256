<div id="login-container">
    <form class="login-form" #formControl="ngForm">
        <mat-card class="login-card">
            <mat-card-header>
                <div mat-card-avatar><img src="/assets/images/{{appConfig.logo || 'kwimo-logo.png'}}" data-loading="eager"></div>
                <mat-card-title>{{appConfig.guest.appTitle}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p *ngIf="failedMessage" class="error-msg mat-elevation-z6">
                    <i class="material-icons">error</i> {{failedMessage}}
                </p>
                <p *ngIf="appConfig.guest.loginCustomMessage" class="custom-message"
                    [innerHTML]="appConfig.guest.loginCustomMessage | safeHtml"></p>
                <p class="intro-text">Verwende für deinen Zugang den individuellen Link, den du bei deiner
                    Anmeldung erhalten hast.<br>
                    Der Anmelde-Link enthält deine UUID und schaut in etwa so aus: <br>{{beispielLink}}
                </p>
                <div class="block-login">
                    <h3>Login mit UUID</h3>
                    <ng-container *ngIf="targetUrl !== ''; else noTargetUrl">
                        <p>Du kannst deine UUID auch hier eingeben und auf 'Login mit UUID' klicken:</p>
                        <mat-form-field>
                            <mat-label>Deine UUID</mat-label>
                            <input matInput #uuid="ngModel" [(ngModel)]="loginUuid" name="loginUuid" required
                                appCustomValidation="uuid">
                            <mat-error *ngIf="uuid.invalid">{{cvd.getValidationErrorMsg(uuid)}}</mat-error>
                        </mat-form-field>
                        <div class="login-button">
                            <button mat-flat-button (click)="guestLogin(uuid.valid)" color="accent">Login mit
                                UUID</button>
                        </div>
                        <br>
                    </ng-container>
                    <ng-template #noTargetUrl>
                        <p>Login mit UUID kann nicht durchgeführt werden, da kein Formular (oder Ansicht) angegeben
                            wurde,<br>zu dem weitergeleitet werden soll.</p>
                        <p>Bitte gib einen entsprechenden Anmelde-Link in die Adresszeile deines Browsers ein. (siehe
                            oben)<br>
                        </p>
                    </ng-template>
                </div>

                <div *ngIf="!appConfig.guest.hideResendAnmeldeLink" class="block-resend-link">
                    <h3>Anmelde-Link nicht zur Hand?</h3>
                    <p>Du kannst dir deinen individuellen Anmelde-Link einfach noch einmal zuschicken lassen.<br>
                        Gib dafür deine E-Mail-Adresse ein, mit der du dich bei uns angemeldet hast:</p>
                    <mat-form-field>
                        <mat-label>E-Mail-Adresse</mat-label>
                        <input matInput #mail="ngModel" [(ngModel)]="email" name="email" required
                            appCustomValidation="email">
                        <mat-error *ngIf="mail.invalid">{{cvd.getValidationErrorMsg(mail)}}</mat-error>
                    </mat-form-field>

                    <p *ngIf="errorMsg !== ''" class="error-msg msg-bottom mat-elevation-z6">
                        <i class="material-icons">error</i>
                        {{errorMsg}}
                    </p>
                    <p *ngIf="isSuccess" class="info-msg mat-elevation-z6">
                        <i class="material-icons">done_outline</i>
                        Wenn die von dir angegebenen Daten stimmen, erhältst du in Kürze ein E-Mail von uns.<br>
                        Du kannst dieses Fenster nun schließen.
                    </p>
                    <div class="button-wrapper">
                        <button mat-flat-button (click)="send(mail.valid)" [disabled]="isChecking"
                            color="accent">Anmelde-Link
                            neu anfordern</button>
                    </div>
                </div>
            </mat-card-content>
            <mat-progress-bar *ngIf="isChecking" mode="indeterminate" color="accent"></mat-progress-bar>
        </mat-card>
    </form>
    <div class="admin-link">
        <a [routerLink]="['/login']"><span class="material-icons-outlined">admin_panel_settings</span></a>
    </div>
</div>
<app-background-image></app-background-image>