/* FORMCONTROL / VALIDATION
   -------------------------------
   für email, phone, int, float, euro, password, fromList (value von LiveSearch / Autocomplete - Eingaben)

   - CustomValidationDirective liefert die Validation selbst,
   - CVD die Fehlermeldungen (siehe dort)
*/

import { Directive, Input, Injectable } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

import moment from 'moment';


// Für Reactive Forms:
export function customValidator(type: string): ValidatorFn {
    return (c: AbstractControl): ValidationErrors | null => {
        switch (type) {
            case 'email':
                if (c.value && !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/gim.test(c.value)) {
                    return { email: true };
                }
                break;
            case 'phone':
                if (c.value && !/[0-9+/\-. ]*\d$/g.test(c.value)) {
                    return { phone: true };
                }
                break;
            case 'euro':
                if (c.value && !/^(\d+)(\.\d{1,2})?$/g.test(c.value)) {
                    return { euro: true };
                }
                break;
            case 'int':
                if (c.value && !/^[0-9]*$/g.test(c.value)) {
                    return { int: true };
                }
                break;
            case 'float':
                if (c.value && !/^(\d+)([.,]\d+)?$/g.test(c.value)) {
                    return { float: true };
                }
                break;
            case 'pw':
                if (c.value && c.value.length < 10) {
                    return { pwTooShort: true };
                }
                if (c.value && c.value.length > 60) {
                    return { pwTooLong: true };
                }
                if (c.value && !/^[a-zäöüß0-9$%&_\-/.:;<>|#+*?!@]+$/gi.test(c.value)) {
                    return { pwChars: true };
                }
                break;
            case 'url':
                if (c.value && c.value.length < 4) {
                    return { urlTooShort: true };
                }
                if (c.value && c.value.length > 15) {
                    return { urlTooLong: true };
                }
                if (c.value && !/^[a-z0-9-]+$/g.test(c.value)) {
                    return { urlChars: true };
                }
                break;
            case 'fromList': {
                // wenn Wert ein String und kein UUID ist, ist er fehlerhaft, da sonst ein ID-Wert (number/UUID) gespeichert wird.
                const selection: any = c.value;
                if (typeof selection === 'string' && !isUuid(selection)) {
                    return { fromList: true };
                }
                break;
            }
            case 'ueber18': {
                // Geburtsdatum muss mindestens 18 Jahre her sein.
                // console.log("Über 18:", moment().diff(c.value, 'years'));
                if (moment().diff(c.value, 'years') < 18) {
                    return { ueber18: true };
                }
                break;
            }
            case 'uuid': {
                if (c.value && !isUuid(c.value.toString().trim())) {
                    return { uuid: true };
                }
                break;
            }
        }
        return null;
    };
}

// Für Template-Driven Forms:
@Directive({
    selector: '[appCustomValidation]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: CustomValidationDirective, multi: true }
    ],
    standalone: false
})
export class CustomValidationDirective implements Validator {
    @Input('appCustomValidation') type: string;

    validate(c: AbstractControl): ValidationErrors | null {
        const validatorFn = customValidator(this.type);
        return validatorFn(c);
    }
}

// Fehlermeldungen:
@Injectable()
export class CVD {
    getValidationErrorMsg(model): string {
        if (model.hasError('required')) return 'Pflichtfeld';
        if (model.hasError('email')) return 'Ungültige E-Mail-Adresse';
        if (model.hasError('phone')) return 'Ungültige Telefonnummer';
        if (model.hasError('euro')) return 'Ungültige Zahl (Gültiges Format: 299 / 1234.56)';
        if (model.hasError('int')) return 'Ungültige Zahl (Gültiges Format: 123)';
        if (model.hasError('float')) return 'Ungültige Zahl';
        if (model.hasError('pwTooShort')) return 'Passwort zu kurz';
        if (model.hasError('pwTooLong')) return 'Passwort zu lang';
        if (model.hasError('pwChars')) return 'Ungültige Zeichen im Passwort. Erlaubt sind Buchstaben, Ziffern und $%&_-/.:;<>|#+*?!@';
        if (model.hasError('urlTooShort')) return 'Angabe zu kurz';
        if (model.hasError('urlTooLong')) return 'Angabe zu lang';
        if (model.hasError('urlChars')) return 'Ungültige Zeichen. Erlaubt sind Kleinbuchstaben ohne Sonderzeichen, Ziffern und Minus.';
        if (model.hasError('fromList')) return 'Eintrag aus der Liste wählen!';
        if (model.hasError('uuid')) return 'Keine gültige UUID';
        return 'Ungültig';
    }
    getDateValidationErrorMsg(model): string {
        if (model.hasError('matStartDateInvalid')) return 'Ungültiges Startdatum';
        if (model.hasError('matEndDateInvalid')) return 'Ungültiges Enddatum';
        if (model.hasError('required')) return 'Pflichtfeld / Fehlendes oder ungültiges Datum';
        if (model.hasError('ueber18')) return 'Mindestalter 18 Jahre';
        if (model.invalid) return 'Ungültiges Datum';
        return 'Ungültig';
    }
}

export function isUuid(term: string): boolean {
    const uuid_regex = RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);
    return uuid_regex.test(term);
}
