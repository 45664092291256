<div id="app-wrapper" class="{{
    isLoggedIn ? 'is-logged-in ' : ''}}{{
    auth.user.readonly ? 'is-readonly ' : ''}}{{
    auth.user.isDemo ? 'is-demo ' : ''}}{{
    auth.user.isAdmin ? 'is-admin ' : ''}}{{
    isLoggedIn && !isLoginPage && !isEmbedded ? 'has-toolbar ' : ''}}{{
    'variant-' + appConfig.variant}}{{ activeModule ? ' module-' + activeModule : ''
      }}" [attr.data-kwimo-version]="kwiVersion">
  <mat-toolbar *ngIf="isLoggedIn && !isLoginPage && !isEmbedded"
    color="{{appConfig.variant === 'ptf' ? 'primary' : 'accent'}}"
    [ngClass]="'route' + activeRoute.split('?')[0].split('/').join('-')">
    <a (click)="goTo('/menu')" class="logo"><img src="/assets/images/{{appConfig.logo || 'kwimo-76.png'}}"></a>
    <a (click)="goTo('/menu')" class="app-title hide-L-down">{{appConfig.appTitle}}</a>
    <a (click)="goTo('/menu')" class="app-title hide-above-L hide-M-down">{{appConfig.appShortTitle}}</a>
    <ul *ngIf="!appMenu.invalidMenu" class="top-menu">
      <ng-container *ngFor="let group of appMenu.menugroups">
        <li *ngFor="let menupoint of group; let i = index" class="toolbar-menu-item group-index-{{i}}">
          <a (click)="goTo(menupoint.route)" class="{{isCurrentRoute(activeRoute, menupoint.route) ? 'active' : ''}}">
            <mat-icon>{{menupoint.icon}}</mat-icon>
            {{menupoint.name}}
          </a>
        </li>
      </ng-container>
    </ul>
    <div *ngIf="(screenSize === 'M' || screenSize === 'S') && !appMenu.invalidMenu" class="mobile-menu">
      <button mat-button [matMenuTriggerFor]="menu">
        <span class="material-icons">menu</span><span *ngIf="!isMobile()"> Menü</span>
      </button>
      <mat-menu #menu="matMenu">
        <ng-container *ngFor="let menupoint of appMenu.menulist">
          <button mat-menu-item *ngIf="menupoint.mobile" (click)="goTo(menupoint.route)">
            <mat-icon>{{menupoint.icon}}</mat-icon>{{menupoint.name}}
          </button>
        </ng-container>
      </mat-menu>
    </div>
    <span class="spacer">{{screenSize === 'M' || screenSize === 'S' ? printTitle : ''}}</span>
    <div class="show-print-only print-title">{{printTitle}}</div>
    <div class="user-area">
      <span *ngIf="auth.user.readonly && !auth.user.isDemo" class="material-icons lesemodus-icon"
        [matTooltip]="'Kein Schreibzugriff'">lock</span>
      <span *ngIf="auth.user.isDemo" class="material-icons lesemodus-icon"
        [matTooltip]="'Demo-Modus'">auto_awesome</span>
      <button *ngIf="!isMobile()" mat-button [matMenuTriggerFor]="userMenu" class="username">{{username}}</button>
      <button mat-icon-button aria-label="Benutzer" [matMenuTriggerFor]="userMenu">
        <mat-icon>account_circle</mat-icon>
      </button>
      <mat-menu #userMenu="matMenu" overlayPanelClass="menu-overlay">
        <span *ngIf="isMobile()" class="userinfo">
          <mat-icon>account_circle</mat-icon>{{username}}
        </span>
        <button *ngIf="auth.user.isAdmin" mat-menu-item [routerLink]="'/make-config'">
          <mat-icon>app_settings_alt</mat-icon>Config
        </button>
        <button mat-menu-item (click)="logout()">Logout</button>
        <span *ngIf="auth.user.readonly && auth.currentWriteUsers?.length" class="info-schreibzugriff">
          <mat-icon>admin_panel_settings</mat-icon>Schreibzugriff hat derzeit:
          <span *ngFor="let name of auth.currentWriteUsers" class="kwi-chip">{{name}}</span>
        </span>
        <button *ngIf="auth.user.readonly && !auth.user.isDemo" mat-menu-item
          (click)="requestWriteAccess()">Schreibzugriff anfragen</button>
        <button *ngIf="!auth.user.readonly" mat-menu-item (click)="readonlyMode()">Schreibzugriff abgeben</button>
        <a class="versionsinfo" href="https://{{ appConfig.infoSite }}"
          title="powered by {{ appConfig.variantName }} - Besuche unsere Website {{ appConfig.infoSite }}!"
          target="_blank">
          <mat-icon>help_outline</mat-icon>{{ appConfig.variantName }} v{{kwiVersion}}
        </a>
      </mat-menu>
    </div>
  </mat-toolbar>
  <div *ngIf="isLoading" class="loading-overlay">
    <div *ngIf="appConfig.variant !== 'ptf'" class="wrapper">
      <img src="/assets/images/kwimo-logo.png"  data-loading="eager" />
      <div class="shine"></div>
    </div>
    <span *ngIf="appConfig.variant === 'ptf'" class="material-icons">sports_soccer</span>
  </div>
  <router-outlet></router-outlet>
  <div class="impressum">
    <span class="material-icons">bolt</span>
    <a href="https://{{ appConfig.infoSite }}"
      title="powered by {{ appConfig.variantName }} - Besuche unsere Website {{ appConfig.infoSite }}!"
      target="_blank">powered by {{ appConfig.variantName }} v{{kwiVersion}}</a> |
    <a href="https://www.gabrielmachts.at/docs/Datenschutzerklaerung_fuer_SaaS-Dienste_von_Gabriel_Stabentheiner.pdf"
      target="_blank">Datenschutz</a>
  </div>
</div>