// Backend Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, inject, provideAppInitializer } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// Frontend Modules
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppCommonModule } from './app-common.module';

// Third Party
import * as Sentry from "@sentry/angular";

// Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { GuestLoginComponent } from './login/guest-login/guest-login.component';
import { MainMenuComponent } from './menu/menu.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';
import { ClipboardComponent, ClipboardService } from './shared/clipboard/clipboard.service';

// Classes, Services, Directives...
import { Tables } from './table/table.model';
import { AppConfig } from './config/app.config';
import { appRoutes } from './app-routing.module';
import { DataService } from './shared/data.service';
import { FilemanagerService } from './shared/filemanager.service';
import { AuthenticationService, AuthGuardAdmin, AuthGuardUser, RedirectGuest, UnauthorizedInterceptor, UnsavedChanges } from './login/authentication.service';
import { SessionStorageService } from './shared/storage.service';
import { LoadingService } from './shared/loading.service';
import { HooksService } from './config/hooks.service';
import { MenuService } from './menu/menu.service';
import { LazyloadImgDirective } from './shared/lazyload-img.directive';
import { CVD } from './shared/formcontrol.directive';
import { IdleTimeoutSheetComponent } from './shared/idle-timeout/idle-timeout-sheet.component';
import { LockService } from './shared/lock.service';
import { ErrorService } from './shared/error.service';

// Providers
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

// Locales
import { registerLocaleData } from '@angular/common';
import localeDeAt from '@angular/common/locales/de-AT';

registerLocaleData(localeDeAt);

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        MainMenuComponent,
        LazyloadImgDirective,
        GuestLoginComponent,
        ClipboardComponent,
        NotFoundComponent,
        IdleTimeoutSheetComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        RouterModule.forRoot(appRoutes, {
            enableTracing: false // <-- true: debugging
        }),
        BrowserAnimationsModule,
        AppCommonModule], providers: [
        AppConfig,
        provideAppInitializer(() => {
          const initializerFn = (initializeApp)(inject(AppConfig), inject(ErrorService));
          return initializerFn();
        }),
        // für bessere Fehlermeldungen in dev nächste Zeile auskommentieren:
        { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false }), },
        Tables,
        DataService,
        FilemanagerService,
        LockService,
        SessionStorageService,
        AuthGuardUser,
        AuthGuardAdmin,
        RedirectGuest,
        UnsavedChanges,
        AuthenticationService,
        LoadingService,
        ClipboardService,
        HooksService,
        MenuService,
        CVD,
        { provide: MAT_DATE_LOCALE, useValue: 'de-AT' },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })

export class AppModule { }


export function initializeApp(appConfig: AppConfig, appError: ErrorService) {
  return () => new Promise<void>(resolve => {
    appConfig.load().then(() => {

      /* Je nach Variant die richtige CSS-Datei und functions.js laden (und einhängen): */

      const variant = AppConfig.standardConfig.appConfig.variant;
      if (!variant) {
        appError.throw(1051);
        resolve();
        return;
      }
      const variantCss = document.getElementById('variant-css') as HTMLLinkElement;
      variantCss.href = `variants/${variant}/${variant}.min.css`;

      let variantJs = document.createElement('script') as HTMLScriptElement;
      variantJs.async = true;
      variantJs.type = 'text/javascript';
      variantJs.src = `variants/${variant}/${variant}-functions.min.js`;

      variantJs.onload = () => {
        console.log(`${variant}-functions.js geladen`);
        resolve();
      };
      variantJs.onerror = (error: any) => {
        appError.throw(1062);
        resolve();
      };

      document.getElementsByTagName('head')[0].appendChild(variantJs);

    });
  });
}
