/* 1) Holt Variant Configs, Action und Filter Hooks aus /variants/[variant]/[variant]-functions.js 
   2) Holt Custom  Configs, Action und Filter Hooks aus /assets/custom/functions.js
      Definitionen in der custom functions.js haben dabei Vorrang vor Definitionen in den Variants
   */

import { Injectable } from '@angular/core';

declare let kwiCustomHooks: any;
declare let kwiHooks: any;

@Injectable({
  providedIn: 'root'
})
export class HooksService {

  constructor() { }

  public configMethod(configname: string, stdFunction?: (...params) => any): (...params) => any {
    /* Gibt eine Funktion zurück, die während dem Startvorgang als Objektmethode in verschiedene Objekte "injiziert" werden kann.
       - Die Methode muss in der functions.js im Objekt kwiCustomHooks unter dem configname definiert sein ODER
         in der [variant]-functions.js im Objekt kwiHooks
       - Wird die Methode nicht gefunden, wird eine entweder eine dafür übergebene Standardfunktion zurückgegeben, 
         oder sonst eine Funktion, die immer null erzeugt.
    */
    if (typeof kwiCustomHooks === 'object' && typeof kwiCustomHooks[configname] === 'function') {
      // console.log('configMethod aus functions.js geladen:', configname);
      return kwiCustomHooks[configname];
    }
    if (typeof kwiHooks === 'object' && typeof kwiHooks[configname] === 'function') {
      // console.log('configMethod aus [variant]-functions.js geladen:', configname);
      return kwiHooks[configname];
    }
    if (stdFunction && typeof stdFunction === "function") return stdFunction;
    return () => null;
  }

  public filterHook(filtername: string, data: any, ...params: any): any {
    // Wenn FilterHook mit angegebenem Namen nicht existiert, einfach die unveränderten Daten wieder zurückgeben.
    filtername = 'filterHook_' + filtername;
    // console.log('filterHook:', filtername, data, params);
    if (typeof kwiCustomHooks === 'object' && typeof kwiCustomHooks[filtername] === 'function') {
      // console.log('filterHook aus functions.js geladen:', filtername);
      return kwiCustomHooks[filtername](data, ...params);
    }
    if (typeof kwiHooks === 'object' && typeof kwiHooks[filtername] === 'function') {
      // console.log('filterHook aus [variant]-functions.js geladen:', filtername);
      return kwiHooks[filtername](data, ...params);
    }
    return data;
  }

  public actionHook(actionname: string, ...params: any): void {
    // Wenn ActionHook mit angegebenem Namen nicht existiert, nichts tun
    actionname = 'actionHook_' + actionname;
    // console.log('actionHook:', actionname);
    if (typeof kwiCustomHooks === 'object' && typeof kwiCustomHooks[actionname] === 'function') {
      // console.log('actionHook aus functions.js geladen:', actionname);
      kwiCustomHooks[actionname](...params);
    }
    else if (typeof kwiHooks === 'object' && typeof kwiHooks[actionname] === 'function') {
      // console.log('actionHook aus [variant]-functions.js geladen:', actionname);
      kwiHooks[actionname](...params);
    }
  }
}
