import { Directive, ElementRef } from '@angular/core';

@Directive({
    selector: 'img',
    standalone: false
})

export class LazyloadImgDirective {
  constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;
    if (supports && nativeElement.dataset.loading !== "eager") { // loading kann manuell auf eager gesetzt werden durch Attribut data-loading="eager"
      nativeElement.setAttribute('loading', 'lazy');
    }
  }
}