/* Hier ausgelagert, um CircularDependeny warnings zu vermeiden:

   Klassen: ReadArgs, ReadOrder
*/

export class ReadArgs {
  /* Leseargumente für die API-Schnittstelle */
  /* vgl. https://github.com/mevdschee/php-crud-api/ */

  // join?: string[];    // Tabellen in einer Anfrage zusammenführen (einmal ausprobiert, hat nicht so gut funktioniert)
  filter?: string[];     // Filter als Array, wird dann mit makeArgsString zusammengesetzt
  // siehe auch https://github.com/mevdschee/php-crud-api/#filters
  /* include?: string;      // derzeit nicht in Verwendung
     exclude?: string;      // derzeit nicht in Verwendung */
  order?: ReadOrder[];
  /* limit?: number;        // derzeit nicht in Verwendung, Pagination läuft über MatPagination
     pageNr?: number;
     pageSize?: number; */

  constructor(init?: Partial<ReadArgs>) {
    Object.assign(this, init);
    // ReadOrder bauen:
    if (this.order?.length) {
      this.order = this.order.map((o) => new ReadOrder(o));
    }
  }

  mergeReadArgs(readArgs1: ReadArgs, readArgs2: ReadArgs): ReadArgs {
    const readArgs = new ReadArgs();
    if (readArgs1 instanceof ReadArgs && readArgs2 instanceof ReadArgs) {
      // Filter:
      if (!Array.isArray(readArgs1.filter)) readArgs1.filter = [];
      if (!Array.isArray(readArgs2.filter)) readArgs2.filter = [];
      readArgs.filter = readArgs1.filter.concat(readArgs2.filter);
      // Order:
      if (!Array.isArray(readArgs1.order)) readArgs1.order = [];
      if (!Array.isArray(readArgs2.order)) readArgs2.order = [];
      readArgs.order = readArgs1.order.concat(readArgs2.order);
    }
    return readArgs;
  }

  makeArgsString() {
    const args = [];
    /*     if (this.join !== undefined) {
          this.join.forEach(s => args.push('join=' + s));
        } */
    if (this.filter !== undefined) {
      this.filter.forEach(s => {
        /* alle Filterparameter, die den Wert (oder die Werte) beinhalten (alles nach dem zweiten Beistrich), wird jeweils mit encodeURIComponent encodiert,
           nicht aber der ganze Filterstring, weil sonst die Beistriche auch encodiert werden und das funktioniert dann anscheinend nicht korrekt.
           vgl. https://github.com/mevdschee/php-crud-api/#filters
           Ist notwendig, weil die Filterwerte (Vergleichswerte) ja auch zB. ein "&" enthalten können.
        */
        const filterParts = s.split(",");
        filterParts.forEach((val, i) => {
          if (i > 1) filterParts[i] = encodeURIComponent(val);
        });
        args.push('filter=' + filterParts.join(","));
      });
    }
    if (this.order?.length) {
      /* hier sollte encodeURI nicht nötig sein */
      this.order.forEach(s => args.push(s.makeOrderString()));
    }
    /*     if (this.include !== undefined && this.include !== '') {
          args.push('include=' + this.include);
        }
        if (this.exclude !== undefined && this.exclude !== '') {
          args.push('exclude=' + this.exclude);
        }
        if (this.limit !== undefined && this.limit !== 0) {
          args.push('size=' + this.limit);
        }
        if (this.pageNr !== undefined && this.pageNr !== 0) {
          if (this.pageSize !== undefined && this.pageSize !== 0) {
            args.push('page=' + this.pageNr + ',' + this.pageSize);
          } else {
            args.push('page=' + this.pageNr);
          }
        } */
        
    /* nicht noch einmal encodeURI anwenden, weil es sonst doppelt wäre */
    if (args.length === 0) return '';
    else return '?' + args.join('&');
  }
}

export class ReadOrder {
  /* Sortierung via API */
  orderBy: string;
  orderDir?: string;

  constructor(init?: Partial<ReadOrder>) {
    Object.assign(this, init);
  }

  makeOrderString(): string {
    if (this.orderDir === 'desc') {
      return 'order=' + this.orderBy + ',desc';
    } else {
      return 'order=' + this.orderBy;
    }
  }
}