import { ActivatedRouteSnapshot, Route, RouterStateSnapshot, Routes } from '@angular/router';

import { MainMenuComponent } from './menu/menu.component';
import { LoginComponent } from './login/login.component';
import { GuestLoginComponent } from './login/guest-login/guest-login.component';
import { NotFoundComponent } from './shared/not-found/not-found.component';

import { AuthGuardAdmin, AuthGuardUser, RedirectGuest, UnsavedChanges } from './login/authentication.service';
import { inject } from '@angular/core';


export const appRoutes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'menu' } as Route,   // Umleitung von root, z.B. nach Login

  { path: 'login', canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuardUser).canActivate(next, state)], component: LoginComponent },
  { path: 'guestlogin', component: GuestLoginComponent },
  { path: 'menu', canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuardUser).canActivate(next, state)], component: MainMenuComponent },
  {
    path: 'table', canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuardUser).canActivate(next, state)], canDeactivate: [() => inject(UnsavedChanges).canDeactivate()],
    loadChildren: () => import('./table/table.module').then(m => m.TableModule)
  },
  { path: 'view', loadChildren: () => import('./view/view.module').then(m => m.ViewModule) },
  {
    path: 'form', canDeactivate: [() => inject(UnsavedChanges).canDeactivate()],
    loadChildren: () => import('./form/form.module').then(m => m.FormModule)
  },
  {
    path: 'make-config', canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuardAdmin).canActivate(next, state)],
    loadChildren: () => import('./config/make-config/make-config.module').then(m => m.MakeConfigModule)
  },
  // Zusatz lazy modules:
  {
    path: 'anwesenheit-list', canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuardUser).canActivate(next, state)],
    loadChildren: () => import('./zusatz/anwesenheit-list/anwesenheit-list.module').then(m => m.AnwesenheitListModule)
  },
  {
    path: 'matchplan', canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuardUser).canActivate(next, state)],
    loadChildren: () => import('./zusatz/matchplan/matchplan.module').then(m => m.MatchplanModule)
  },
  {
    path: 'spielerbewertung', canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuardUser).canActivate(next, state)],
    loadChildren: () => import('./zusatz/spielerbewertung/spielerbewertung.module').then(m => m.SpielerbewertungModule)
  },
  // -- finish Zusatz
  { path: ':guestId', canActivate: [RedirectGuest], component: GuestLoginComponent },  // Logik findet in RedirectGuest statt, GuestLoginComponent ist Fallback 
  { path: '**', canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthGuardUser).canActivate(next, state)], component: NotFoundComponent }   // Umleitung von unbekannten Pfaden (404-Seite)
];
