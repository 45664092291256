/* GUEST-"LOGIN"-SEITE */

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AppConfig } from 'src/app/config/app.config';
import { IAppConfig } from 'src/app/config/app-config.model';
import { AuthenticationService } from '../authentication.service';
import { DataService } from 'src/app/shared/data.service';
import { LoadingService } from 'src/app/shared/loading.service';
import { ErrorService } from 'src/app/shared/error.service';
import { CVD } from 'src/app/shared/formcontrol.directive';


@Component({
    selector: 'app-guest-login',
    templateUrl: './guest-login.component.html',
    styleUrls: ['./guest-login.component.scss'],
    standalone: false
})
export class GuestLoginComponent implements OnInit {
  appConfig: IAppConfig;
  targetUrl: string;
  failedMessage = '';
  beispielLink: string;
  errorMsg = '';
  isChecking = false;
  email = '';
  loginUuid = '';
  isSuccess = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService,
    private auth: AuthenticationService,
    private rootLoad: LoadingService,
    public cvd: CVD,
    private appError: ErrorService,
    private appConfigService: AppConfig
  ) {
    this.appConfig = this.appConfigService.getConfig().appConfig;

  }

  ngOnInit() {
    // Lade ReturnUrl aus Query Parameter, falls vorhanden:
    this.targetUrl = this.route.snapshot.queryParams['returnUrl'] || this.appConfig.guest.loginRoute || '';
    if (this.route.snapshot.queryParams['failed']) {
      this.failedMessage = this.appError.errorMessages[this.route.snapshot.queryParams['failed']] ||
        "Der von dir verwendete Anmelde-Link ist leider ungültig.";
    }
    this.beispielLink = window.location.origin + (this.targetUrl !== '' ? this.targetUrl : '/form/beispiel') + '/80da025a-5803-11eb-ae93-0242ac130002';
    window.setTimeout(() => {
      this.rootLoad.set(false);
    }, 10);
  }

  guestLogin(valid: boolean): void {
    this.failedMessage = '';
    this.loginUuid = this.loginUuid.trim();
    if (!valid) {
      this.errorMsg = 'Bitte gib alle erforderlichen Daten ein!'; // UI Feedback
      return;
    }
    else this.errorMsg = '';
    sessionStorage.setItem('loginGuestId', this.auth.encrypt(this.loginUuid));
    this.router.navigate([this.targetUrl]);
  }

  send(valid: boolean): void {
    this.failedMessage = '';
    this.isSuccess = false;
    if (!valid) {
      this.errorMsg = 'Bitte gib alle erforderlichen Daten ein!'; // UI Feedback
      return;
    } else this.errorMsg = '';
    this.isChecking = true;   // Während Login-Daten überprüft werden
    this.dataService.guestRemind(this.email, window.location.origin + this.targetUrl).subscribe({
      next: (apiData: any) => {
        if (apiData.sentMail !== null) {   // Wenn GuestRemind erfolgreich war (oder auch Daten nicht gefunden):
          this.isSuccess = true;
        }
        else {
          this.errorMsg = 'Es sind Fehler aufgetreten, kein E-Mail verschickt.';
          for (const err of apiData.errors) {
            this.errorMsg += ' Fehler ' + (err.errcode ? err.errcode + 7300 : 7390) + ': ' + err.errmsg;
          }
        }
        this.isChecking = false;
      },
      error: (err) => {
        console.log(err);
        this.errorMsg = 'Es sind Fehler aufgetreten, kein E-Mail verschickt. Fehler 7391: ' + err.errmsg;
        this.isChecking = false;
      }
    });
  }

}
