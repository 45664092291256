/* STORAGE SERVICE
   -------------------------------
   - speichert Daten in den SessionStorage, derzeit die jeweilige Auswahl der Filter und Sort aller Seiten
   - es wäre auch leicht, das auf ein Speichern in LocalStorage für eine (etwas) längere Speicherung der Daten umzustellen
   - einige Funktion speichern Session-Daten allerdings direkt (Login, Hintergrundbild)
   - beachte die Hinweise zur Speicherung der Sort-Daten unten bei class ReturnObject
*/

import { Inject, Injectable } from '@angular/core';
import { MatSortable } from '@angular/material/sort';

import { StorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';

import { Filter, SaveFilter, SaveSort, KwiSort, Grouping } from '../shared/filter/filter';
import { AuthenticationService } from '../login/authentication.service';
import { environment } from 'src/environments/environment';

let STORAGE_KEY_PREFIX: string;

class SaveObject {
    /* so werden die Daten abgespeichert (nur die wesentlichen Daten der Filter und Sort) */
    filters: SaveFilter[];
    volltextFilter = '';
    grouping: Grouping;       // hier wird der Einfachheit halber die gesamte Klasse Grouping gespeichert bzw. das, was mit JSON.stringify übrigbleibt
    sort: SaveSort;         // nur in View verwendet
    matSorts: MatSortable[];       // für MatTables wird matSort gespeichert und sort nicht verwendet
    pageSize?: number;      // wieviele Einträge auf einer Seite
    pageIndex?: number;     // auf welcher Seite befinden wir uns?

    constructor() {
        this.filters = [];
        this.sort = new SaveSort();
    }
}
class ReturnObject {
    /* so werden die Daten zurück geliefert, damit sie direkt in die Filter / Sort geladen werden können
       - Filter werden mit den gespeicherten Daten zusammen geführt
       - Sort wird 1:1 aus dem Speicher übernommen (deswegen verwenden wir hier auch den SaveSort - Typ)
         Hintergrund: die Daten werden dann einmal als Objekt Sort und einmal als MatSort gebraucht,
                      je nachdem, ob die interne Sortierfunktion oder die Sortierung in MatTables verwendet wird.
                      Deshalb ist es am einfachsten, sie erstmal als SaveSort - Typ zu übergeben und dann weiter anzupassen
    */
    filters: Filter[];
    volltextFilter = '';
    grouping: Grouping;     // hier wird der Einfachheit halber die gesamte Klasse Grouping gespeichert bzw. das, was mit JSON.stringify übrigbleibt
    sort: SaveSort;
    matSorts: MatSortable[];       // für MatTables wird matSort gespeichert und sort nicht verwendet
    pageSize?: number;      // wieviele Einträge auf einer Seite
    pageIndex?: number;     // auf welcher Seite befinden wir uns?

    constructor() {
        this.filters = [];
        this.sort = new SaveSort();
        if (environment.variant === 'ptf') STORAGE_KEY_PREFIX = 'ptfWebApp_';
        else if (environment.variant === 'sport') STORAGE_KEY_PREFIX = 'kwimoWebApp_';
        else STORAGE_KEY_PREFIX = 'kwimo_';
    }
}

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private auth: AuthenticationService,
    ) { }

    public saveFilters(domain: string, filters: Filter[], volltextFilter: string, grouping?: Grouping, sort?: KwiSort, matSorts?: MatSortable[],
        pageSize?: number, pageIndex?: number
    ): void {
        // Für alle erlauben, wird in ViewComp auch eingesetzt
        // if (!this.auth.checkAuth(false, true)) return;    // Ohne Autorisierung geht nix, guests erlaubt
        const data = new SaveObject();
        const key = STORAGE_KEY_PREFIX + domain;
        // Aus Filtern SaveFilter machen (nur die Auswahl-Properties speichern)
        filters.forEach((filter) => {
            const saveFilter = new SaveFilter();
            saveFilter.makeFromFilter(filter);
            data.filters.push(saveFilter);
        });
        data.volltextFilter = volltextFilter;
        if (grouping) data.grouping = grouping;
        // Aus Sort SaveSort machen (nur die Auswahl-Properties speichern)
        if (sort) data.sort.makeFromSort(sort);
        if (matSorts) data.matSorts = matSorts;
        if (pageSize) data.pageSize = pageSize;
        if (pageIndex) data.pageIndex = pageIndex;
        this.storage.set(key, JSON.stringify(data));
        console.log("saved in SESSION_STORAGE: ", key, data);
    }

    public getFilters(domain: string, filters: Filter[]): ReturnObject | null {
        /*  filters wird automatisch in place aktualisiert 
        */

        // if (!this.auth.checkAuth(false, true)) return;    // Ohne Autorisierung geht nix, guests erlaubt  // Für alle erlauben, wird in ViewComp auch eingesetzt
        let storageData = new SaveObject();
        const returnData = new ReturnObject();
        const key = STORAGE_KEY_PREFIX + domain;
        if (this.storage.get(key) == null) return null;
        storageData = JSON.parse(this.storage.get(key));
        // Gespeicherte Auswahl-Properties mit Filter-Objekt mergen
        filters.forEach((filter) => {

            // Folgende Entkopplung ist nötig, damit die Daten von den fetched filters nicht
            // direkt in this.filters der Parent-Compononent "eingeflößt" werden
            // const tempFilter = new Filter();
            // Object.assign(tempFilter, filter);
            // tempFilter.mergeWithSavedFilter(storageData.filters.find((f) => f.id === filter.type + '__' + filter.id));
            // returnData.filters.push(tempFilter);

            // => genau das wollen wir (jetzt):
            filter.mergeWithSavedFilter(storageData.filters.find((f) => f.id === filter.type + '__' + filter.id));
            returnData.filters.push(filter);
        });
        // Volltext-Filter holen:
        returnData.volltextFilter = storageData.volltextFilter;
        if (storageData.grouping) returnData.grouping = new Grouping(storageData.grouping);
        // Sort holen:
        if (storageData.sort) returnData.sort = storageData.sort;
        if (storageData.matSorts) returnData.matSorts = storageData.matSorts;
        if (storageData.pageSize) returnData.pageSize = storageData.pageSize;
        if (storageData.pageIndex) returnData.pageIndex = storageData.pageIndex;
        console.log('fetched from SESSION_STORAGE: ', key, returnData);
        return returnData;
    }

    public saveToStorage(key: string, data: string): void {
        if (!this.auth.checkAuth()) return;    // Ohne Autorisierung geht auch hier nix
        key = STORAGE_KEY_PREFIX + key;
        this.storage.set(key, data);
        // console.log('saved in SESSION_STORAGE: ', key, data);
    }

    public getFromStorage(key: string): string | null {
        if (!this.auth.checkAuth()) return;    // Ohne Autorisierung geht auch hier nix
        key = STORAGE_KEY_PREFIX + key;
        if (this.storage.get(key) == null) return null;
        else return this.storage.get(key);
    }
}
