import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  public subject = new BehaviorSubject(true);
  constructor() { }

  set(value: boolean): void {
    this.subject.next(value);
  }

}
