// Gemeinsame Modul-Importe für Hauptmodul (app.module) und Untermodule (table.module, form.module)

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Frontend Modules
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
// import { MatChipsModule } from '@angular/material/chips';

// Components
import { FilterComponent } from './shared/filter/filter.component';
import { GuestToolbarComponent } from './shared/guest-toolbar/guest-toolbar.component';
import { BackgroundImageComponent } from './shared/background-image/background-image.component';
import { FieldContentComponent } from './shared/field-content/field-content.component';

// Directives, Services, ...
import { CustomValidationDirective } from './shared/formcontrol.directive';
import { ToDateObjPipe } from './shared/date.pipe';
import { SafeHtmlPipe } from './shared/sanitize.pipe';


@NgModule({
  declarations: [
    FilterComponent,
    FieldContentComponent,
    BackgroundImageComponent,
    CustomValidationDirective,
    GuestToolbarComponent,
    ToDateObjPipe,
    SafeHtmlPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatToolbarModule,
    MatCardModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatDialogModule,
    MatExpansionModule,
    // MatChipsModule,
  ],
  exports: [
    FilterComponent,
    FieldContentComponent,
    BackgroundImageComponent,
    GuestToolbarComponent,
    CustomValidationDirective,
    ToDateObjPipe,
    SafeHtmlPipe,
    FormsModule,
    MatToolbarModule,
    MatCardModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatMenuModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatBadgeModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatOptionModule,
    MatBottomSheetModule,
    MatExpansionModule,
    MatDialogModule,
    MatExpansionModule,
    // MatChipsModule,
  ],
  providers: [
    MatDialog
  ]
})

export class AppCommonModule { }
