import { Injectable, Component, ViewChild, ElementRef, Inject, ChangeDetectorRef, NgZone, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { RepositionScrollStrategy, ScrollDispatcher, ViewportRuler } from '@angular/cdk/overlay';
import { MatBottomSheet, MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Subject, Subscription } from 'rxjs';

import { AppConfig } from '../../config/app.config';
import { AuthenticationService } from 'src/app/login/authentication.service';
import { IAppConfig } from 'src/app/config/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  isActive: boolean;
  changes$: Subject<ClipboardData>;
  constructor(
    private bottomSheet: MatBottomSheet,
    private scrollDispatcher: ScrollDispatcher,
    private viewport: ViewportRuler,
    private ngZone: NgZone,
  ) { }

  public addToClipboard(value: string, emptyClipboardBeforeInsert?: boolean, copyInsertImmediately?: boolean): void {
    if (this.isActive) this.changes$.next({ value, emptyClipboardBeforeInsert, copyInsertImmediately });
    else {
      this.changes$ = new Subject();
      const bottomSheetRef = this.bottomSheet.open(ClipboardComponent, {
        data: { 
          content: value,
          copyInsertImmediately
        },
        autoFocus: false,
        hasBackdrop: false,
        panelClass: 'clipboard-panel',
        scrollStrategy: new RepositionScrollStrategy(this.scrollDispatcher, this.viewport, this.ngZone),
      });
      this.isActive = true;
      bottomSheetRef.afterDismissed().subscribe(() => {
        this.isActive = false;
        this.changes$.complete();
      });
    }
  }

}

@Component({
    selector: 'app-clipboard',
    templateUrl: './clipboard.component.html',
    styleUrls: ['./clipboard.component.scss'],
    standalone: false
})
export class ClipboardComponent implements OnInit, AfterViewInit, OnDestroy {
  content = '';
  appConfig: IAppConfig;
  @ViewChild('textarea', { static: false }) textarea: ElementRef;
  private cleanupSub$: Subscription;  // Subscription auf Logout
  constructor(
    private bottomSheetRef: MatBottomSheetRef<ClipboardComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private clipboard: ClipboardService,
    private auth: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private appConfigService: AppConfig,
  ) {
    this.content = this.data.content;
    this.appConfig = this.appConfigService.getConfig().appConfig;
  }
  ngOnInit(): void {
    this.clipboard.changes$.subscribe((cbData) => {
      // Wert in interne Zwischenablage schreiben:
      if (this.content === '' || cbData.emptyClipboardBeforeInsert) this.content = cbData.value;
      else this.content += this.appConfig.clipboard.separator + cbData.value;
      // Wert in Browser-/OS-Zwischenablage schreiben:
      if(cbData.copyInsertImmediately) this.copyExternal(cbData.value);
      this.cdr.detectChanges();
    });


    this.cleanupSub$ = this.auth.cleanup$.subscribe(() => {    // Nach Cleanup-Trigger Cache löschen
      this.empty();
      this.close();
    });
  }

  ngAfterViewInit(): void {
    // Beim ersten Aufruf auch Wert gleich in Browser-/OS-Zwischenablage schreiben, wenn gewünscht:
    if(this.data.copyInsertImmediately) this.copy();
  }

  ngOnDestroy(): void {
    this.cleanupSub$.unsubscribe();
  }

  empty(): void {
    this.content = '';
  }

  copy(): void {
    this.textarea.nativeElement.select();
    this.copyExternal(this.content);
  }

  copyExternal(data): void {
    // Wert in Browser-/OS-Zwischenablage schreiben:
    navigator.clipboard.writeText(data).then(
      () => {
        console.log('Async: Copying to clipboard was successful!');
      },
      (err) => {
        console.error('Async: Could not copy text: ', err);
      });
  }

  close(): void {
    this.content = '';
    this.bottomSheetRef.dismiss();
  }

}

class ClipboardData {
  value: string;
  emptyClipboardBeforeInsert: boolean;
  copyInsertImmediately: boolean;
}
