declare const require: any;

export const environment = {
  production: true,
  apiEndpoint: '/restapi/api.php',
  uploadEndpoint: '/restapi/upload.php',
  signupEndpoint: '/restapi/signup.php',
  gremindEndpoint: '/restapi/gremind.php',
  version: require('../../package.json').version,
  variant: 'kwimo' as 'kwimo' | 'sport' | 'ptf',
  infoSite: 'www.kwimo.at',
  variantName: 'Kwimo',
};
