/* DATEN-SERVICE
   -------------------------------
   - Verbindung zu API-Schnittstelle / Datenbank
   - Aufbau und Verwaltung interner Cache
   - AnonMode für nicht angemeldete Benutzer

   öffentliche Methoden:
   # init: Initialisieren
   # checkCache: Überprüfen, ob eine oder mehrere Tabellen gerade gecached werden.
   # read: Daten aus Cache oder API auslesen
   # create, update, delete, login, logout: Weitergabe an API-Schnittstelle
   # emptyCache: Cache zurücksetzen
*/

import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subscriber, Subject, forkJoin, combineLatest, Subscription } from 'rxjs';

import moment from 'moment';

import { environment } from '../../environments/environment';
import { Table, Tables } from '../table/table.model';
import { ReadArgs } from './readargs';
import { IAppConfig } from '../config/app-config.model';
import { AppConfig } from '../config/app.config';
import { AuthenticationService } from '../login/authentication.service';
import { SnackbarService } from './snackbar.service';
import { FilemanagerService } from './filemanager.service';
import { ErrorService } from './error.service';
import { LockService } from './lock.service';


@Injectable({
  providedIn: 'root'
})
export class DataService {
  private appConfig: IAppConfig;
  private apiEndpoint = environment.apiEndpoint;
  private signupEndpoint = environment.signupEndpoint;
  private gremindEndpoint = environment.gremindEndpoint;
  public cachedData = [];         // Cache in Array
  public cachedDataIndexed = {};  // Indexierter Cache in Objekt
  private cachingStatus = {};     // 'caching' / 'cached' / 'failed' für jede Tabelle
  private cachingSubjects$: Subject<void>[] = [];  // Subject, das nach jedem Caching-Vorgang ein next() zurück gibt (pro Tabelle)
  public isInitialized = false;   // true: grundsätzliche Initialisierung des Services hat statt gefunden
  public isLoggingOutBecauseNoApiUser = false;  // wird verwendet, um initiales Daten-Holen abzubrechen (Logout dauert ein bisschen, aber dieser Marker wird sofort gesetzt)
  private cleanupSub$: Subscription;
  public tables;
  public filemanager: FilemanagerService;
  public lock: LockService;
  private batchCounters = {};   // Ein BatchCounter pro Table und Befehl. 
  // Total und Counter werden vom ersten Aufruf eines Batch-Befehls gesetzt/erhöht. (Somit wird auch bei mehreren Aufrufen hintereinander die korrekte Anzahl gespeichert)
  // Bei allen weiteren Aufrufen des gleichen Batch-Befehls wird der Counter runtergezählt.

  constructor(
    private httpClient: HttpClient,
    private injector: Injector,
    private auth: AuthenticationService,
    private snackbar: SnackbarService,
    private appError: ErrorService,
    private tablesModel: Tables,
    private appConfigService: AppConfig,
    private router: Router,
  ) {
    this.appConfig = this.appConfigService.getConfig().appConfig;
  }

  init(anon?: boolean, guest?: boolean) {
    /* grundsätzliche Initialisierung des Services */
    this.isInitialized = false;
    if (!anon && !guest && !this.auth.checkAuth(true)) {
      this.appError.throw(1202); // Ohne Autorisierung geht nix
      return;
    }
    // Anon-Modus: Nur Tabellen cachen, die anonRights = readAll
    // Reiner Lesecache, Re-caching-Trigger bei update und create abgeschaltet
    if (anon) this.tables = this.tablesModel.getTablesWithRight('anonRights', 'readAll');
    // Gast-Modus: Nur Tabellen cachen, die guestRights = readAll
    else if (guest) this.tables = this.tablesModel.getTablesWithRight('guestRights', 'readAll');
    else this.tables = Object.keys(this.tablesModel.tables);

    if (this.appConfig.filemanager?.active) {
      this.filemanager = this.injector.get(FilemanagerService) as FilemanagerService;
    }
    if (this.appConfig.dbLock?.active) {
      this.lock = this.injector.get(LockService) as LockService;
    }

    if (!this.cleanupSub$) {
      this.cleanupSub$ = this.auth.cleanup$.subscribe(() => {    // Nach Cleanup-Trigger Cache löschen
        this.emptyCache();
        // Zur Sicherheit DataService gleich (anonym) neu initialisieren, falls es von anonymen Benutzern weiterverwendet wird
        this.init(true);
      });
    }

    // Variablen initialisieren und Tabellen Cachen:
    if (!this.isLoggingOutBecauseNoApiUser) {
      for (const table of this.tables) {
        this.batchCounters[table] = new BatchCounterSet();
        // Wenn Tabelle bereits gecached ist, überspringen! (ist wichtig bei Wechsel von einem Benutzerstatus (anon / guest / user) auf einen anderen)
        if (this.cachingStatus[table] === 'cached') continue;
        this.cachingStatus[table] = 'caching';
        this.cachingSubjects$[table] = new Subject();  // CachingSubject initialisieren
        // Daten von API holen:
        let readArgsString = '';
        if (this.tablesModel.tables[table].config.readArgs instanceof ReadArgs) { // fixe ReadArgs aus TableConfig übernehmen
          readArgsString = this.tablesModel.tables[table].config.readArgs.makeArgsString();
        }
        this.httpClient.get(`${this.apiEndpoint}/records/${table}${readArgsString}`).subscribe({
          next: (apiData: any) => {
            this.cachedData[table] = this.tablesModel.tables[table].prepareDataFromApi(apiData.records);
            console.log('Initial API data for table ' + table + ': ', apiData.records);
            this.makeDataArrays(table);   // CachingSubject gibt erst am Ende von makeDataArrays next() aus.
          },
          error: (error: HttpErrorResponse | any) => {
            if (this.isLoggingOutBecauseNoApiUser || !this.auth.userLoggedin) {   // wenn ausgeloggt (wird), Fehler nicht senden
              if (error.status !== 401) { // 401 wird schon im UnauthorizedInterceptor verarbeitet
                this.appError.throw(6015, {
                  msg: `Fehler beim initialen Laden der Daten in den Cache. Tabelle: ${table}, Error: ${error.message}`
                });
              }
              this.cachingStatus[table] = 'failed';
            }
            this.cachedData[table] = [];
            this.cachingSubjects$[table].next();
          }
        });
      }
    }

    this.isInitialized = true;
  }

  checkCache(tables: string[]): Observable<any> {
    /* - Überprüfen, ob eine oder mehrere Tabellen gerade gecached werden.
         Gibt complete aus, sobald alles fertig ist (sofort oder später). */
    // console.log('checkCache, tables', tables);
    return new Observable((observer) => {
      if (!this.checkInit(observer)) return;
      const cachingSubs: Observable<any>[] = [];
      for (const table of tables) {
        if (this.cachingStatus[table] === 'caching') {  // wenn eine Tabelle gerade gecached wird:
          // console.log('currently caching:', table);
          cachingSubs.push(this.cachingSubjects$[table]); // Sub zur Liste hinzufügen
        }
      }
      // console.log('cachingSubs', cachingSubs.length);
      if (cachingSubs.length) {
        const combSubs = combineLatest(cachingSubs).subscribe(() => {
          // console.log('checkCache finished');
          combSubs.unsubscribe();
          this.maybeShowUserErrorForTableLoading(tables);
          observer.next();
          observer.complete();
        });
      } else {
        // console.log('checkCache: allCached');
        this.maybeShowUserErrorForTableLoading(tables);
        observer.next();
        observer.complete();
      }
    });

  }

  public maybeShowUserErrorForTableLoading(tables) {
    //  Überprüfen, ob irgendeine Tabelle nicht geladen werden konnte, dann eine Fehlermeldung ausgeben
    const failedTables = tables.filter((t) => this.cachingStatus[t] === 'failed').map((ft) => this.tablesModel.tables[ft].config.labelSingle);
    if (failedTables?.length) {
      this.snackbar.showMessage(`Fehler beim Laden der Tabelle/n: ${failedTables.join(', ')}. Diese Daten können nicht angezeigt / verändert werden.`, null, 10000);
    }
  }

  read(table: string, row?: string, readArgs?: ReadArgs, helperTables?: string[], options?: DsOptions): Observable<any> {
    /* - Anfragen ohne readArgs (oder nur mit den fixen readArgs aus TableConfig) werden aus Cache ausgelesen
       - Anfragen mit dynamischen readArgs werden immer direkt an API gerichtet (damit sparen wir uns hier die Logik)
       - Übergebene readArgs werden mit den fixen readArgs aus TableConfig kombiniert (übergebene readArgs kommen zuerst, wichtig für order)
       - Wenn entsprechende Tabelle gerade gecached wird, auf cachingSubject next warten.
       - Wird helperTables angegeben, wird mit der Antwort solange gewartet, bis auch diese Tabelle(n) gecached sind.
       Rückgabewert: Observable, Daten in apiData oder AppError
       Hinweis: Wichtig ist, dass jeder Aufruf von read() auch einen möglichen error behandelt, da sonst ein unhandled error entsteht, der von sentry protokolliert wird.
    */
    if (!options) options = {};
    const tableModel: Table | undefined = this.tablesModel.tables[table];
    return new Observable((observer) => {
      const command = row ? 'readRow' : 'readAll';
      if (!this.checkInitAndAuth(command, table, options.anon, options.guest, observer, false)) return;

      /* Folgende Anfragen direkt an API richten:
         - Wenn dynamische readArgs angegeben werden (als Parameter),   
         - wenn die Tabelle nicht gecached wird (werden darf) (inkl. bei "System"-Tabellen wie kwi_files, ...)
         - sowie anonyme ReadRow-Anfragen
      */
      const hasDynamicReadArgs = (readArgs instanceof ReadArgs);
      if (tableModel?.config.readArgs instanceof ReadArgs) {
        if (hasDynamicReadArgs) {
          // Übergebene readArgs werden mit den fixen readArgs aus TableConfig kombiniert (übergebene readArgs kommen zuerst, wichtig für order)
          readArgs = readArgs.mergeReadArgs(readArgs, tableModel.config.readArgs);
        }
        else {
          // nur fixe, keine dynamischen ReadArgs:
          readArgs = tableModel.config.readArgs;
        }
      }
      const readArgsString = readArgs ? readArgs.makeArgsString() : '';

      if (table !== 'kwi_files') console.log('DataService Read', table, readArgsString, this.cachingStatus[table], row, helperTables, options, hasDynamicReadArgs);

      if (hasDynamicReadArgs || !this.cachingStatus[table] || (options.anon && row)) {
        if (!row) row = ''; // (Anmerkung: row und readArgs sollten nie gleichzeitig vorkommen)
        const waitForTables = helperTables ? [... new Set(helperTables)] : [];    // Sicherstellen, dass keine doppelten HelperTables vorkommen


        // forkJoin gibt .complete() zurück, wenn beide Observables complete() zurückgegeben haben.
        // debugger;
        forkJoin([
          this.httpClient.get(`${this.apiEndpoint}/records/${table}${row !== '' ? '/' + row : ''}${readArgsString}`),
          this.checkCache(waitForTables)
        ]).subscribe({
          next: (apiData: any) => {
            apiData = apiData[0]; // Nur die Antwort von httpClient.get interessiert uns!
            if (apiData.records !== undefined) apiData = apiData.records;
            if (tableModel) {
              apiData = tableModel.prepareDataFromApi(apiData, !!row);
              if (options.makeTemplatesAndAutoOptions) {
                apiData = this.makeTemplatesAndAutoOptions(table, helperTables, apiData, !!row, false);
              }
            }
            console.log('API Data: ', apiData);
            observer.next(apiData);
            observer.complete();
          },
          error: (error: HttpErrorResponse | any) => {
            // checkCache() hat derzeit keinen observer.error()-Aufruf, deswegen wird hier nur die HttpErrorResponse von der API verarbeitet:
            if (error.status !== 401) { // 401 wird schon im UnauthorizedInterceptor verarbeitet
              this.appError.throw(6100, {
                msg: `Fehler beim Lesen der Daten. Tabelle: ${table}, Error: ${error.message}`,
                observer,
                snackbar: !options.silent,
                snackbarMsg: `Fehler beim Lesen der Daten aus Tabelle: ${tableModel.config.labelSingle}`,
                noSentryCapture: !!options.noSentryCaptureOnError,
              });
            }
          }
        });

        // Alle anderen Anfragen aus dem Cache auslesen:
      } else {
        const waitForTables = helperTables ? [table, ... new Set(helperTables)] : [table];
        this.checkCache(waitForTables).subscribe({
          next: (failedTables) => {
            if (this.cachingStatus[table] === 'failed') {
              this.appError.throw(6101, {
                observer,
                snackbar: !options.silent,
                noSentryCapture: !!options.noSentryCaptureOnError,
              });
              return;
            }
            if (row) {  // Abfrage einer einzelnen Datenreihe:
              let data = this.cachedDataIndexed[table][row];
              if (!data) {
                this.appError.throw(6102, {
                  observer,
                  snackbar: !options.silent,
                  noSentryCapture: !!options.noSentryCaptureOnError
                });
                return;
              }
              if (options.makeTemplatesAndAutoOptions) {
                data = this.makeTemplatesAndAutoOptions(table, helperTables, data, true, false);
              }
              console.log('Local Data Row: ', data);
              observer.next(data);
            }
            else { // gesamte Tabelle:
              // console.log('CachingStatus: ', this.cachingStatus[table]);
              let data = this.cachedData[table];
              if (options.makeTemplatesAndAutoOptions) {
                data = this.makeTemplatesAndAutoOptions(table, helperTables, data, false, true);
              }
              console.log('Local Data: ', data);
              observer.next(data);
            }
            observer.complete();
          },
          error: (error) => {
            this.appError.throw(6020);  // wird derzeit nie auftreten, weil checkCache keinen error zurückgibt
          }
        });
      }
    });
  }

  create(table: string, data: any, options?: DsOptions): Observable<any> {
    /* Neue Datenreihe in Tabelle schreiben
       anon = true: eingeschränkte Funktionalität für nicht angemeldete Benutzer (z.B. keine BatchOperationen)
       Während des Vorgangs wird diese Tabelle auf 'caching' gestellt und am Ende im Cache aktualisiert.
       Rückgabewert: Observable, neue id in apiData.
    */
    if (!options) options = {};
    const tableModel: Table | undefined = this.tablesModel.tables[table];
    return new Observable((observer) => {
      if (!this.checkInitAndAuth('create', table, options.anon, options.guest, observer, false)) return;  // observer.error wird von AppError zurückgegeben
      if (!options.caption) options.caption = 'Eintrag';  // Tabellenname für Ausgaben in Snackbar
      if (this.cachingStatus[table] === 'failed') {
        this.appError.throw(6201, {
          msg: options.caption + ' konnte nicht geladen werden, daher ist auch das Schreiben neuer Daten nicht möglich.',
          observer,
          snackbar: true
        });
        return;
      }
      console.log('API create', table);
      let dataForApi;
      if (tableModel !== undefined) {  // Nicht für "System"-Tabellen (kwi_files, ...)
        if (!options.anon && this.cachedData[table] && options.batchCounter !== -1) { // bei options.batchCounter === -1 wurde der Status 'caching' für diesen BatchVorgang bereits gesetzt.
          this.batchCounters[table].create.set(options.batchCounter);
          this.cachingStatus[table] = 'caching';  // Tabellenstatus auf 'caching' setzen
        }
        // Daten vorbereiten / entkoppeln: (UUID wird aber noch direkt in data.id geschrieben)
        dataForApi = tableModel.prepareDataForApi(data, true, options.isGuestform);
      }
      else dataForApi = data;
      // console.log(JSON.stringify(data));

      this.httpClient.post(`${this.apiEndpoint}/records/${table}`, JSON.stringify(dataForApi)).subscribe({
        next: (apiData: any) => {
          if (tableModel !== undefined) { // Nicht für "System"-Tabellen (kwi_files, ...)
            data.id = apiData;            // Von API zurückgegebene id zu Datenreihe hinzufügen
            // Limits:
            // Neuer Eintrag in Haupttabelle (z.B. Kurse) => neuen Datensatz in Limit-Tabelle anlegen zum Speichern der Limit-Infos
            if (tableModel?.config?.limits?.active) {
              this.createLimit(tableModel.config.limits.table, data.id, data[tableModel.config.limits.copyCol] ?? 0);
            }
            // Neuer Eintrag in Count-Table (z.B. Anmeldungen) => current_count um 1 erhöhen
            if (tableModel?.config?.limits?.countTableIdCol && data[tableModel.config.limits.countTableIdCol] && !options.batchCounter) {
              this.limitCount(tableModel.config.limits.table, data[tableModel.config.limits.countTableIdCol], 1);
            }
            // BatchCounter (für Limits und - weiter unten - makeDataArrays);
            if (options.batchCounter && this.batchCounters[table].create.countDown() === 0) { // Counter updaten
              // Am Ende des Batch-Befehls (bzw. der Batch-Befehle) ausführen (current_count um Anzahl der im Batch-Befehl geschriebenen Zeilen erhöhen)
              this.limitCount(tableModel.config.limits.table, data[tableModel.config.limits.countTableIdCol], this.batchCounters[table].create.total);

            }
            if (!options.anon) {            // Nicht im Anon-Modus
              // Filemanager:
              if (this.filemanager && tableModel.hasFileCols && !options.guest) {
                this.filemanager.removeOrphans(data, table);
                // Einträge in Dateiverzeichnis (kwi_files) machen:
                for (const colId of tableModel.getColumnsByFilter((col) => col.hasFiles(data) === true)) {
                  this.filemanager.convertToFileArray(data[colId]).forEach((filename) => {
                    this.maybeMakeFileRef(filename, table, data.id, colId);
                  });
                }
              }
              // Cache updaten:
              if (this.cachedData[table]) { // Sicherheitsabfrage. Im Gast-Modus wird Tabelle u.U nicht gecached.
                this.cachedData[table].push(data);  // Datenreihe in Cache hinzufügen
                console.log('Updated Cache Table: ', this.cachedData[table]);
                // makeDataArrays entweder direkt aufrufen oder am Ende eines Batch-Befehls:
                if (!options.batchCounter || this.batchCounters[table].create.current === 0) {
                  this.makeDataArrays(table); // CachingSubject gibt erst am Ende von makeDataArrays next() aus.
                }
              }
            }
          }
          observer.next(apiData);
          observer.complete();
          if (!options.silent) this.snackbar.showMessage(options.caption + ' neu angelegt');
        },
        error: (error: HttpErrorResponse) => {
          if (error.status !== 401) { // 401 wird schon im UnauthorizedInterceptor verarbeitet
            this.appError.throw(6200, {
              msg: options.caption + ' konnte nicht angelegt werden: ' + error.message,
              observer,
              snackbar: !options.silent,
              snackbarMsg: options.caption + ' konnte nicht angelegt werden.',
              noSentryCapture: !!options.noSentryCaptureOnError,
            });
          }
        }
      });
    });
  }


  update(table: string, data: any, rowId: number | string, options?: DsOptions):
    Observable<any> | null {
    /* Datenreihe in Tabelle aktualisieren
       Während des Vorgangs wird diese Tabelle auf 'caching' gestellt und am Ende im Cache aktualisiert.
       Rückgabewert: Observable, id in apiData.
       -----
       BatchUpdate (options.batchOp = true)
       * als rowId wird ein String mit allen Ids übergeben (id1, id2, ...)
       * als data wird ein Array aller Daten übergeben
       Einschränkungen: 
       - Filemanager wird übersprungen => Darf keine Dateieinträge haben
       - Nicht für Tabellen mit Limits verwendbar
       - Cache wird nicht angepasst => muss durch Neuladen der Seite o.ä. erneuert werden
       - Aufgrund der URL-Beschränkung auf 2KB sollten bei Verwendung von UUIDs max. 30 Datensätze übergeben werden, bei Verwendung von numerischen Ids max 120 Datensätze
         Die Daten sollten also in Gruppen dieser Größe aufgeteilt werden und der batchCounter dafür verwendet werden.
    */
    if (!options) options = {};
    const tableModel: Table | undefined = this.tablesModel.tables[table];
    if (typeof rowId === 'number') rowId = rowId.toString();
    return new Observable((observer) => {
      if (!this.checkInitAndAuth('updateRow', table, options.anon, options.guest, observer, table !== 'kwi_users')) return; // Readonly-Mode: nur User-Table update erlaubt:
      if (!options.caption) options.caption = 'Eintrag';  // Tabellenname für Ausgaben in Snackbar
      if (this.cachingStatus[table] === 'failed') {
        this.appError.throw(6301, {
          msg: options.caption + ' konnte nicht geladen werden, daher ist auch das Bearbeiten der Daten nicht möglich.',
          observer,
          snackbar: true
        });
        return;
      }
      let dataForApi;
      if (tableModel !== undefined) {  // Nicht für "System"-Tabellen (kwi_user, kwi_files, ...)
        if (!options.anon && this.cachedData[table] && options.batchCounter !== -1) { // bei options.batchCounter === -1 wurde der Status 'caching' für diesen BatchVorgang bereits gesetzt.
          this.batchCounters[table].update.set(options.batchCounter);
          this.cachingStatus[table] = 'caching';  // Tabellenstatus auf 'caching' setzen
        }
        // Daten vorbereiten / entkoppeln:
        if (options.batchOp) {
          dataForApi = data.map((row) => tableModel.prepareDataForApi(row, false, options.isGuestform));
        } else {
          dataForApi = tableModel.prepareDataForApi(data, false, options.isGuestform);
        }
      }
      else dataForApi = data;

      this.httpClient.put(`${this.apiEndpoint}/records/${table}/${rowId}`, JSON.stringify(dataForApi)).subscribe({
        next: (apiData: any) => {
          // Dateiverwaltung: (nicht im Anon- oder Gastmodus oder für BatchOps verfügbar)
          if (this.filemanager && !options.anon && !options.guest && !options.batchOp && tableModel !== undefined && tableModel.hasFileCols) {
            // Nach gelungenem Updaten: betreffende Orphans aus der Liste löschen
            this.filemanager.removeOrphans(data, table);
            // ausgetauschte Files (widows) und gelöschte Files vom Server löschen, falls sie nicht mehr gebraucht werden
            const filesToDelete = this.filemanager.getWidowedFiles(table, rowId as string);
            // console.log('update: filesToDelete', filesToDelete);
            // Dazu zunächst überprüfen, welche Dateien noch gebraucht werden:
            this.deleteFileFilter(this.filemanager.makeArrayFromFileObjects(filesToDelete)).subscribe((filteredFiles) => {
              // console.log('update: filteredFiles', filteredFiles);
              // Nicht mehr gebrauchte Dateien löschen:
              if (filteredFiles?.length) this.filemanager.deleteFiles(null, filteredFiles).subscribe();

              // Dateiverzeichnis aktualisieren (darf erst hier passieren, weil sonst deleteFileFilter falsche Ergebnisse liefert)
              // Alle Einträge aus Dateiverzeichnis löschen, egal ob Dateien gelöscht wurden oder nicht:
              for (const fileEntry of filesToDelete) {
                for (const filename of this.filemanager.convertToFileArray(fileEntry.files)) {
                  this.deleteFileRef(filename, table, rowId as string, fileEntry.colId);
                }
              }
              // Neue Einträge in Dateiverzeichnis (kwi_files) machen, falls nötig:
              // Hierfür müssen alle entsprechenden Spalten durchgegangen werden, um die passende SpaltenId zu bekommen
              for (const colId of tableModel.getColumnsByFilter((col) => col.hasFiles(data) === true)) {
                this.filemanager.convertToFileArray(data[colId]).forEach((filename) => {
                  this.maybeMakeFileRef(filename, table, data.id, colId);
                });
              }
            });
          }
          // Limits (nicht für BatchOps verfügbar):
          if (!options.batchOp && tableModel?.config?.limits?.active && data[tableModel.config.limits.copyCol]) {
            this.updateLimit(tableModel.config.limits.table, data.id, data[tableModel.config.limits.copyCol] ?? 0);
          }
          // Cache updaten:
          if (!options.batchOp && !options.anon && this.cachedData[table]) {
            //  RowId ist manchmal ein String und manchmal eine Zahl
            const objIndex = this.cachedData[table].findIndex((obj => obj.id.toString() === rowId.toString())); // finde ObjektIndex in Cache Data
            if (objIndex !== -1) this.cachedData[table][objIndex] = data;                                     // aktualisiere Datenreihe in Cache
            console.log('Updated Cache Table: ', this.cachedData[table]);
            // makeDataArrays entweder direkt aufrufen oder am Ende eines Batch-Befehls:
            if (options.batchCounter) {
              // Counter updaten:
              if (this.batchCounters[table].update.countDown() === 0) {
                // Am Ende des Batch-Befehls (bzw. der Batch-Befehle) ausführen:
                this.makeDataArrays(table);
              }
            }
            else this.makeDataArrays(table); // CachingSubject gibt erst am Ende von makeDataArrays next() aus.        
          }
          observer.next(apiData);
          observer.complete();
          if (!options.silent) this.snackbar.showMessage(options.caption + ' gespeichert');
        },
        error: (error: HttpErrorResponse) => {
          if (error.status !== 401) { // 401 wird schon im UnauthorizedInterceptor verarbeitet
            this.appError.throw(6300, {
              msg: options.caption + ' konnte nicht gespeichert werden: ' + error.message,
              observer,
              snackbar: !options.silent,
              snackbarMsg: options.caption + ' konnte nicht gespeichert werden.',
              noSentryCapture: !!options.noSentryCaptureOnError,
            });
          }
        }
      });
    });
  }


  delete(table: string, rowId: number | string, options?: DsOptions): Observable<any> | null { // nur caption und silent in options verwendet
    /* Datenreihe aus Tabelle löschen
       - Verknüpfte Dateien löschen (falls sie nicht mehr gebraucht werden)
       * Anon / Guests dürfen grundsätzlich nicht löschen
       * Während des Vorgangs wird diese Tabelle auf 'caching' gestellt und am Ende im Cache aktualisiert.
       * Rückgabewert: Observable<id in apiData>.
    */

    if (!options) options = {};
    const tableModel: Table | undefined = this.tablesModel.tables[table];
    return new Observable((observer) => {
      if (!this.checkInitAndAuth('delete', table, false, false, observer, false)) return;
      if (!options.caption) options.caption = 'Eintrag';  // Tabellenname für Ausgaben in Snackbar
      if (this.cachingStatus[table] === 'failed') {
        this.appError.throw(6401, {
          msg: options.caption + ' konnte nicht geladen werden, daher ist auch das Löschen der Daten nicht möglich.',
          observer,
          snackbar: true
        });
        return;
      }
      if (tableModel !== undefined) {  // Nicht für "System"-Tabellen (kwi_files, ...)
        // console.log('DataService Delete', table, rowId, this.cachingStatus[table], options);
        if (this.cachedData[table] && options.batchCounter !== -1) { // bei options.batchCounter === -1 wurde der Status 'caching' für diesen BatchVorgang bereits gesetzt.
          this.batchCounters[table].delete.set(options.batchCounter);
          this.cachingStatus[table] = 'caching';  // Tabellenstatus auf 'caching' setzen
        }
      }
      this.httpClient.delete(`${this.apiEndpoint}/records/${table}/${rowId}`).subscribe({
        next: (apiData: any) => {

          if (tableModel !== undefined) {
            // Ungenauer Vergleich hier notwendig, weil RowId manchmal ein String und manchmal eine Zahl ist (ToDo: fix!)
            // eslint-disable-next-line eqeqeq
            const objIndex = this.cachedData[table].findIndex((obj) => obj.id == rowId);  // finde ObjektIndex in Cache Data
            // console.log('Delete Index', objIndex, this.cachedData[table][objIndex]['id'], rowId);
            if (objIndex > -1) {
              // Filemanager:
              if (this.filemanager && tableModel.hasFileCols) {
                for (const colId of tableModel.getColumnsByFilter((col) => col.hasFiles(this.cachedData[table][objIndex]) === true)) {
                  const filenames = this.filemanager.convertToFileArray(this.cachedData[table][objIndex][colId]);
                  this.deleteFileFilter(filenames).subscribe((filteredFiles) => {
                    if (filteredFiles?.length) this.filemanager.deleteFiles(null, filteredFiles).subscribe();
                    // Eintrag in Dateiverzeichnis jedenfalls löschen:
                    for (const filename of filenames) this.deleteFileRef(filename, table, rowId.toString(), colId);
                  });
                }
              }
              // Limits:
              if (tableModel?.config?.limits?.active) { // Eintrag aus Haupttabelle (z.B. Kurse) wird gelöscht - alle Limit-Infos zu diesem Eintrag löschen
                this.deleteLimit(tableModel.config.limits.table, rowId.toString());
              }
              if (tableModel?.config?.limits?.countTableIdCol && this.cachedData[table][objIndex][tableModel.config.limits.countTableIdCol] && !options.batchCounter) {
                // Eintrag aus Count-Table (z.B. Anmeldungen) wird gelöscht - current_count um 1 senken
                this.limitCount(tableModel.config.limits.table, this.cachedData[table][objIndex][tableModel.config.limits.countTableIdCol], -1);
              }
              // BatchCounter (für Limits und makeDataArrays);
              if (options.batchCounter && this.batchCounters[table].delete.countDown() === 0) {
                // console.log('Delete Limit Counter', tableModel.config.limits.table, this.cachedData[table][objIndex][tableModel.config.limits.countTableIdCol], -this.batchCounters[table].delete.total);
                // Am Ende des Batch-Befehls (bzw. der Batch-Befehle) ausführen:
                this.limitCount(tableModel.config.limits.table, this.cachedData[table][objIndex][tableModel.config.limits.countTableIdCol], -this.batchCounters[table].delete.total);
              }
              // Entweder direkt oder am Ende eines Batch-Befehls: Datenreihe aus Cache löschen und makeDataArrays aufrufen :
              if (!options.batchCounter || this.batchCounters[table].delete.current === 0) {
                this.cachedData[table].splice(objIndex, 1);                    // Datenreihe aus Cache löschen
                console.log('Updated Cache Table: ', this.cachedData[table]);
                this.makeDataArrays(table); // CachingSubject gibt erst am Ende von makeDataArrays next() aus.
              }
            }
            else {
              this.appError.throw(6451, {
                msg: options.caption + ' erfolgreich gelöscht. Etwaige zugehörige Einträge im Dateiverzeichnis konnten nicht gelöscht werden.',
                observer,
                snackbar: !options.silent,
                noSentryCapture: !!options.noSentryCaptureOnError,
              });

            }
          }

          observer.next(apiData);
          observer.complete();
          if (!options.silent) this.snackbar.showMessage(options.caption + ' gelöscht');

        },
        error: (error: HttpErrorResponse) => {
          if (error.status !== 401) { // 401 wird schon im UnauthorizedInterceptor verarbeitet
            this.appError.throw(6400, {
              msg: options.caption + ' konnte nicht gelöscht werden: ' + error.message,
              observer,
              snackbar: !options.silent,
              snackbarMsg: options.caption + ' konnte nicht gelöscht werden.',
              noSentryCapture: !!options.noSentryCaptureOnError,
            });
          }
        }
      });
    });
  }


  login(username: string, password: string): Observable<any> {
    /*  */
    return new Observable((observer) => {
      const login = { username, password };
      this.httpClient.post(`${this.apiEndpoint}/login`, JSON.stringify(login)).subscribe({
        next: (apiData: any) => {
          console.log('Login: ', apiData);
          if (apiData.activeSession == null) {  // '===' liefert false negative
            observer.next(apiData); // App-Login starten
            this.checkUserRights(false, true, observer);
            this.init(); // neu initialisieren, weil die App vor dem Login im Anon-Modus initialisiert war.
          }
        },
        error: (error) => {
          observer.error(error.message);
          this.snackbar.showMessage('Login nicht erfolgreich.');
        }
      });
    });
  }

  signup(username: string, email: string, password: string): Observable<any> {
    /*  */

    const formData: FormData = new FormData();
    formData.append('username', username);
    formData.append('email', email);
    formData.append('password', password);
    return new Observable((observer) => {
      this.httpClient.post(this.signupEndpoint, formData).subscribe({
        next: (apiData: any) => {
          console.log('Signup: ', apiData);
          observer.next(apiData);
        },
        error: (error) => {
          observer.error(error.message);
          this.snackbar.showMessage('E-Mail konnte nicht angefordert werden.');
        }
      });
    });
  }

  checkUserRights(afterAppInit?: boolean, afterLogin?: boolean, observer?, isUserRequest?: boolean): void {
    /* Bei App-Initialisierung und bei angemeldetem Benutzer in regelmäßigen Abständen: 
       1) werden über den /me Endpoint der API die Daten des aktuell angemeldeten Benutzers geholt (falls vorhanden)
          - falls abweichend vom gespeicherten Benutzer: (Auto-) Login/Logout initiieren
       2) User-Management (siehe unten)
  
       Hinweis: observer wird derzeit nur von der Funktion login() übergeben - observer.error() sollte daher nur ausgelöst werden, wenn der Fehler den Login verhindert
    */

    /* Alt: Überprüfung userLoggedin / PHP Timeout  
    if (!this.auth.userLoggedin) return;
    // PHP Timeout überprüfen:
    if (moment().subtract(this.appConfig.session.phpTimeout, 'minutes').isSameOrAfter(this.auth.user.keepalive, 'minute')) {
      this.snackbar.showMessage('Zu lange inaktiv, bitte neu einloggen!');
      this.logout();
      return;
    } */

    // console.log('checkUserRights', afterAppInit);

    if (!this.auth.userLoggedin && !afterAppInit) return;

    // Aktuellen API-Benutzer abrufen:
    this.httpClient.get(`${this.apiEndpoint}/me`).subscribe({
      next: (apiData: any) => {
        // Auto-Login:
        if (!this.auth.userLoggedin) {
          console.log('Auto-Login...');
          this.auth.userlogin(apiData);
        }
        // Wenn in der App ein anderer User aktiv scheint als in API angemeldet ist, diesen User abmelden: 
        // (wüsste zwar nicht, wie das passieren sollte, aber zur Sicherheit)
        else if (this.auth.user.id !== apiData.id) {
          this.appError.throw(6603);
          this.logout();
          return;
        }
        this.userManagement(afterLogin, observer, isUserRequest);
      },
      error: (error: HttpErrorResponse) => { // Fehler von /me - Endpoint
        if (error instanceof HttpErrorResponse) {  // 
          if (error.status === 401) { // = Kein Benutzer in API angemeldet
            // Auto-Logout:
            if (this.auth.userLoggedin) {
              this.isLoggingOutBecauseNoApiUser = true;
              this.snackbar.showMessage('Bitte neu einloggen!');
              this.logout(true);
            }
          } else {
            this.appError.throw(6602, {
              msg: 'Fehler bei Abfrage des API-Benutzers. /me endpoint meldet: ' + error.message, observer,
              snackbar: true, snackbarMsg: 'Fehler bei der Abfrage der Benutzerberechtigungen. Bitte neu anmelden!', snackbarDuration: 10
            });
            // ev. Auto-Logout?
          }
        }
      }
    });
  }

  userManagement(initial?: boolean, observer?, isUserRequest?: boolean): void {
    /* a) normale Benutzer: User-Management durchführen (max. Benutzerzahl, max. Benutzer mit Schreibberechtigung überprüfen)
       b) Demobenutzer: Demo-User-Management (max. Anzahl Demo-Benutzer überprüfen) 
     */

    // Vor dem User Management sichergehen, dass wir die aktuellsten Benutzerdaten haben:
    // z.B. könnte sich die Session-ID geändert haben, wenn in einem anderen Tab ein Logout und gleich wieder ein neuer Login stattgefunden hat.
    this.auth.getUserDataFromLocalStorage();
    const thisUser = this.auth.user;

    // Für normale Benutzer (außer Demo): Alle Benutzer mit aktiver Session holen (session != NULL):
    if (!thisUser.isDemo) {
      this.httpClient.get(`${this.apiEndpoint}/records/kwi_users?filter=session,nis`).subscribe({
        next: (data: any) => {
          const userdata = data.records;
          // Überprüfen, ob gleicher Benutzer schon angemeldet ist (und nicht timed out):
          const hasRowWithThisId = userdata.some((u) => u.id === thisUser.id);
          let row;
          if (hasRowWithThisId) row = userdata.find((u) => u.id === thisUser.id);
          if ((initial && hasRowWithThisId) || (!initial && hasRowWithThisId && row.session !== thisUser.session)) {
            if (!this.hasTimedOut(row.keepalive)) {
              this.auth.user.id = undefined;  // Wird hier gelöscht, damit die DB nicht durch diesen Login-Versuch upgedated wird
              this.logout();
              this.snackbar.showMessage('Dieser Benutzer ist bereits angemeldet und aktiv.', null, 10);
              return;
            }
          }
          if (this.auth.forceReadonly) {
            if (isUserRequest) {
              this.snackbar.showMessage('Schreibzugriff abgegeben, eingeschränkter Modus aktiv.', null, 10);
            }
          } else {
            // Überprüfen, ob maximale Anzahl Benutzer überschritten ist:
            const offset = initial ? 1 : 0;
            if (userdata.length + offset >= this.appConfig.session.maxUsers ||
              userdata.filter((u) => !u.readonly).length + offset >= this.appConfig.session.maxWriteUsers) {
              // Zunächst inaktive User rauswerfen:
              let remaining = 0;
              let writeUsers = 0;
              this.auth.currentWriteUsers = [];
              for (const user of userdata) {
                if (user.id === thisUser.id) continue;
                if (this.hasTimedOut(user.keepalive)) {
                  user.session = null;
                  user.readonly = false;
                  this.update('kwi_users', user, user.id, { caption: 'Login', anon: false, guest: false, silent: true }).subscribe({
                    next: (apiDataUpdate) => {
                      console.log('Kicked user', user.username);
                    },
                    error: (error) => {
                      console.log('Couldn\'t kick user', user.username);
                      // nichts weiter nötig?
                    }
                  });
                }
                else remaining++;
                if (!user.readonly) {
                  writeUsers++;
                  if (this.appConfig.session.showWriteUserName) this.auth.currentWriteUsers.push(user.username);
                }
              }
              if (remaining >= this.appConfig.session.maxUsers ||
                (writeUsers >= this.appConfig.session.maxWriteUsers && !this.appConfig.session.readonlyMode)) {
                console.log('Zu viele Benutzer angemeldet.');
                this.logout();
                if (initial) this.snackbar.showMessage('Es sind bereits zu viele Benutzer angemeldet.', null, 10);
                return;
              }
              if (writeUsers >= this.appConfig.session.maxWriteUsers && this.appConfig.session.readonlyMode) {
                console.log('maxWriteUsers überschritten, readonlyMode', this.auth.currentWriteUsers);
                if (initial) {
                  this.snackbar.showMessage('Es sind bereits Benutzer mit Schreibzugriff angemeldet. Eingeschränkter Zugriff erlaubt.', null, 10);

                }
                this.auth.user.readonly = true;
              }
            }
            if (thisUser.readonly) {
              if (thisUser.isDemo) {
                if (initial) this.snackbar.showMessage('Demo-Benutzer: Eingeschränkter Zugriff erlaubt');
              } else if (userdata.filter((u) => u.session && !u.readonly).length < this.appConfig.session.maxWriteUsers) {
                this.auth.user.readonly = false;
                this.snackbar.showMessage('Schreibzugriff ist jetzt möglich.', null, 10);
              } else if (isUserRequest) {
                this.snackbar.showMessage('Es sind andere Benutzer mit Schreibzugriff aktiv. Eingeschränkter Zugriff erlaubt.', null, 10);
              }
            }
          }
          this.keepalive();
          this.auth.saveUserData();
          if (observer) observer.complete();
        },
        error: (error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 404 && this.auth.userLoggedin) this.logout(true);
            this.appError.throw(6600, {
              msg: `Fehler bei Zugriff auf kwi_users: ${error.status} ${error.statusText}`
            });
          }
        }
      });
    }

    /* Für Demobenutzer:
       Aktive Sessions werden in DB-Spalte demo_sessions geschrieben (als Array)
       Wenn dieses Array die maximale Anzahl Demo-Sessions maxDemoSessions erreicht,
        - schreibt die jeweils älteste Session im Array ([0]) beim checkUserRights die aktuelle Zeit in die DB-Spalte keepalive
        - und setzt sich wieder an das Ende des Arrays.
       Beim Anmelden wird überprüft, ob die maximale Anzahl Demo-Sessions erreicht ist.
       Wenn ja, und die letzte Keepalive-Zeit abgelaufen ist, wird die hinterste Session aus dem Array gekickt.
    */
    else {
      this.httpClient.get(`${this.apiEndpoint}/records/kwi_users/${thisUser.id}`).subscribe({
        next: (data: any) => {
          let demoSessions: string[];
          demoSessions = JSON.parse(data.demo_sessions);
          if (!Array.isArray(demoSessions)) demoSessions = [];

          // Beim Login bzw. auch wenn eigene Session nicht in demoSessions steht, wird versucht, die Session hinzuzufügen
          // Letzteres kann sein, wenn Session nicht aktiv war (z.b. Tab geschlossen) und in der Zwischenzeit durch andere DemoSession verdrängt wurde.
          if (initial || !demoSessions.includes(thisUser.session)) {
            if (demoSessions === null) demoSessions = [thisUser.session];
            // Wenn Anzahl maxDemoSessions erreicht ist, etwaige inaktive Sessions entfernen, ansonsten Fehlermeldung
            if (demoSessions.length === this.appConfig.session.maxDemoSessions) {
              if (this.hasTimedOut(data.keepalive)) {
                demoSessions.shift();
              }
              else {
                this.logout();
                if (initial) this.snackbar.showMessage('Login nicht möglich, maximale Anzahl Demo-Benutzer erreicht.', null, 10);
                else this.snackbar.showMessage('Zu lange inaktiv und maximale Anzahl Demo-Benutzer erreicht.', null, 10);
                return;
              }
            }
            // Neue Session hinzufügen:
            demoSessions.push(thisUser.session);
            // wenn mit der eben gestarteten Session die maximale Anzahl Sessions erreicht ist, Keepalive setzen
            if (demoSessions.length === this.appConfig.session.maxDemoSessions) data.keepalive = this.auth.user.keepalive;
          }
          // Beim regelmäßigen checkUserRights: wenn maxDemoSessions erreicht, und diese Session die älteste ist:
          else if (demoSessions.length === this.appConfig.session.maxDemoSessions && demoSessions[0] === thisUser.session) {
            this.auth.user.keepaliveNow();             // (das könnte auch weggelassen werden und in data.keepalive einfach die aktuelle Zeit geschrieben werden)
            data.keepalive = this.auth.user.keepalive; // Keepalive von DemoUser auf jetzt setzen
            demoSessions.push(demoSessions.shift());   // diese Session ans Ende von demoSessions setzen.
          }
          data.demo_sessions = JSON.stringify(demoSessions);
          this.update('kwi_users', data, thisUser.id, { caption: 'Session', anon: true, silent: true }).subscribe({
            next: (apiDataUpdate) => {
              if (observer) observer.complete();
            },
            error: (error) => {
              this.appError.throw(6612, { observer });
            }
          });

        }, error: (error: HttpErrorResponse) => {
          if (error instanceof HttpErrorResponse) {
            if (error.status === 404 && this.auth.userLoggedin) this.logout(true);
            this.appError.throw(6601, {
              msg: `Fehler bei Zugriff auf kwi_users: ${error.status} ${error.statusText}`
            });
          }
        }
      });
    }

  }

  hasTimedOut(time: string): boolean {
    // überprüft, ob time schon länger als config.session.timeout her ist.
    return moment().subtract(this.appConfig.session.timeout, 'minutes').isAfter(time, 'second');
  }

  keepalive() {
    this.auth.user.keepaliveNow();
    this.update('kwi_users', this.auth.user, this.auth.user.id, { caption: 'Login', anon: false, guest: false, silent: true })
      .subscribe({
        next: (apiDataUpdate) => {
          console.log('Keepalive: ', this.auth.user.keepalive);
        },
        error: (error) => {
          this.appError.throw(6611);
        }
      });
  }

  logout(noApiLogout?: boolean) {
    /* Logout 1. Schritt:
      - CleanupFiles
      - falls User angemeldet, DB-Eintrag aktualisieren und so als abgemeldet kennzeichnen.
      - noApiLogout=true, wenn es ohnehin keine (gültige/funktionierende) Verbindung zur DB (mehr) gibt.
    */

    if (this.filemanager) this.filemanager.cleanupFiles();

    if (noApiLogout) {  // Auto-Logout, weil kein API-Benutzer mehr vorhanden
      this.logout2(true);
    }
    else {
      if (!this.auth.user.id) {
        this.logout2();
        return;
      }
      if (this.auth.user.isDemo) {
        this.demoLogout();
        return;
      }
      const data = { session: null, readonly: false };
      this.update('kwi_users', data, this.auth.user.id, { caption: 'Login', anon: false, guest: false, silent: true })
        .subscribe({
          next: (apiDataUpdate) => {
            this.logout2();
          },
          error: (error) => {
            this.snackbar.showMessage('Fehler beim Abmelden von Datenbank.');
            this.logout2();
          }
        });
    }
  }

  logout2(noApiLogout?: boolean) {
    /* Logout 2. Schritt:
      - Falls nötig, von API abmelden
      - Sonst sofort App-Abmeldung durchführen
    */
    setTimeout(() => {  // Etwas Zeit lassen, damit cleanupFiles sicher fertig ist.
      if (!noApiLogout) this.apiLogout();
      else this.auth.userlogout();
      this.isLoggingOutBecauseNoApiUser = false;
      return;
    }, 500);
  }

  demoLogout() {
    /* Diese Session vom Demo-User beenden. Diese SessionId aus demoSessions löschen. */
    this.httpClient.get(`${this.apiEndpoint}/records/kwi_users/${this.auth.user.id}`).subscribe({
      next: (data: any) => {
        let demoSessions: string[];
        demoSessions = JSON.parse(data.demo_sessions);
        if (!Array.isArray(demoSessions)) demoSessions = [];
        else demoSessions = demoSessions.filter((s) => s !== this.auth.user.session)
        data.demo_sessions = JSON.stringify(demoSessions);
        this.update('kwi_users', data, data.id, { caption: 'Session', anon: true, silent: true }).subscribe({
          next: (apiDataUpdate) => {
            this.apiLogout(); // ApiLogout (Logout von Datenbank)
          },
          error: (error) => {
            this.snackbar.showMessage('Fehler beim Abmelden von Datenbank.');
            this.logout2();
          }
        });
      },
      error: (error) => {
        this.snackbar.showMessage('Fehler beim Abmelden von Datenbank.');
        this.logout2();
      }
    });
  }

  apiLogout() {
    // ApiLogout (Logout von Datenbank)
    this.httpClient.post(`${this.apiEndpoint}/logout`, null).subscribe({
      next: (data) => {
        this.auth.userlogout();          // internes Logout und Cleanup, zurück zur Login-Seite
      },
      error: (error) => {
        if (!this.snackbar.isActive) this.snackbar.showMessage('Fehler beim Abmelden von API.');
        this.auth.userlogout();        // internes Logout und Cleanup, zurück zur Login-Seite
      }
    });
  }


  guestLogin(uuid: string): Observable<any> {
    console.log("DataService GuestLogin");
    return new Observable((observer) => {
      this.read(this.appConfig.guest.table, uuid, null, null, { anon: true, silent: true }).subscribe({
        next: (data) => {
          // Überprüfen, ob alle Vorgaben erfüllt sind, damit der Gast Zugriff erhält: 
          // (wird auch in API gemacht, aber hier noch einmal, um korrektes User-Feedback zu geben)
          if (!this.checkGuestAccount(data, observer)) return;

          // Guestname aus Template im Model vom guestTable machen:
          const guestname = this.tablesModel.tables[this.appConfig.guest.table].templates(data, this.appConfig.guest.templateId);
          this.auth.guestlogin(uuid, guestname);
          // DataService neu im Gastmodus initialisieren (außer es ist bereits ein User angemeldet, dann ist es nicht nötig)
          if (!this.auth.userLoggedin) this.init(false, true);
          observer.next(data);
        },
        error: (error) => {
          this.appError.throw(6710, {
            msg: 'Fehler bei der Gast-Anmeldung. API meldet: ' + error.message, observer,
            snackbar: true, snackbarMsg: 'Ungültiger Anmelde-Link'
          });
        }
      });
    });
  }

  checkGuestAccount(data: any, observer: Subscriber<any>): boolean {
    /* Die Verständigung des observers bei einem etwaigen Fehler wird von AppError übernommen */
    // Activation: im Gastdatensatz müssen alle diese Spalten auf true gesetzt sein, damit der Gast Zugriff erhält. [] => Funktion deaktiviert
    if (this.appConfig.guest.activationCols) {
      for (const activationCol of this.appConfig.guest.activationCols) {
        if (!data[activationCol]) {
          this.appError.throw(6711, { observer, snackbar: true });
          return false;
        }
      }
    }
    // ActiveFrom: im Gastdatensatz muss der Wert dieser Spalte heute oder danach sein, damit der Gast Zugriff erhält. '' => Funktion deaktiviert
    if (this.appConfig.guest.activeFromCol && data[this.appConfig.guest.activeFromCol] &&
      moment().isBefore(data[this.appConfig.guest.activeFromCol], 'day')) {
      this.appError.throw(6712, { observer, snackbar: true });
      return false;
    }
    // ActiveUntil: im Gastdatensatz muss der Wert dieser Spalte heute oder davor sein, damit der Gast Zugriff erhält. '' => Funktion deaktiviert
    if (this.appConfig.guest.activeUntilCol && data[this.appConfig.guest.activeUntilCol] &&
      moment().isAfter(data[this.appConfig.guest.activeUntilCol], 'day')) {
      this.appError.throw(6713, { observer, snackbar: true });
      return false;
    }
    return true;
  }

  guestKeepalive(uuid: string): Observable<any> {
    console.log("DataService GuestKeepalive");
    return new Observable((observer) => {
      this.read(this.appConfig.guest.table, uuid, null, null, { anon: true, silent: true }).subscribe({
        next: (data) => {
          observer.next(data);
        },
        error: (error) => {
          this.appError.throw(6710, {
            observer,
            snackbar: true, snackbarMsg: 'Gast-Account scheint nicht mehr vorhanden zu sein. Es können u.U. keine Daten mehr gespeichert werden.'
          });
        }
      });
    });
  }
  guestApiLogout() {
    /* Um die PHP-Session beim GuestLogout zu beenden, wird ein "leerer" Lese-Befehl (id = 0) der Guest-Tabelle geschickt.
       Im afterHandler der API wird daraufhin die Guest-Session beendet. Die Fehlermeldung der API wird ignoriert.
    */
    this.read(this.appConfig.guest.table, '0', null, null, { anon: true, silent: true, noSentryCaptureOnError: true }).subscribe();
  }

  guestRemind(email: string, redirectTo: string): Observable<any> {
    /* Email verschicken, um Anmelde-Link neu zu erhalten
     */

    const formData: FormData = new FormData();
    formData.append('guestTable', this.appConfig.guest.table);
    formData.append('redirectTo', redirectTo);
    formData.append('email', email);
    return new Observable((observer) => {
      this.httpClient.post(this.gremindEndpoint, formData).subscribe({
        next: (apiData: any) => {
          console.log('GRemind: ', apiData);
          observer.next(apiData);
        },
        error: (error) => {
          console.log(error);
          observer.error(error.message);
        }
      });
    });
  }

  emptyCache(): void {
    // Empty Cache and vars:
    this.cachedData = [];
    this.cachedDataIndexed = {};
    this.cachingStatus = {};
    // cachingSubjects mit complete beenden (weiß nicht, ob das unbedingt nötig wäre...)
    for (const table of this.tables) {
      if (this.cachingSubjects$[table]) this.cachingSubjects$[table].complete();
    }
    this.cachingSubjects$ = [];
    this.isInitialized = false;
  }

  private makeTemplatesAndAutoOptions(table: string, helperTables: string[], data: any, isSingleRow: boolean, saveMainData: boolean): any {
    /* 1) wendet getTemplates auf alle helperTables an und speichert diese Daten dann in cachedData und cachedDataIndexed
       2) wendet getTemplates auf die übergebenen Daten an. Wenn das nicht eine einzelne Zeile ist, werden die Daten ebenfalls in cachedData(Indexed) gespeichert
       - wendet makeAutoOptions auf alle helperTables und den mainTable an.
    */
    // HelperTables
    if (!helperTables) helperTables = [];
    for (const helperTable of helperTables) {

      // Wird nur durchgeführt, wenn cachingStatus[helperTable] initialisiert wurde. Falls nicht, wird dieser helperTable übersprungen.
      // Dies kann z.B. sein, wenn der helperTable zwar als solcher definiert wurde, aber laut Gast- / Anon-Rechten nicht gecached werden darf.
      if (this.cachingStatus[helperTable]) {

        // Wenn Table nicht geladen werden konnte, abbrechen!
        if (this.cachingStatus[helperTable] === 'failed') continue;

        // console.log("makeTemplatesAndAutoOptions helperTable", helperTable, this.cachedData[helperTable], this.cachingStatus[helperTable]);
        const tableModel: Table = this.tablesModel.tables[helperTable];
        if (this.cachingStatus[helperTable] === 'caching') {
          this.appError.throw(6181, { msg: 'makeTemplatesAndAutoOptions: HelperTable ' + helperTable + ' wird gerade gecached, Abbruch', noSentryCapture: true });
          continue;
        }
        this.cachingStatus[helperTable] = 'caching';
        this.cachedData[helperTable] = tableModel.getTemplates(this.cachedData[helperTable], this.cachedDataIndexed, false);
        this.makeDataArrays(helperTable);
        tableModel.makeAutoOptions(this.cachedData);
      }
    }
    // MainTable:
    // console.log("makeTemplatesAndAutoOptions mainTable", table, isSingleRow);
    const tableModel: Table = this.tablesModel.tables[table];

    // Wenn Table nicht geladen werden konnte, abbrechen!
    if (this.cachingStatus[table] === 'failed') return null;

    data = tableModel.getTemplates(data, this.cachedDataIndexed, isSingleRow);
    if (this.cachingStatus[table] === 'caching') {
      this.appError.throw(6181, { msg: 'makeTemplatesAndAutoOptions: MainTable ' + table + ' wird gerade gecached, Abbruch', noSentryCapture: true });
      return data;
    }
    if (saveMainData) {
      this.cachingStatus[table] = 'caching';
      this.cachedData[table] = data;
      this.makeDataArrays(table);
    }
    tableModel.makeAutoOptions(this.cachedData);
    return data;
  }

  private makeDataArrays(table: string): void {
    /* Indexierten Cache machen: Objekte und Iterables (Arrays)
       - kann nur durchgeführt werden, wenn cachingStatus/cachedData[table] bereits initialisiert wurde. Falls nicht, wird abgebrochen.
     */
    // console.log('makeDataArrays', table);
    if (!this.cachingStatus[table] || !this.cachedData[table]) {
      this.appError.throw(6051, { msg: 'MakeDataArrays abgebrochen, weil cachingStatus[table] oder cachedData[table] nicht vorhanden. Table ' + table });
      return;
    }
    this.cachedDataIndexed[table] = [];
    for (const row of this.cachedData[table]) {
      this.cachedDataIndexed[table][row.id] = row;    // Indexierten Cache
    }
    // Caching für table beendet:
    this.cachingStatus[table] = 'cached';
    // console.log('Table is now cached', table);
    if (this.cachingSubjects$[table]) this.cachingSubjects$[table].next();
  }

  private checkInitAndAuth(command: string, table: string, anon: boolean, guest: boolean, observer: Subscriber<any>, checkReadonly: boolean): boolean {
    /* Überprüft zunächst, ob Service initialisert ist und dann die Berechtigung zur Ausführung des Befehls 'command'
       - wenn ein User angemeldet ist, gilt die Berechtigung unabhängig von command
         bei checkReadonly = true wird dann noch überprüft, ob der User auch Lese- und Schreibberechtigung hat.
       - Für Demo-User wird (nur bei command 'create') überprüft, ob der User in der betreffenden Tabelle neue Datensätze anlegen darf
       - wenn kein User angemeldet ist:
         * bei guest = true => Überprüfen ob Gast angemeldet und command in guestRights der betreffenden Tabelle erlaubt ist.
         * bei anon = true => Überprüfen ob command in anonRights der betreffenden Tabelle erlaubt ist.
         Es sollte nur entweder anon oder guest true sein. Ansonsten hat der Wert in guestRights Vorrang gegenüber dem in anonRights
    */
    if (!this.checkInit(observer)) return false;
    if (!this.auth.userLoggedin) {
      // Gast:
      if (guest) {
        if (this.auth.checkAuth(false, true)) { // Ist Gast bereits angemeldet?
          // PHP Timeout für GuestLogin überprüfen: 
          if (moment().subtract(this.appConfig.session.phpTimeout, 'minutes').isSameOrAfter(this.auth.guest.keepalive, 'minute')) {
            // Wenn timeout bereits überschritten wurde, noch einmal GuestLogin durchführen:
            this.guestLogin(this.auth.guest.id).subscribe();
          }
          // Guest Keepalive jedenfalls erneuern, weil ja mit jeder API-Anfrage die Session wieder weiterläuft:
          this.auth.guest.keepaliveNow();
          return this.checkRights(table, 'guestRights', command, observer);
        }
        else return false;
      }
      // Anon:
      if (anon) return this.checkRights(table, 'anonRights', command, observer);
      // User nicht angemeldet:
      this.appError.throw(1203, { observer, snackbar: true }); // Ohne Autorisierung und Init geht gar nix
      return false;
    }
    // bei checkReadonly = true wird überprüft, ob der User auch Lese- und Schreibberechtigung hat:

    if (checkReadonly && this.auth.user.readonly) {
      // wenn wir im Readonly-Modus sind, überprüfen, ob für dieses Modul und diese Tablle ein UpdateRow doch stattfinden darf:
      const urlParams = this.router.routerState.snapshot.url.split('/');  // Zur Bestimmung des aktiven Moduls (eigentlich erste Pfadebene)
      // console.log(5, urlParams[1], this.appConfig.allowCustomReadonlyUserUpdateRightsForModules?.includes(urlParams[1]), this.checkRights(table, 'readonlyUserRights', command, observer));
      if (urlParams?.length && this.appConfig.allowCustomReadonlyUserUpdateRightsForModules?.includes(urlParams[1])) {
        return this.checkRights(table, 'readonlyUserRights', command, observer);
      }
      else {
        this.appError.throw(1210, { observer, snackbar: true });  // Meldung: Keine Schreibberechtigung
        return false;
      }
    }
    // Für Demo-User wird (nur bei command 'create') überprüft, ob der User in der betreffenden Tabelle neue Datensätze anlegen darf:
    if (command === 'create' && this.auth.user.isDemo && !this.tablesModel.tables[table].config.demoUser?.allowCreate) {
      this.appError.throw(1241, { observer, snackbar: true });
      return false;
    }
    return true;
  }
  private checkInit(observer?: Subscriber<any>): boolean {
    if (!this.isInitialized && observer) this.appError.throw(6010, { observer });
    return this.isInitialized;
  }


  private checkRights(table: string, type: "anonRights" | "guestRights" | "readonlyUserRights", right: string, observer?: Subscriber<any>): boolean {
    const ret = this.tablesModel.checkTableRight(table, type, right);
    let errorNumber: number;
    let modusText: string;
    if (type === 'anonRights') {
      errorNumber = 1220;
      modusText = 'anonymen Modus';
    }
    if (type === 'guestRights') {
      errorNumber = 1230;
      modusText = 'Gastmodus';
    }
    if (type === 'readonlyUserRights') {
      errorNumber = 1211;
      modusText = 'eingeschränkten Modus';
    }
    if (!ret && observer) {
      this.appError.throw(errorNumber, {
        msg: `Diese Operation ist für die Tabelle ${table} im ${modusText} nicht verfügbar: ${right}.`,
        observer,
        noSentryCapture: true,
      });
    }
    return ret;
  }

  /* FILE REF (DATEIVERZEICHNIS) */

  public maybeDeleteFiles(filenames: string[]): void {
    /* Überprüfen, wie oft filename im Dateiverzeichnis in kwi_files vorkommt.
       wenn genau 1x, Datei löschen und Eintrag in kwi_files ebenfalls löschen
    */
    this.deleteFileFilter(filenames).subscribe((filteredFiles) => {
      this.filemanager.deleteFiles(null, filenames).subscribe();

    });
  }

  public maybeMakeFileRef(filename: string, refTable: string, refId: string, refCol: string): void {
    /* Eintrag in Dateiverzeichnis in kwi_files machen, falls dieser für gleichen Filename, refTable und refId nicht schon vorhanden ist
       - gibt bei Erfolg true zurück
    */
    this.checkFileRef(filename, refTable, refId, refCol).subscribe((apiData) => {
      // console.log('maybeMakeFileRef apiData', apiData);
      if (apiData.length > 0) return;
      else {
        this.create('kwi_files', { filename, ref_table: refTable, ref_id: refId, ref_col: refCol }, { silent: true }).subscribe({
          next: (apiData) => {
            console.log(`Eintrag ins Dateiverzeichnis für Datei ${filename} geschrieben.`, apiData);
          },
          error: (error) => {
            this.appError.throw(6973);
          }
        });
      }

      return apiData;
    });
  }

  public deleteFileRef(filename: string, refTable: string, refId: string, refCol: string): void {
    /* Eintrag aus Dateiverzeichnis in kwi_files löschen, falls vorhanden
     */
    this.checkFileRef(filename, refTable, refId, refCol).subscribe((apiData) => {
      // console.log('deleteFileRef apiData', apiData);
      if (!apiData.length) return;
      else {
        this.delete('kwi_files', apiData[0].id, { silent: true }).subscribe({
          next: () => {
            console.log(`Eintrag ${apiData[0].id} für Datei ${filename} aus Dateiverzeichnis gelöscht.`);
          },
          error: (error) => {
            this.appError.throw(6977);
          }
        });
      }
    });
  }

  public checkFileRef(filename: string, refTable?: string, refId?: string, refCol?: string): Observable<any> {
    /* Gibt alle Einträge im Dateiverzeichnis für eine bestimmte Datei zurück
       - Es kann entweder nur nach filename gesucht werden, oder auch table, id und col als zusätzliche Filter angegeben werden 
     */
    const filter = ['filename,eq,' + filename];
    if (refTable) filter.push('ref_table,eq,' + refTable);
    if (refId) filter.push('ref_id,eq,' + refId);
    if (refCol) filter.push('ref_col,eq,' + refCol);
    // console.log('checkFileRef parameter', filename, refTable, refId, refCol, filter);
    return new Observable((observer) => {
      this.read('kwi_files', null, new ReadArgs({ filter })).subscribe({
        next: (apiData) => {
          // console.log('checkFileRef apiData', apiData);
          observer.next(apiData);
          observer.complete();
        },
        error: (error) => {
          this.appError.throw(6971, { observer });
        }
      });
    });
  }

  deleteFileFilter(filenames: string[]): Observable<string[]> {
    /* Filtert die übergebene Liste der Dateinamen: 
       alle Dateinamen, die in kwi_files mehr als einmal vorkommen, werden aus der Liste entfernt.
       Diese Dateien sollen nicht vom Server gelöscht werden, weil sie ja mehr als einmal verwendet werden.
     */

    return new Observable((observer) => {
      if (!filenames) this.appError.throw(6978);
      // Alle Einträge zu allen Dateien in einer DB-Abfrage holen:
      const filter = ['filename,in,' + filenames.join(',')];
      this.read('kwi_files', null, new ReadArgs({ filter })).subscribe({
        next: (apiData) => {
          // console.log('deleteFileFilter apiData', apiData);
          const output = [];
          for (const filename of filenames) {
            if (apiData.filter((d) => d.filename === filename).length <= 1) output.push(filename);
          }
          observer.next(output);
          observer.complete();
        },
        error: (error) => {
          this.appError.throw(6979);
        }
      });
    });
  }

  // LIMITS: 

  public readLimits(limitTable: string): Observable<any> {
    return new Observable((observer) => {
      this.httpClient.get(`${this.apiEndpoint}/records/${limitTable}`).subscribe({
        next: (data: any) => {
          if (data.records) data = data.records;
          observer.next(data);
          observer.complete();
        },
        error: (error: HttpErrorResponse) => {
          this.appError.throw(6161, { observer, snackbar: true });
        }
      });
    });
  }

  createLimit(limitTable: string, id: string, limitValue: number): void {
    this.httpClient.post(`${this.apiEndpoint}/records/${limitTable}`, JSON.stringify({
      id,
      limit_count: limitValue
    })).subscribe({
      error: (error: HttpErrorResponse) => {
        this.appError.throw(6261, { snackbar: true });
      }
    });
  }

  updateLimit(limitTable: string, id: string, limitValue: number): void {
    this.httpClient.put(`${this.apiEndpoint}/records/${limitTable}/${id}`, JSON.stringify({
      id,
      limit_count: limitValue
    })).subscribe({
      next: (rowsUpdated: any) => {
        // wenn Update nicht klappt (weil Limit-Eintrag noch nicht vorhanden), stattdessen CreateLimit probieren
        if (!rowsUpdated) {
          this.createLimit(limitTable, id, limitValue);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.appError.throw(6361, { snackbar: true });
      }
    });
  }

  deleteLimit(limitTable: string, id: string): void {
    this.httpClient.delete(`${this.apiEndpoint}/records/${limitTable}/${id}`).subscribe({
      next: (rowsUpdated: any) => {
        if (!rowsUpdated) {
          this.appError.throw(6462, { noSentryCapture: true });
        }
      },
      error: (error: HttpErrorResponse) => {
        this.appError.throw(6461, { snackbar: true });
      }
    });
  }

  limitCount(limitTable: string, id: string, offsetValue: number): void {
    // console.log('limitCount', limitTable, id, offsetValue);
    this.httpClient.get(`${this.apiEndpoint}/records/${limitTable}/${id}`).subscribe({
      next: (data: any) => {
        data.current_count = data.current_count + offsetValue;
        this.httpClient.put(`${this.apiEndpoint}/records/${limitTable}/${id}`, JSON.stringify(data)).subscribe({
          error: (error: HttpErrorResponse) => {
            this.appError.throw(6362, { snackbar: true });
          }
        });
      },
      error: (error: HttpErrorResponse) => {
        this.appError.throw(6162, { snackbar: true });
      }
    });
  }

}


export interface DsOptions {
  /* Optionen für die Funktionen read, create, update */
  anon?: boolean;
  guest?: boolean;
  silent?: boolean;
  noSentryCaptureOnError?: boolean;
  caption?: string;
  batchCounter?: number;    // Wenn gesetzt, ist der Aufruf Teil eines Batch-Befehls. 
  // Beim ersten Aufruf soll die Anzahl der Aufrufe im Batch übermittelt werden und bei allen weiteren Aufrufen -1.

  batchOp?: boolean;  // Derzeit nur für Update (BatchEdit) verwendet - mit Einschränkungen
  // für die Zukunft?  für create und update: handelt es sich um eine Batch Operation (multiple rows)
  // Notiz: Vielleicht sind Batch Operations nicht so sinnvoll, weil (von https://github.com/mevdschee/php-crud-api/):
  // "Batch operations use database transactions, so they either all succeed or all fail (successful ones get roled back)." 
  // Außer es wird aus Performance-Gründen absolut notwendig...
  isGuestform?: boolean; // nur für create & update
  makeTemplatesAndAutoOptions?: boolean;  // nur für read
}

export class BatchCounter {
  total = 0;
  current = 0;

  set(length: number) {
    /* Beim Start eines neuen Batch-Befehls ausführen! 
       length: wird aus DsOptions.batchCounter übernommen (beim ersten Aufruf)
    */
    // console.log('set BatchCounter', this.current, this.total, length);
    // Falls nötig, re-initialisieren:
    if (this.current <= 0) {  // current sollte eigentlich nie unter 0 sein, ist nur Vorsichtsmaßnahme
      this.current = 0;
      this.total = 0;
    }
    // Falls schon ein Batch-Befehl auf der gleichen Tabelle ausgeführt wird, wird der neue einfach hinzugefügt
    this.total += length;
    this.current += length;
  }

  countDown(): number {
    /* Return-Wert: current nach Aktualisierung
    */
    // console.log('countDown BatchCounter', this.current, this.total);
    this.current--;  // Counter herunterzählen
    return this.current;
  }
}

export class BatchCounterSet {
  create = new BatchCounter();
  update = new BatchCounter();
  delete = new BatchCounter();
}