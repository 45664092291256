import { Component, OnInit } from '@angular/core';

import { IAppConfig } from 'src/app/config/app-config.model';
import { AppConfig } from 'src/app/config/app.config';
import { AuthenticationService } from 'src/app/login/authentication.service';

@Component({
    selector: 'app-background-image',
    templateUrl: './background-image.component.html',
    styleUrls: ['./background-image.component.scss'],
    standalone: false
})
export class BackgroundImageComponent implements OnInit {
  picSrc: string;
  picLink: string;
  picCredit: string;
  picIsLoaded = false;
  appConfig: IAppConfig;

  constructor(
    private appConfigService: AppConfig,
    private auth: AuthenticationService
  ) {
    this.appConfig = this.appConfigService.getConfig().appConfig;
  }

  ngOnInit(): void {
    this.picLink = this.appConfig.bgPicsLink || 'https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral';
    this.picCredit = this.appConfig.bgPicsCredit || 'Pixabay';
    // Für eingeloggte User / Guests: Gespeichertes Hintergrundbild weiter verwenden (sollte bei Login passieren)
    this.picSrc = localStorage.getItem('backgroundPic') || '';
    if (this.picSrc && (this.auth.guestLoggedin || this.auth.userLoggedin)) this.picIsLoaded = true;
    else this.setRandomPic();       // zufälliges Hintergrundbild laden
  }


  private setRandomPic(): void {
    /* Wählt zufällig ein Bild aus der Liste aus und setzt dieses als Hintergrundbild (mittels LazyLoad)
    */

    const pics: string[] = this.appConfig.bgPics;
    const i = Math.floor(Math.random() * pics.length);
    console.log(`Background ${i}/${pics.length}: ${pics[i]}`);

    const downloadingImage = new Image();

    downloadingImage.onload = () => {   // Hintergrundbild erst nachdem es vollständig geladen ist einblenden
      if (document.readyState === 'complete') {
        this.doSetPic(downloadingImage.src)
      } else {
        window.onload = () => {
          this.doSetPic(downloadingImage.src)
        };
      }
    };
    downloadingImage.src = pics[i];     // gewähltes Hintergrundbild setzen (Laden beginnt)
  }

  private doSetPic(picSrc): void {
    this.picSrc = picSrc;
    this.picIsLoaded = true;
    // gewähltes Hintergrund speichern, kann von anderen Seiten auch verwendet werden
    localStorage.setItem('backgroundPic', picSrc);
  }
}

