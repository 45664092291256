/* FILTER MODELS
   -------------------------------
   Klassen:
   # Option - eine Option in einer Auswahlliste
   # Filter - ein Filter
   # KwiSort - Sortierreihenfolge für View bzw. Sortierung der Gruppen in FilterBar (nur je eine pro Komponente möglich)
   # Grouping - Gruppierung und Sortierung der Gruppen
   # SaveFilter, SaveSort - Vorbereitung für Speichern von Filter / Sort in storage

   Public Functions:
   # makeFilterStrings - aus allen Filtern einen String zusammensetzen, der an API-Schnittstelle übergeben werden kann
*/

import { SortDirection } from '@angular/material/sort';

import moment from 'moment';


export class Filter {
    id: string;             // ACHTUNG: es können mehrere Filter mit gleicher id vorkommen (wenn mehrere Filter einer einzigen Datenspalte zugeordnet sind)
    label: string;
    type: string;           // 'select', 'year' (Jahreszahlen), 'boolean', 'date_from', 'date_until', 'text', 'number'
    options?: Option[];     // Optionen in einer Auswahlliste (select, year)
    multiSelectRow = false;     // Für Filter von MultiSelect-Spalten. Muss unbedingt auch hier definiert werden.
    // diese property hieß früher nur "multiSelect". Wird im constructor übersetzt, für backward compatibility von configs 
    multiSelectFilter = false;  // MultiSelect im Filter aktivieren. 
    // ACHTUNG: funktioniert nur in Kombination mit filterMethod=onpage! Wenn true, wird daher automatisch filterMethod=onpage ausgewählt
    multiSelectFilterAny = true; // Gibt an, ob bei MultiSelect-Filtern die ausgewählten Optionen UND- (any: false) oder ODER-verknüpft (any: true) sind
    generateOptions = true;     // Optionen automatisch aus den vorhandenen Daten erstellen
    generateLabelsFromRow = ''; // Label für automatisch generierte Optionen aus dieser Spalte holen (für Templates)
    getLabelFromSelectField = false;  // oder: Label aus dem SelectField mit gleicher id holen (via getActiveOption)
    sortOptions = true;     // Optionen alphanumerisch (nach Label) sortieren
    selected = '';          // ausgewählter Wert
    prevSelected = '';      // wird nur für select benötigt, um Änderungen zu erkennen
    isPreset = false;       // Preselection (z.B. via query Param)
    hidden = false;         // true => nicht anzeigen (funktioniert derzeit nur in Kombi mit isPreset)
    useGuestUuidAsPresetValue = false;    // für Form: Guest UUID als presetValue verwenden
    valueForNoFilter? = ''; // welcher Wert übermittelt wird, wenn nichts ausgewählt ist (gilt nur für select)
    startvalueTodayWithOffset: number;     // Für 'date_from' und 'date_until': Vorauswahl als Offset in Tagen (+/-). undefined => keine Vorauswahl; 0 => heute;
    allowConversion = false; // wenn true wird auf den Variablentyp-Vergleich verzichtet. Bei type=boolean werden Werte in truthy und falsey verwandelt
    // ACHTUNG: funktioniert nur in Kombination mit filterMethod=onpage! Wenn true, wird daher automatisch filterMethod=onpage ausgewählt
    filterMethod? = 'api';  // 'api', 'onpage': Soll der Filter bei der API-Anfrage angewandt werden oder in der Komponente
    // 'onpage'-Filterung wird z.B. dann benötigt, wenn template-Felder, die aus anderen Tabellen geladen werden, als Filter verwendet werden.
    // ACHTUNG: 'onpage'-Filterung funktioniert (derzeit) nur in TableComponent

    constructor(init?: Partial<Filter>) {
        Object.assign(this, init);
        if (this['multiSelect']) {      // für Backward compatibility
            this.multiSelectRow = this['multiSelect'];
            delete this['multiSelect'];
        }
        if(this.multiSelectFilter || this.allowConversion) this.filterMethod = 'onpage';
        if (this.type === 'boolean') {  // Werte für type = boolean automatisch erzeugen
            this.options = [{
                label: 'Ja',
                value: '1',
                disabled: false,
            }, {
                label: 'Nein',
                value: '0',
                disabled: false,
            }];
        }
        if (this.type.startsWith('date') && (this.startvalueTodayWithOffset === 0 || this.startvalueTodayWithOffset)) {
            this.selected = moment().add(this.startvalueTodayWithOffset, 'days').format('YYYY-MM-DD');
        }
    }

    doOnpageFiltering(data: any): any {
        if (this.selected !== this.valueForNoFilter) {
            let filterValue: any = this.selected;
            if (this.type === 'boolean') {
                filterValue = this.selected === '1'; // Wenn Filter Boolean, value von 0/1 in boolean umwandeln
            }
            // Convert dates:
            if (this.type.startsWith('date')) {
                filterValue = moment(filterValue);
            }
            // FILTERN beginnt hier:
            // Select inkl. MultiSelect:
            if (this.type === 'select') {
                if (this.multiSelectFilter && Array.isArray(filterValue)) {
                    if(this.multiSelectFilterAny) { // ODER-Verknüpfung der ausgewählten Filteroptionen
                        data = data.filter((row) => filterValue.some((fv) => row[this.id]?.includes(fv)));
                    } else {                        // UND-Verknüpfung der ausgewählten Filteroptionen
                        data = data.filter((row) => filterValue.every((fv) => row[this.id]?.includes(fv)));
                    }
                } else {
                    data = data.filter((row) => Array.isArray(row[this.id]) ? row[this.id].includes(filterValue) : row[this.id] === filterValue);
                }
            }
            // Haystack startet mit Jahr für type 'year':
            else if (this.type === 'year') data = data.filter((row) => row[this.id]?.toString().startsWith(filterValue));
            // Datum von / Datum bis:
            else if (this.type === 'date_from') data = data.filter((row) => moment(row[this.id]).isSameOrAfter(filterValue, 'day'));
            else if (this.type === 'date_until') data = data.filter((row) => moment(row[this.id]).isSameOrBefore(filterValue, 'day'));
            else if (this.type === 'date') data = data.filter((row) => moment(row[this.id]).isSame(filterValue, 'day'));
            else if (this.allowConversion) {
                if (this.type === 'boolean') data = data.filter((row) => !!row[this.id] === filterValue);
                else data = data.filter((row) => row[this.id] == filterValue);
            }
            else data = data.filter((row) => row[this.id] === filterValue);
        }
        return data;
    }
    mergeWithSavedFilter(saveFilter: SaveFilter) {
        /* Daten aus gespeichertem Filter in Filter-Objekt kopieren (nur 'selected') */
        if (saveFilter && saveFilter.selected !== null) {
            this.selected = saveFilter.selected;
        }
    }

    makeOptionsFromData(data: any[], colId: string, labelFunction?: (val: any) => string): void {
        /* options aus übergebenen Daten erstellen
           - colId ist die id der Datenspalte, die verwendet werden soll
           - grundsätzlich wird dieser Wert für value UND label der Option verwendet,
             außer es ist im Filter eine Spalte für die Labels definiert via generateLabelsFromRow
           - oder es kann eine labelFunction (templateFunction) mit übergeben werden, dann wird damit das Label gebaut
             (wird derzeit für getLabelFromSelectField verwendet, um tableModel hier nicht zu verwenden und Circular Dependencies zu vermeiden)
           - doppelte Werte werden dabei ignoriert
           - zuletzt wird das Resultat sortiert
           - für type = 'year' wird aus dem übergebenen Wert (muss string im Format yyyy-mm-dd sein) die Jahreszahl erzeugt
        */
        this.options = [];    // etwaige frühere options werden überschrieben
        if (data?.length) {   // wenn Daten vorhanden
            for (const row of data) {
                let rawValue = row[colId];
                if (this.type === 'year' && typeof rawValue === 'string') rawValue = rawValue.substring(0, 4);   // Jahreszahlen erzeugen
                if (rawValue === undefined || rawValue === null || rawValue === '') continue;
                // Wenn die vorgefundene rawValue ein Array ist (aus MultiSelect), alle einzelnen Values als Options verwenden:
                let valueList: any[];
                if (this.multiSelectRow && Array.isArray(rawValue)) valueList = rawValue;
                else valueList = [rawValue];
                for (const value of valueList) {
                    // wenn option noch nicht vorhanden und value ein gültiger Wert:
                    if (!this.options.some(o => o.value === value)) {
                        const option = new Option();
                        option.value = value;
                        // Labels aus anderer Spalte holen (für Templates):
                        if (this.generateLabelsFromRow !== '' && row[this.generateLabelsFromRow]) {
                            option.label = row[this.generateLabelsFromRow];
                            // Labels aus labelFunction holen:
                        } else if (labelFunction !== null && typeof labelFunction === 'function') {
                            option.label = labelFunction(value);
                        } else {  // ansonsten ist label = value
                            option.label = value;
                        }
                        this.options.push(option);
                    }
                }
            }
            // alphabetisch (bzw. alphanumerisch / nach ASCII) aufsteigend sortieren:
            if (this.sortOptions) {
                this.options.sort((a, b) => (a.label.toString().toLowerCase() > b.label.toString().toLowerCase()) ? 1 : -1);
            }
        }
        else {  // wenn keine Daten vorhanden, weil z.B. nichts gefunden wurde, aber ein Preset vorhanden ist und dieser ein Label benötigt
            if (this.isPreset && this.getLabelFromSelectField && this.selected && labelFunction !== null && typeof labelFunction === 'function') {
                this.options = [{ value: this.selected, label: labelFunction(this.selected) }];
            }
        }
        // console.log('Generated options for', colId, this.options);
    }
}


export class KwiSort {
    options: Option[];      // mögliche Sortierkriterien
    selected = '';          // ausgewähltes Sortierkriterium
    dir: SortDirection;     // Sortierrichtung (aufwärts / abwärts): 'asc' | 'desc' | ''
    disabled? = false;       // derzeit nicht in Verwendung

    public constructor(init?: Partial<KwiSort>) {
        Object.assign(this, init);
    }

    mergeWithSavedSort(saveSort: SaveSort) {
        /* Daten aus gespeichertem Sort in Sort-Objekt kopieren (nur 'selected' und 'dir') */
        if (saveSort.selected !== '') {
            this.selected = saveSort.selected;
            this.dir = saveSort.dir;
        }
    }
}


export class Grouping {
    active = false;             // Gruppierfunktion ist grundsätzlich aktiviert oder nicht
    options: GroupByOption[];   // mögliche Gruppierkriterien
    selected = '';              // ausgewähltes Gruppierkriterium (colId = groupByOption.value)
    disabled? = false;           // keine Änderung durch Benutzer möglich
    groupsPageSize = 5;         // Wie viele Tabellengruppen werden auf einmal geladen?
    public constructor(init?: Partial<Grouping>) {
        Object.assign(this, init);
        if (this.options?.length) this.options = this.options.map((o) => new GroupByOption(o));
    }
}

export class GroupByOption {
    // Jedes Gruppierkriterium hat individuelle Möglichkeiten zur Sortierung der jeweiligen Gruppen
    label: string;
    value: string;       // Id der Spalte, nach der die Tabelle gruppiert werden soll
    titleTemplate: '';       // Template für die Anzeige des Gruppentitels
    subtitleTemplate: '';    // Template für den Untertitel (neben Gruppentitel angezeigt)
    groupSort: KwiSort;  // Nach welchen Kriterien werden die Gruppen gereiht und angezeigt:
    // - das kann entweder nur die Spalte sein, nach der gruppiert wird, um die Sortierrichtung zu ändern
    // - oder es können weitere TemplateFields aus einem HelperTable sein, nach dem die Gruppierung stattfindet
    showGroupSort? = false; // KwiSort wird in FilterBar angezeigt und kann geändert werden, um Gruppensortierung zu ändern
    // Wenn false, sollte groupSort (selected und dir) trotzdem angegeben werden, groupSort.options kann aber [] sein.
    hideGroupByColInTables? = false;
    public constructor(init?: Partial<GroupByOption>) {
        Object.assign(this, init);
        this.groupSort = new KwiSort(init.groupSort);
    }
}


export class SaveFilter {
    /* abgespeichert werden nur die veränderlichen Daten: selected (der ausgewählte Wert) */
    id: string;
    selected = '';
    makeFromFilter(filter: Filter) {
        this.id = filter.type + '__' + filter.id;
        this.selected = filter.selected;
    }
}

export class SaveSort {
    /* abgespeichert werden nur die veränderlichen Daten: selected (der ausgewählte Wert) und dir */
    selected = '';
    dir: SortDirection;
    makeFromSort(sort: KwiSort): void {
        this.selected = sort.selected || '';
        this.dir = sort.dir || '';
    }
}


export function makeFilterStrings(filters: Filter[]): string[] {
    /* aus allen Filtern einen String zusammensetzen, der an API-Schnittstelle übergeben werden kann
       siehe auch https://github.com/mevdschee/php-crud-api/#filters
       (ist keine Methode von Filter, weil sie das Array aller Filter als Parameter nimmt)
       - vorbereitet für types select, boolean, number, year, string
       - falls type date als Filter verwendet wird, müsste noch Logik eingefügt werden
         (und überlegt werden, ob hier genau verglichen werden soll oder mit "startet mit", um auch auf datetime einzugehen)
     */
    const filterStrings = [];
    // nur für aktive Filter mit der filterMethod api (für onpage-Filterung braucht es keinen FilterString):
    for (const filter of filters.filter(a => (a.selected !== (a.valueForNoFilter || '') && a.filterMethod === 'api'))) {
        // nochmalige Abfrage, falls valueForNoFilter != ''.
        // Das würde hier aber trotzdem keinen Sinn machen, weil die API nicht auf leere Werte reagiert.
        if (filter.selected === '') continue;
        // Convert dates:
        if (filter.type.startsWith('date')) {
            // format date to yyyy-mm-dd
            filter.selected = moment(filter.selected).format('YYYY-MM-DD');
        }
        // Genaue Übereinstimmung für Select, Boolean und number:
        if (filter.type === 'select' || filter.type === 'boolean' || filter.type === 'number') {
            filterStrings.push(`${filter.id},${filter.multiSelectRow ? 'cs' : 'eq'},${filter.selected}`);
        }
        // Haystack startet mit Jahr für type 'year':
        else if (filter.type === 'year') filterStrings.push(filter.id + ',sw,' + filter.selected);
        // Datum von / Datum bis:
        else if (filter.type === 'date_from') filterStrings.push(filter.id + ',ge,' + filter.selected);
        else if (filter.type === 'date_until') filterStrings.push(filter.id + ',le,' + filter.selected);
        // Sonst: Haystack enthält Value:
        else filterStrings.push(filter.id + ',cs,' + filter.selected);
    }
    if (filterStrings.length === 0) return [];
    else return filterStrings;
}


export class Option {
    /* eine Option in einer Auswahlliste (select, year) */
    label: string;
    value: string;
    disabled? = false;  // für Limits in Verwendung
}