import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IAppConfig } from 'src/app/config/app-config.model';
import { AppConfig } from 'src/app/config/app.config';

@Component({
    selector: 'app-idle-timeout-sheet',
    templateUrl: './idle-timeout-sheet.component.html',
    standalone: false
})
export class IdleTimeoutSheetComponent implements OnInit {
  countdown = 30;
  intervalRef;
  appConfig: IAppConfig;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<IdleTimeoutSheetComponent>,
    private appConfigService: AppConfig,
  ) {
    this.appConfig = this.appConfigService.getConfig().appConfig;
   }

  ngOnInit(): void {
    this.intervalRef = setInterval(() => {
      this.countdown--;
      if(this.countdown === 0) {
        this._bottomSheetRef.dismiss(false);
      }
    }, 1000); 
  }

  abort(): void {
    clearInterval(this.intervalRef);
    this._bottomSheetRef.dismiss(true);
  }

}
