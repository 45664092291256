import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LockService {

  constructor() { }
}


interface Lock {
  id: number;           // Lock-id in der Datenbank
  table: string;
  row: string;
  singleCol?: string;
  component: string;    // welche Component hat diese Sperre gesetzt (z.B. table, form, view)
  compId: string;       // Id der Component (Table.mainTable, Form.id, View.id)
}