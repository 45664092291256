/* AppConfig aus app-config.model (Struktur) und Werten aus Datenbank erstellen
 * HIER IST NICHTS ZUM EINSTELLEN!
 *
 * Seit v3.3: die Config wird zusammengesetzt aus:
 *  1) der allgemeinen Config (id 1), 
 *  2) der Custom- oder Abo-Config (id 2) und 
 *  3a) der jeweiligen UserGroup-Config (id >= 10) oder
 *  3b) der User-Config, die direkt in der Tabelle kwi_users > user_config steht.
 * 
 * UserGroup-Config wird beim Login geladen
 * 
 * In der User-Config oder UserGroup-Config darf nur eingestellt werden, was in allowUserConfig freigeschaltet wurde.
 * Einstellungen in User-/UserGroup-Config überschreiben andere, Einstellungen in Custom-Config überschreiben allgemeine Config.

   Erste Version zu großen Teilen von https://github.com/rfreedman/angular-configuration-service/issues/1 übernommen
   ("avoid using HttpClient in APP_INITIALIZER #1")
*/

import { Injectable } from '@angular/core';
import { of, Subject } from 'rxjs'

import * as AES from 'crypto-js/aes';
import * as Utf8 from 'crypto-js/enc-utf8';

import { environment } from '../../environments/environment';
import { AllowUserConfig, GlobalConfig } from './app-config.model';
import { AppError, ErrorService } from '../shared/error.service';

export const aesSecretKey = {
    kwimo: 'kwickie{~~z"(@#8UF`-bQuo;P!t<cT<(+iD7',
    sport: 'ptf9][tK^Lpks=y{xa}x6L/DWWK2o7_e2',
    ptf: 'ptf9][tK^Lpks=y{xa}x6L/DWWK2o7_e2',
};

@Injectable()
export class AppConfig {
    static standardConfig = new GlobalConfig();     // mit Custom/Abo-Anpassungen, aber vor User-Anpassungen
    private currentConfig = new GlobalConfig();     // nach User-Anpassungen
    private loaded = false;
    public configChanges$: Subject<void>;   // Benachrichtigt Subscriber bei Änderungen in (current)Config

    constructor(
        private appError: ErrorService,
    ) {
        this.configChanges$ = new Subject();
    }

    public getConfig(userConfig?: GlobalConfig | ''): GlobalConfig {
        if (!this.loaded) this.appError.throw(1100);
        if (userConfig || userConfig === '') this.makeUserConfig(userConfig);       // userConfig = '' wird beim Logout verwendet
        return this.currentConfig;
    }

    // the return value (Promise) of this method is used as an APP_INITIALIZER,
    // so the application's initialization will not complete until the Promise resolves.
    public load(forceReload?: boolean): Promise<any> {
        /* Config-Daten aus Datenbank lesen: */

        if (this.loaded && !forceReload) {
            return of(this, this.currentConfig).toPromise();
        } else {
            return new Promise((resolve, reject) => {
                this.getConfigFromApi(1).then((config1: GlobalConfig) => {      // StandardConfig laden

                    AppConfig.standardConfig = config1;

                    // Variant-Variablen manuell aus environment auslesen, damit sie überall einfach zur Verfügung stehen.
                    AppConfig.standardConfig.appConfig.variant = environment.variant;
                    AppConfig.standardConfig.appConfig.variantName = environment.variantName;
                    AppConfig.standardConfig.appConfig.infoSite = environment.infoSite;

                    this.getConfigFromApi(2).then((config2: GlobalConfig) => {  // Custom/Abo-Config laden
                        mergeDeep(AppConfig.standardConfig, config2);
                        this.makeUserConfig('');
                        this.loaded = true;
                        resolve(this.currentConfig);
                    }, () => {
                        this.appError.throw(1102, { noSentryCapture: true });  // Keine Custom/Abo-Config vorhanden
                        this.makeUserConfig('');
                        this.loaded = true;
                        resolve(this.currentConfig);
                    });
                }, (error: AppError | null) => {
                    if (error) reject();     // Fehlermeldung schon passiert
                    else reject(this.appError.throw(1101, { snackbar: true }).msg);
                });
            });
        }
    }


    public getConfigFromApi(id: number): Promise<GlobalConfig> {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `${environment.apiEndpoint}/records/kwi_config/${id}`);
            xhr.addEventListener('readystatechange', () => {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        // console.log('getConfigFromApi', id);
                        let rawData;
                        try {
                            rawData = JSON.parse(xhr.responseText);
                        } catch {
                            this.appError.throw(1103, { snackbar: true });
                        }
                        if (rawData) {
                            const decryptedConfig = this.decryptConfig(rawData);
                            if (decryptedConfig) resolve(decryptedConfig);
                            else reject();
                        }
                        else reject();
                    } else {
                        reject();
                    }
                }
            });
            xhr.send(null);
        });
    }

    public decryptConfig(rawData: any): GlobalConfig | false {
        // console.log('rawData', rawData)
        const returnConfig = new GlobalConfig();
        try {
            // Einzelne Spalten aus DB einlesen und entschlüsseln:
            for (const [key, value] of Object.entries(rawData)) {
                if (key === 'id') continue;
                const bytes = AES.decrypt(value.toString(), aesSecretKey[environment.variant]);
                const jsonDataRow = bytes.toString(Utf8);
                const configData = JSON.parse(jsonDataRow);
                // console.log('Config decrypted:', key, configData);
                returnConfig[key] = configData;
            }
            return returnConfig;
        } catch {
            this.appError.throw(1104, { snackbar: true }).msg;
            return false;
        }
    }

    private makeUserConfig(userConfig: GlobalConfig | ''): void {
        /* Erzeugt in this.currentConfig die Kombination aus allgemeiner Config und UserConfig 
           wenn übergebene userConfig = "", dann wird die allgemeine Config in this.currentConfig gespeichert (z.B. bei Logout)
           Ansonsten muss als userConfig die entprechenden ConfigDaten übergeben werden

           Hinweis: es genügt, wenn makeUserConfig nur von der AppComponent aufgerufen wird - trotzdem sollte this.currentConfig überall korrekt zur Verfügung stehen   
        */

        // Zuerst alles auf standard zurücksetzen:
        this.currentConfig.appConfig = Object.assign({}, AppConfig.standardConfig.appConfig);
        this.currentConfig.tableConfig = Object.assign({}, AppConfig.standardConfig.tableConfig);
        this.currentConfig.formConfig = Object.assign({}, AppConfig.standardConfig.formConfig);
        this.currentConfig.viewConfig = Object.assign({}, AppConfig.standardConfig.viewConfig);

        if (userConfig === '') {
            this.configChanges$.next();
            return;
        }

        // UserGroup- oder User-Config auslesen:
        else {
            if (!this.currentConfig.appConfig.allowUserConfig) this.currentConfig.appConfig.allowUserConfig = new AllowUserConfig();

            // AppConfig:
            for (const [key, isAllowed] of Object.entries(this.currentConfig.appConfig.allowUserConfig.appConfig)) {
                if (key === 'allowUserConfig') continue;     // allowUserConfig kann natürlich nicht abgeändert werden
                // console.log(12, key, isAllowed, userConfig.appConfig[key]);
                if (userConfig.appConfig.hasOwnProperty(key)) {
                    if (isAllowed) {
                        // Objekte (ohne Arrays): generalSettings mit userConfig kombinieren
                        if (isObject(userConfig.appConfig[key])) {
                            this.currentConfig.appConfig[key] = Object.assign({}, AppConfig.standardConfig.appConfig[key]);
                            this.currentConfig.appConfig[key] = Object.assign(this.currentConfig.appConfig[key], userConfig.appConfig[key]);
                        }
                        // Arrays und einfache Werte: userConfig übernehmen:
                        else this.currentConfig.appConfig[key] = userConfig.appConfig[key];
                    } else {
                        console.log('Einstellung darf nicht angepasst werden, key', key);
                    }
                }
            }
            // TableConfig (es kann jeweils nur die gesamte Config pro Tabelle übernommen werden):
            if (this.currentConfig.appConfig.allowUserConfig.tableConfig && userConfig.tableConfig) {
                for (const [table, isAllowed] of Object.entries(this.currentConfig.appConfig.allowUserConfig.tableConfig)) {
                    if (userConfig.tableConfig[table]) {
                        if (isAllowed) this.currentConfig.tableConfig[table] = userConfig.tableConfig[table];
                        else console.log('Einstellung darf nicht angepasst werden, table', table);
                    }
                }
            }
            // FormConfig (es kann jeweils nur die gesamte Config pro Tabelle übernommen werden):
            if (this.currentConfig.appConfig.allowUserConfig.formConfig && userConfig.formConfig) {
                for (const [id, isAllowed] of Object.entries(this.currentConfig.appConfig.allowUserConfig.formConfig)) {
                    if (userConfig.formConfig[id]) {
                        if (isAllowed) this.currentConfig.tableConfig[id] = userConfig.formConfig[id];
                        else console.log('Einstellung darf nicht angepasst werden, form', id);
                    }
                }
            }
            if (this.currentConfig.appConfig.allowUserConfig.viewConfig && userConfig.viewConfig) {
                // ViewConfig (es kann jeweils nur die gesamte Config pro Tabelle übernommen werden):
                for (const [id, isAllowed] of Object.entries(this.currentConfig.appConfig.allowUserConfig.viewConfig)) {
                    if (userConfig.viewConfig[id]) {
                        if (isAllowed) this.currentConfig.tableConfig[id] = userConfig.viewConfig[id];
                        else console.log('Einstellung darf nicht angepasst werden, view', id);
                    }
                }
            }
            this.configChanges$.next();
        }
    }
}

/* isObject und mergeDeep übernommen und angepasst von https://thewebdev.info/2021/03/06/how-to-deep-merge-javascript-objects/ */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, source) {
    /* mergeDeep wird für die CustomConfig verwendet (nicht für UserConfig)
       target wird in place mit Werten von source angepasst / überschrieben
       Dabei kann jedes Objekt in source eine property "__replaceEntireObject": true haben. Wenn dies zutrifft, wird nicht gemerged, sondern dieses ganze Objekt ersetzt. 
    */


    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key]) && !source[key].__replaceEntireObject === true) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
                if (source[key].__replaceEntireObject) {
                    delete target[key].__replaceEntireObject;   // Indikator im Zielobjekt entfernen
                }
            }
        }
    }
}