/* FILEMANAGER-SERVICE
   -------------------------------
   - Verbindung zu Upload/File - Schnittstelle
   - Management von File Orphans / Widows (Erklärung siehe unten)
   * FileUpload nur für angemeldete User möglich!

   öffentliche Methoden:
   # postFile: Sendet File an Server, um dort gespeichert zu werden, inkl. resampling für Bilder
   # deleteFile: Sendet Auftrag an Upload API, diese(s) File(s) zu löschen (falls die Datei nicht mehr gebraucht wird)
   # deleteOrphanedFiles: Cleanup der Dateien am Server beim Verlassen einer Seite
   # deleteWidowedFiles: Die betreffenden widows (vorige Bilder, die ersetzt werden) vom Server löschen.
   # removeOrphans: Gespeicherte Dateien von der Orphans-Liste löschen
   # convertToFileArray: macht aus einfachen strings und Image-Objekten ein Array aus filenames

*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Tables } from '../table/table.model';
import { AppConfig } from '../config/app.config';
import { AuthenticationService } from '../login/authentication.service';
import { ErrorService } from './error.service';


@Injectable({
    providedIn: 'root'
})
export class FilemanagerService {
    private httpOptions;
    private fmConfig;

    private uploadEndpoint = environment.uploadEndpoint;
    // Orphans: Files, die zwar schon hochgeladen, aber dann nicht in einen DB-Eintrag gespeichert wurden [ { table, files } ]
    private orphanedUploads: any[] = [];
    // Widows: Files, die durch neuere Files ersetzt wurden oder gelöscht werden sollen. 
    public widowedFiles: FileEntry[] = [];

    constructor(
        private httpClient: HttpClient,
        private auth: AuthenticationService,
        private tablesModel: Tables,
        private appError: ErrorService,
        private appConfigService: AppConfig,
    ) {
        this.fmConfig = appConfigService.getConfig().appConfig.filemanager;
        this.httpOptions = {};
        // TODO: schauen, ob wir das Auslesen von apiSessionId via JavaScript ersetzen können und das Session Cookie direkt wieder zurück schicken mit:
        // if (environment.production) this.httpOptions = { withCredentials: true }
    }

    public postFile(fileToUpload: File, table: string, rowId: string, colId: string, uploadType: string, oldFiles?: string): Observable<any> {
        /* Sendet File an Server, um dort gespeichert zu werden, inkl. resampling für Bilder
           - uploadType = "image" / "video" / "file". Für "image" wird Bildbearbeitung / resampling gemacht
           Bei Erfolg:
           - speichert die alte Datei / den Satz alter Dateien in widowedFiles => Dateien löschen bei Speicherung des Eintrags
           - speichert die neue Datei / neue Dateien in orphanedUploads => Dateien löschen, wenn Eintrag nicht gespeichert wird
        */
        return new Observable((observer) => {
            if (!this.auth.checkAuth(true, false, observer)) return;    // observer.error wird in dem Fall von appError zurückgegeben
            else if (!this.auth.apiSessionId) this.appError.throw(6912, { observer });
            else {
                const formData: FormData = new FormData();
                formData.append('Filedata', fileToUpload, fileToUpload.name);
                formData.append('apiSessionId', this.auth.apiSessionId);
                formData.append('username', this.auth.user.name);
                formData.append('uploadType', uploadType);
                if (uploadType === 'image') {
                    formData.append('w_dst', this.fmConfig.images.maxWidth.toString());
                    formData.append('h_dst', this.fmConfig.images.maxHeight.toString());
                    formData.append('w_thumb', this.fmConfig.images.thumbWidth.toString());
                    formData.append('h_thumb', this.fmConfig.images.thumbHeight.toString());
                    formData.append('quality', this.fmConfig.images.jpegQuality.toString());
                }
                this.httpClient.post(this.uploadEndpoint, formData, this.httpOptions).subscribe({
                    next: (uploadData) => {
                        if (uploadData['savedFiles']) {
                            this.orphanedUploads.push({ table, files: uploadData['savedFiles'] });
                            if (oldFiles) this.widowedFiles.push({ table, rowId, colId, files: this.maybeMakeObjectFromString(oldFiles) });
                        }
                        observer.next(uploadData);
                        observer.complete();
                    }, error: (error) => {
                        this.appError.throw(
                            error.status ? (error.status < 1000 ? error.status + 8000 : error.status) : 6919,
                            { observer }
                        );
                    }
                });

            }
        });
    }

    public deleteFiles(files: string | any, fileArray?: string[]): Observable<any> {
        /* Sendet Auftrag an Upload API, diese(s) File(s) zu löschen
           für mögliche Formate von files, siehe convertToFileArray
           - Alternativ zu files kann auch ein fertiges fileArray übergeben werden. files muss dann null sein
        */
        console.log('deleteFiles:', files, fileArray);
        return new Observable((observer) => {
            if (files !== null) fileArray = this.convertToFileArray(files);
            if (!this.auth.checkAuth(true, false, observer)) return;    // observer.error wird in dem Fall von appError zurückgegeben
            else if (!this.auth.apiSessionId) this.appError.throw(6912, { observer });
            else if (!fileArray?.length) this.appError.throw(6922, { observer });
            else {
                const formData: FormData = new FormData();
                let hasFilesToDelete = false;
                formData.append('apiSessionId', this.auth.apiSessionId);
                formData.append('username', this.auth.user.name);
                formData.append('uploadPath', this.fmConfig.uploadPath);
                for (const filename of fileArray) {
                    // Datei nur löschen, wenn es keine absolute URL ist:
                    if (!filename.startsWith('https://')) {
                        hasFilesToDelete = true;
                        formData.append('oldFiles[]', filename);
                    }
                }
                if (hasFilesToDelete) {
                    this.httpClient.post(this.uploadEndpoint, formData, this.httpOptions).subscribe({
                        next: (uploadData: any) => {
                            if (uploadData.deletedFiles) console.log('deleted file(s) successfully:', uploadData.deletedFiles);
                            observer.next(uploadData);
                            observer.complete();
                        },
                        error: (error) => {
                            this.appError.throw(
                                error.status ? (error.status < 1000 ? error.status + 8000 : error.status) : 6929,
                                { observer }
                            );
                        }
                    });
                }
                else {
                    observer.next({
                        deleted: false,
                        errors: [{ code: 9280, msg: 'Datei wurde nicht gelöscht, da sie sich nicht auf diesem Server befindet.' }]  // Nur Info
                    });
                    observer.complete();
                }
            }

        });
    }

    public deleteOrphanedFiles(table): void {
        /* Cleanup beim Verlassen der Seite
         */
        // Alle orphaned Files, die zu dieser Tabelle gehören, löschen
        const orphansToDelete = this.orphanedUploads.filter((o) => (o.table === table));
        if (orphansToDelete?.length) {
            this.deleteFiles(null, this.makeArrayFromFileObjects(orphansToDelete)).subscribe();
            this.orphanedUploads = this.orphanedUploads.filter((o) => (o.table !== table));
        }
        // Alle Einträge dieser Tabelle aus Widow list löschen
        // (diese dürfen nur vom Server gelöscht werden, wenn der Update tatsächlich durchgeführt wird)
        this.widowedFiles = this.widowedFiles.filter((w) => w.table !== table);
    }

    makeArrayFromFileObjects(filelist): string[] {
        /* filelist ist ein Array of objects. Jedes Objekt muss jedenfalls eine files - property haben 
           (für mögliche Formate von files, siehe convertToFileArray)
        */
        const fileArray = [];
        for (const obj of filelist) {
            fileArray.push(...this.convertToFileArray(obj.files));
        }
        return fileArray;
    }

    public convertToFileArray(files: string | any): string[] {
        /* macht aus einfachen strings und Image-Objekten ein Array aus filenames
           - versucht zunächst, strings als JSON-Daten zu interpretieren und diese in Objekte umzuwandeln
           - ansonsten wird angenommen, dass die strings direkt der Dateiname ist
           - bei Objekten (und JSON-Daten) werden einfach alle values übernommen.
             (derzeit besteht das nur aus: filename und thumbFilname. Sollte das erweitert werden, muss das hier angepasst werden.)
        */
        const fileArray = [];
        if (typeof files === 'string') {
            files = this.maybeMakeObjectFromString(files);
        }
        if (typeof files === 'object' && files !== null) {
            for (const filename of Object.values(files)) {
                fileArray.push(filename as string);
            }
        } else if (typeof files === 'string') {
            fileArray.push(files);
        }
        return fileArray;
    }

    maybeMakeObjectFromString(string: string): string | any {
        /* String kann entweder ein JSON-String sein, dann wird ein Objekt draus gemacht
           oder es ist ein "normaler" String, dann wird er einfach so zurückgegeben. */
        try {
            const temp = JSON.parse(string);
            string = temp;
        } catch { // ev. TODO
        }
        return string;
    }

    public removeOrphans(data: any, table: string): void {
        /* wird bei Speicherung des Tabellen-Eintrags aufgerufen.
           Gespeicherte Daten durchgehen (nur die Cols mit Files).
           Betreffende Files aus den orphanedUploads löschen, da diese ja nun behalten werden sollen.
         */
        for (const colId of this.tablesModel.tables[table].getColumnsByFilter((col) => col.hasFiles(data) === true)) {
            this.orphanedUploads = this.orphanedUploads.filter((o) => {
                // Etwas komplizierterer Vergleich, wegen unterschiedlicher Speicherformate für Bilder (mit thumbs) und Videos
                return !(JSON.stringify(o.files) === data[colId] || o.files.filename === data[colId]);
            });
        }
    }

    public getWidowedFiles(table: string, rowId: string): FileEntry[] {
        /* Beim Speichern des Eintrags müssen die betreffenden widows vom Server gelöscht werden.
           Sie werden jetzt ja nicht mehr gebraucht, da durch neue Datei ersetzt.
           Die Funktion gibt alle widowedFiles eines bestimmten Eintrags zurück. Hier werden sie aus der Liste der widowedFiles gelöscht. 
           In DataService wird dann entschieden, ob die Dateien gelöscht werden sollen, weil sie nicht mehr gebraucht werden, oder nicht.
        */
        console.log('get widowedFiles table, rowId', table, rowId);
        console.log('all widowedFiles:', this.widowedFiles);
        const deleteTheseWidows = this.widowedFiles.filter((w) => (w.rowId === rowId && w.table === table))
        this.widowedFiles = this.widowedFiles.filter((w) => (w.rowId !== rowId || w.table !== table));
        return deleteTheseWidows;
    }

    public cleanupFiles(): void {
        if (this.orphanedUploads?.length) {
            this.deleteFiles(null, this.makeArrayFromFileObjects(this.orphanedUploads)).subscribe();
        }
        this.orphanedUploads = [];
        this.widowedFiles = [];
    }


}

export interface FileEntry {
    table: string;
    rowId: string;
    colId: string;
    files: string | any;
}
