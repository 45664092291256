/* TABLE MODELS (allgemein)
   Models für Tabellen / Ansichten
   -------------------------------

   Klassen:
   # Field (enthält Properties aller Felder) und die speziellen Felder
   # StringField, DateField, NumberField, BooleanField, TemplateField, ImageField, VideoField, DocField
   # Model: allgemeine Definitionen für Tabellen und Ansichten
   # Link

*/

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MatSortable } from '@angular/material/sort';
import { Subject } from 'rxjs';

import moment from 'moment';
import { v1 as uuidv1 } from 'uuid';

import { Filter, Grouping, Option } from '../shared/filter/filter';
import { ReadArgs } from '../shared/readargs';
import { AppConfig } from '../config/app.config';
import { HooksService } from '../config/hooks.service';
import { AuthenticationService } from '../login/authentication.service';
import { AppError, ErrorService } from '../shared/error.service';
import { ClipboardService } from '../shared/clipboard/clipboard.service';
import { SnackbarService } from '../shared/snackbar.service';
import { LoadingService } from '../shared/loading.service';
import { IAppConfig } from '../config/app-config.model';



/****************************/
/* DATENFELDER (= SPALTEN): */
/****************************/

export class Field {
  /* Properties aller Felder */
  id: string;
  type: string;
  caption = '';       // Beschriftung
  format = '';        // Formatierung mittels (hauseigenen) template strings '$val sec', nicht zu verwechseln mit type = template
  required = true;    // Pflichtfeld (entspricht NULL = false in Datenbank)
  system = false;     // wird nicht angezeigt, für interne Zwecke
  validation = '';    // Form-Validation
  maxSize = 0;        // maximale Länge, 0 = no max size
  tooltip = '';       // tooltip-Text für Tabellenansicht (mit MatTooltip)
  alwaysShowTooltip = false;  // Tooltip auch in Dialogen, Single-Ansicht, in Forms und Views anzeigen
  list = true;        // wird in Tabellenansicht angezeigt (wenn false, dann nur in Detailansicht zu sehen)
  editable = true;    // kann vom Benutzer bearbeitet werden
  updatable = true;   // nach dem erstmaligen Speichern veränderbar
  inlineEdit = false; // kann in Tabellenansicht bearbeitet werden
  hint = '';          // Hinweis (mat-hint) unterhalb von Eingabefeld im Add-Edit-Dialog bzw. als Text im File-Dialog
  batchEdit = false;  // Dieses Feld kann per Batch-Edit bearbeitet werden. (mehrere Zeilen auf einmal). Geht nur für types: string, number/euro, boolean, select (incl. multi-select)
  showSum = false;    // Summe aller Zeilen bilden und in Footer-Zeile der Tabelle anzeigen
  // GUESTFORM:
  guestformShow = true; // Feld im Anmeldeformular sichtbar
  guestformEdit = true; // Feld im Anmeldeformular bearbeitbar (im mainTable)
  guestformRequired = false;  // nur in Formular als Pflichtfeld, nicht in tableModule
  guestformHint = '';   // Hint (mat-hint) unterhalb vom Eingabefeld
  guestformHintMultiline = false; // guestformHint kann mehrere Zeilen lang werden
  guestformTitle = '';  // Titel (h3) vor dem Feld
  guestformIntro = '';  // Text vor dem Feld
  guestformOutro = '';  // Text nach dem Feld
  guestformUpdate = false; // Feld in Guest-Step von Anmeldeformular bearbeitbar
  // OnlyShowIf: 
  // -- nicht gemeinsam mit required = true verwenden!
  guestformOnlyShowIfField = '';  // Anderes Feld in der Tabelle, von dem die Anzeige dieses Felds abhängt
  guestformOnlyShowIfCondition = '';  // Feld nur anzeigen, wenn guestformOnlyShowIfField == guestformOnlyShowIfCondition (kein Typvergleich).
  // Standardmäßig '' => Feld wird angezeigt, wenn guestformOnlyShowIfCondition truthy ist
  guestformShowConditional = true;  // wird programmatisch gesetzt, abhängig von den Werten in guestformShowOnly... und den aktuellen Daten.
  guestformImportFromTable = ''; // Wert beim Speichern aus Hilfstabelle importieren.
  // Die Form braucht dazu einen MainStep oder UserStep mit einer (aktiven) Spalte [helperTable]_id
  // Im Unterschied zu einem TemplateField kann der Wert später geändert werden.
  guestformImportFromCol = '';  // Kann in Verbindung mit guestformImportFromTable angegeben werden, wenn der Spaltenname der Hilfstabelle
  // nicht mit eigenem Spaltennamen übereinstimmt
  guestformShowInRecap = true;  // Hiermit kann die Anzeige in Recap noch einmal extra ausgeschaltet werden.
  guestformAfterRecap = false;  // Feld nicht im Step sondern erst nach der Gesamt-Zusammenfassung anzeigen (für Einwilligungen, etc.)
  // VIEW:
  viewShow = true;

  linkIcon = 'east'; // nur verwendet, wenn inlineEdit aktiv ist. Ansonsten wird direkt der Text verlinkt. 
  // Geht außerdem nur bei Standardfeldern (Textfeld). (east: Pfeil nach rechts)
  link = (row: any): Partial<Link> | null => null;
  // Verlinkung des Feldes (in Tabellenansicht). Das Objekt Link wird erst beim Aufrufen des Links initialisiert.

  calc = (row: any, isGuestform?: boolean): any => null;
  // Berechnung, die nach jedem Update der Zeile für dieses Feld durchgeführt werden soll
  // wenn die Zelle updatable = false, dann wird calc nur beim Erstellen durchgeführt.

  hasFiles = (rowData) => false;

  appConfig: IAppConfig;
  constructor(
    table: string,
    init: Partial<Field>,
    hooks: HooksService,
    appConfig: IAppConfig
  ) {
    if (hooks !== null && hooks !== undefined) {
      this.link = hooks.configMethod('fieldLink_' + table + '_' + init.id);
      this.calc = hooks.configMethod('fieldCalc_' + table + '_' + init.id);
    }
    this.appConfig = appConfig;
  }

  isRequiredInForm(): boolean {
    return (this.required || this.guestformRequired);
  }

  getFormattedVal(val, ignoreDateType?: boolean): any {
    if (val === undefined || val === null || val === '' || this.format === '') {
      if (this.type === 'date' && !ignoreDateType) return moment(val).format(this.appConfig.dateFormat.moment);
      else return val;
    }
    return this.format.replace('$val', val);
  }
  getPrefix(): string {
    if (this.format === '') return '';
    else return this.format.split('$')[0];
  }
  getSuffix(): string {
    if (this.format === '') return '';
    else return this.format.substring(this.format.indexOf('$val') + 4);
  }

  setShowConditional(rowData): void {
    if (this.guestformOnlyShowIfCondition === '') this.guestformShowConditional = !!rowData[this.guestformOnlyShowIfField];
    else this.guestformShowConditional = rowData[this.guestformOnlyShowIfField] == this.guestformOnlyShowIfCondition;
  }
}

export class StringField extends Field {
  value = '';
  type = 'string';     // oder: textarea (derzeit)
  maxSize = 256;       // Standardlänge
  inlineEdit = true;   // inlineEdit ist standardmäßig aktiviert
  constructor(tablename: string, init: Partial<StringField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
  }
}


export class DateField extends Field {
  value: Date = undefined;
  type = 'date';
  validation = 'date';
  guestformAsCheckbox = false;   // in guestform als Checkbox anzeigen und aktuelles Datum speichern. required ist dann automatisch true
  guestformText = '';            // wenn als Checkbox angezeigt: Text für Einverständnis etc. Wenn '', wird nur das label angezeigt.
  constructor(tablename: string, init: Partial<DateField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
  }
}

export class NumberField extends Field {
  // Hinweis: wird maxSize angegeben, wird automatisch ein Integer-Wert angenommen, ansonsten float (derzeit nur für make-config / makeSQL() relevant)
  value: number = undefined;
  type = 'number';     // oder: euro
  validation = 'int';
  inlineEdit = true;    // inlineEdit ist standardmäßig aktiviert
  constructor(tablename: string, init: Partial<NumberField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
  }
}

export class RateField extends NumberField {
  // DERZEIT NUR IN SPIELERBEWERTUNG IN VERWENDUNG - wenn es woanders auch eingesetzt werden soll, müssten FormGroupComp, FieldContentComp etc. noch angepasst werden.
  // für Integer-Values
  value: number = undefined;
  type = 'rate';
  minValue = 0;
  maxValue = 10;
  stepSize = 1;
  inlineEdit = true;    // inlineEdit ist standardmäßig aktiviert
  constructor(tablename: string, init: Partial<RateField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
  }  
}

export class BooleanField extends Field {
  value = false;
  type = 'boolean';
  inlineEdit = true;    // inlineEdit ist standardmäßig aktiviert
  guestformText = '';   // Text für Einverständnis etc. Wenn '', wird nur das label angezeigt.
  constructor(tablename: string, init: Partial<BooleanField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
  }
}

export class ImageField extends Field {
  /*  - ImageFields werden nicht in Guestforms angezeigt
   */
  value = '';           // ein JSON-String mit den Infos "filename" und "thumbFilename"
  type = 'image';
  altText = '';
  inlineEdit = false;   // inlineEdit ist nicht möglich, Bilddialog kann immer durch Klicken geöffnet werden
  tooltip = 'Anklicken öffnet den Bild-Dialog';

  hasFiles = (rowData) => !!rowData[this.id]  // Gibt true zurück, wenn tatsächlich ein Wert gespeichert ist. (Wert muss übergeben werden)

  getUrl = (x) => this.getInfo(x, 'filename');
  getThumbUrl = (x) => this.getInfo(x, 'thumbFilename');
  getInfo(x: string, prop: string): string {
    // Inkl. Sicherheitsabfragen, falls fehlerhafter JSON-Code vorhanden
    if (x === '') return x;
    const uploadPath = this.appConfig.filemanager.uploadPath;
    // Wenn x ein absoluter Pfad ist (kann derzeit nur manuell in DB eingetragen werden), diesen zurückgeben. 
    // Ansonsten Uploads Ordner-Pfad + Dateinamen zurückgeben.
    try {
      const result = JSON.parse(x);
      return result[prop].startsWith('https://') ? result[prop] : uploadPath + result[prop];
    } catch (e) {
      return x.startsWith('https://') ? x : uploadPath + x;
    }
  }
  constructor(tablename: string, init: Partial<ImageField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
  }
}
export class VideoField extends Field {
  /*  - VideoFields werden nicht in Guestforms angezeigt
   */
  value = '';
  type = 'video';
  inlineEdit = false;    // inlineEdit ist nicht möglich, Videodialog kann immer durch Klicken geöffnet werden
  tooltip = 'Anklicken öffnet den Video-Dialog';

  hasFiles = (rowData) => !!rowData[this.id]  // Gibt true zurück, wenn tatsächlich ein Wert gespeichert ist. (Wert muss übergeben werden)

  // Wenn value ein absoluter Pfad ist (kann derzeit nur manuell in DB eingetragen werden), diesen zurückgeben. 
  // Ansonsten Uploads Ordner-Pfad + Dateinamen zurückgeben.
  getUrl = (x) => x.startsWith('https://') ? x : this.appConfig.filemanager.uploadPath + x;

  constructor(tablename: string, init: Partial<DateField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
  }
}

export class FileField extends Field {
  /* - Für andere Dateien zum Hochladen außer Bilder und Videos, z.B. pdf. 
     - FileFields werden derzeit nicht in Guestforms angezeigt
   */
  value = '';
  type = 'file';
  inlineEdit = false;    // inlineEdit ist nicht möglich, Filedialog kann immer durch Klicken geöffnet werden
  acceptFiletypes = 'application/pdf';  // Für Dateiauswahl-Dialog. Voreinstellung: PDFs
  acceptFileEndings = ['pdf'];          // Für PHP-Sicherheitsüberprüfung.
  tooltip = 'Anklicken öffnet den Dateiupload-Dialog';

  hasFiles = (rowData) => !!rowData[this.id]  // Gibt true zurück, wenn tatsächlich ein Wert gespeichert ist. (Wert muss übergeben werden)

  // Wenn value ein absoluter Pfad ist (kann derzeit nur manuell in DB eingetragen werden), diesen zurückgeben. 
  // Ansonsten Uploads Ordner-Pfad + Dateinamen zurückgeben.
  getUrl = (x) => x.startsWith('https://') ? x : this.appConfig.filemanager.uploadPath + x;

  constructor(tablename: string, init: Partial<FileField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
  }
}

export class SelectField extends Field {
  value = '';
  type = 'select';
  multiSelect = false;
  options: Option[] = [];   // ganz wichtig, hier mit [] zu initialisieren, weil sonst in der filter.comp bei ngOnChanges Fehler entstehen
  autoOptionsTable: string; // options aus helperTable generieren (helperTable muss im Table Model definiert sein!)
  autoOptionsCol = 'id';    // helperTable column (standardmäßig 'id')
  autoOptionsTemplate: string; // use template function from helperTable
  sortOptions = true;       // Optionen alphanumerisch (nach Label) sortieren
  optionsFilters: Filter[] = [];  // Optionen werden mit diesem vordefinierten statischen Filtern gefiltert
  // optionsSort: KwiSort;  // Optionen werden mit dieser Sortierung sortiert (ToDo)
  inlineEdit = true;        // inlineEdit ist standardmäßig aktiviert
  getActiveOption = (val: any): string => {
    if (!val) return '';
    if (this.multiSelect && Array.isArray(val)) { // Für MultiSelect alle ausgewählten anzeigen:
      const ret = [];
      for (const entry of val) {
        const activeOption = this.options.find((o) => o.value.toString() === entry.toString());
        if (activeOption !== undefined) ret.push(activeOption.label);
      }
      return ret.join(', ');
    } else {
      const activeOption = this.options.find((o) => o.value.toString() === val.toString());
      if (activeOption !== undefined) return activeOption.label;
    }
    return '#' + val;
  }

  constructor(tablename: string, init: Partial<SelectField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
    if (this.optionsFilters?.length) this.optionsFilters = this.optionsFilters.map((f) => new Filter(f));
  }
}

export class TemplateField extends Field {
  /* nur zum Anzeigen von Daten, die mittels eines templates generiert werden.
     In diesem Feld / Spalte werden keine Werte gespeichert.
     Template und Daten können aus der Haupttabelle oder aus einem HelperTable kommen.
     Die Haupttabelle muss dafür eine Spalte mit der id: hilfstabelle + '_id' haben. (z.B. Spalte kurse_id für Hilfstabelle kurse)
     - Templates werden (derzeit) nicht in Guestforms angezeigt, können dort aber verwendet werden, um an API übergeben zu werden
   */
  value = '';
  type = 'template';
  inlineEdit = false;
  editable = false;
  guestformShow = false; // Template-Felder werden standardmäßig nicht in Formularen angezeigt, muss eigens aktiviert werden.
  guestformEdit = false;
  templateId = '';      // ID des templates im eigenen table oder - wenn importFromTable definiert - in dem Helpertable.
  importFromTable = ''; // Daten kommen nicht aus der Haupttabelle, sondern aus dieser Hilfstabelle

  constructor(tablename: string, init: Partial<TemplateField>, hooks: HooksService, appConfig: IAppConfig) {
    super(tablename, init, hooks, appConfig);
    Object.assign(this, init);
  }
}

/*********************************************/
/* TABLE
/* allgemeine Definitionen für eine Tabelle: */
/*********************************************/
/*
## HelperTables / JoinedDataTables ##

## HelperTables: Jede Tabelle kann beliebig viele Hilfstabellen definiert haben, die für die Anzeige der Tabelle benötigt werden.
   - Die Haupttabelle besitzt für jede Hilfstabelle eine Spalte [helperTable]_id, 
     in der die id des zugehörigen Datensatzes in der Hilfstabelle gespeichert ist.
   - Hilfstabellen können zur Anzeige von Templates, SelectFields, etc. herangezogen werden.
   - Vor dem Darstellen einer Tabelle müssen daher alle Hilfstabellen ebenfalls geladen sein.
   - Es ist möglich, Templates zu verschachteln, also Templates der Hilfstabelle1 aus Templates der Hilfstabelle2 zu bauen.
     Dazu müssen die Hilfstabellen in der Reihenfolge angegeben werden, in der sie ausgelesen und die Templates erstellt werden sollen.
     Daten der TemplateFields werden in cachedData und cachedDataIndexed gespeichert.

## JoinedDataTables: ist sozusagen die umgekehrte Liste zu den helperTables
   - Jede JoinedDataTable besitzt eine Spalte [mainTable]_id, in der die id des zugehörigen Hauptdatensatzes gespeichert ist.
   - Bei Merge werden (auf Wunsch) ebenfalls alle Einträge einer bestimmten Datenzeile in den Hilfstabellen zusammengeführt.
   - Beim Löschen eines Eintrags werden (auf Wunsch) auch alle Einträge der gelöschten Datenzeile aus den Hilfstabellen gelöscht.
   - Die Liste der JoinedDataTables, die in der SingleComponent angezeigt werden, kann individuell definiert werden, da ja ev. nicht alle
     zugehörigen / verknüpften Daten angezeigt werden sollen.
*/
export class Table {
  mainTable: string;
  helperTables: string[] = [];     // Diese Hilfstabellen enthalten Daten, die für die Anzeige der Tabelle gebraucht werden. Mehr Infos siehe oben.
  joinedDataTables: string[] = []; // Die Datensätze dieser Tabellen sind mit den Datensätzen dieser Tabelle verknüpft. Siehe oben
  config: TableConfig;
  columns = {};
  appError: ErrorService;
  appConfig: IAppConfig;
  auth: AuthenticationService;
  hasFileCols: boolean;
  // Templating-Funktion. row = Datenreihe, templateId zur Unterscheidung, falls mehrere Templates verwendet werden sollen.
  // TemplateId kann weggelassen werden, wenn es pro Tabelle nur ein Template gibt.
  templates = (row: any, templateId?: string, header?: any): string => '';

  constructor(
    private tablesModel: Tables,
    configData: any,
    appConfig: IAppConfig,
    hooks: HooksService,
    auth: AuthenticationService,
    appError: ErrorService,
  ) {
    this.mainTable = configData.mainTable;
    this.helperTables = configData.helperTables ?? [];
    this.joinedDataTables = configData.joinedDataTables ?? [];
    this.appError = appError;
    this.appConfig = appConfig;
    this.auth = auth;
    this.hasFileCols = false;

    // Table Config:
    if (!configData.config) {
      appError.throw(1111, { snackbar: true, snackbarMsg: 'Fehler beim Auslesen der Tabellen-Konfiguration' });
      return;
    }
    // TableConfig-Daten aus AppConfig auslesen und entkoppeln und daraus TableConfig-Objekt erstellen:
    this.config = new TableConfig(tablesModel, this.mainTable, JSON.parse(JSON.stringify(configData.config)), hooks, appError);

    // Table Columns:
    if (!configData.columns) {
      appError.throw(1113, {
        msg: 'Fehler in der Tabellen-Konfiguration (keine Spalten definiert): Tabelle ' + this.config.label,
        snackbar: true, snackbarMsg: 'Fehler in der Tabellen-Konfiguration'
      });
      return;
    }
    for (const col of Object.keys(configData.columns)) {
      const field = structuredClone(configData.columns[col]) as Field;  // von configData entkoppeln
      field.id = col; // field.id automatisch auf entsprechenden key von columns setzen
      if (!field.type) {
        appError.throw(1114, {
          msg: 'Fehler in der Tabellen-Konfiguration (Feldtyp nicht definiert): Tabelle ' +
            this.config.label + ', Spalte ' + col, snackbar: true, snackbarMsg: 'Fehler in der Tabellen-Konfiguration'
        });
        return;
      }
      switch (field.type) {
        case 'string':
        case 'textarea':
          this.columns[col] = new StringField(this.mainTable, field, hooks, appConfig);
          break;
        case 'number':
        case 'euro':
          this.columns[col] = new NumberField(this.mainTable, field, hooks, appConfig);
          break;
        case 'date':
          this.columns[col] = new DateField(this.mainTable, field, hooks, appConfig);
          break;
        case 'boolean':
          this.columns[col] = new BooleanField(this.mainTable, field, hooks, appConfig);
          break;
        case 'image':
          this.columns[col] = new ImageField(this.mainTable, field, hooks, appConfig);
          this.hasFileCols = true;
          break;
        case 'video':
          this.columns[col] = new VideoField(this.mainTable, field, hooks, appConfig);
          this.hasFileCols = true;
          break;
        case 'file':
          this.columns[col] = new FileField(this.mainTable, field, hooks, appConfig);
          this.hasFileCols = true;
          break;
        case 'select':
          this.columns[col] = new SelectField(this.mainTable, field, hooks, appConfig);
          break;
        case 'template':
          this.columns[col] = new TemplateField(this.mainTable, field, hooks, appConfig);
          break;
        default:
          appError.throw(1115, {
            msg: 'Fehler in der Tabellen-Konfiguration (Unbekannter Feldtyp): Tabelle ' + this.config.label + ', Spalte ' + col + ', Typ ' + field.type,
            snackbar: true, snackbarMsg: 'Fehler in der Tabellen-Konfiguration'
          });
          return;
      }
    }

    // Table Templates:
    if (hooks !== null && hooks !== undefined) {
      this.templates = hooks.configMethod('tableTemplates_' + this.mainTable);
    }
  }

  public getColumns(list?: boolean, includeSystem?: boolean): string[] {
    /*  get Array with all column ids
        - list = true => return only columns with property list = true
        - includeSystem = true => also return system columns
    */
    const tableColumns = [];
    Object.keys(this.columns).forEach((e) => {
      if ((!!includeSystem || !this.columns[e].system) && (!list || this.columns[e].list)) {
        tableColumns.push(e);
      }
    });
    return tableColumns;
  }

  public getColumnsByFilter(filter: (col: Field | any) => boolean): string[] {  // "Field | any" steht für: Field oder eine ihrer Extensions (StringField, ...) 
    /* get Array with ids of columns filtered by filter-function
     */
    const cols = [];
    Object.keys(this.columns).forEach(e => {
      if (filter(this.columns[e])) {
        cols.push(e);
      }
    });
    return cols;
  }

  public getInlineEditColumns(): string[] {
    /* get Array with all column ids that are inline editable
     */
    return this.getColumnsByFilter((col) => !col.system && col.editable && col.updatable && col.inlineEdit && col.type !== 'boolean');
  }

  public getDataObject(useUndefinedAsValue?: boolean) {
    /* get Object with column ids and standard value; { id1: value1, ... }
       - ohne system cols
    */
    const dataObject = {};
    Object.keys(this.columns).forEach(e => {
      if (!this.columns[e].system) {
        dataObject[e] = useUndefinedAsValue ? undefined : this.columns[e].value;
      }
    });
    return dataObject;
  }

  public doCalculations(row: any, isUpdate: boolean, isGuestform?: boolean): any {
    /* Führt calc für alle Zellen der row durch, falls vorhanden.
       - Es werden hier alle Spalten der Tabelle durchgegangen und berechnet, nicht nur die, die in row übergeben wurden
         Dies ist wichtig für Berechnungen in nicht angezeigten Systemspalten.
       - Zurückgegegeben werden ebenfalls alle Spalten, die neu berechnet wurden.
         Es können also mehr zurückgegeben werden als übergeben wurden.
       - wenn isUpdate = true, wird calc nur durchgeführt, wenn die Zelle updatable ist
       - Parameter isGuestform wird an calc übergeben
     */
    Object.keys(this.columns).forEach(e => {
      // float-Zahlen konvertieren (ist notwendig, damit wir in der Eingabe "," erlauben können):
      if (this.columns[e].validation === 'float' && typeof row[e] === 'string' && row[e].indexOf(',') > -1) {
        row[e] = parseFloat(row[e].replace(',', '.'));
      }
      // calc durchführen
      if (this.columns[e] && this.columns[e].calc(row, this.auth, isGuestform) !== null && (!isUpdate || this.columns[e].updatable)) {
        row[e] = this.columns[e].calc(row, this.auth, isGuestform);
      }
    });
    return row;
  }

  public getTemplates(data: any, cachedDataIndexed: any, dataIsOneRow: boolean): any {
    /* Templates anwenden. Falls nötig, Daten aus Hilfstabellen holen
       - Die Funktion kann für eine ganze Tabelle oder nur eine Zeile (dataIsOneRow = true) verwendet werden.
       - für Felder type = template
       - templateId muss definiert sein!
       - Falls importFromTable definiert ist, wird das template aus dem HelperTable geholt.
       - Wenn isView = true, werden auch alle SelectFields als TemplateFields behandelt
     */

    for (const colId of this.getColumnsByFilter((col) => col.type === 'template')) {
      const importTable = this.columns[colId].importFromTable || '';
      // Daten aus Hilfstabellen importieren (eine Zeile):
      if (dataIsOneRow) {
        data[colId] = this.getTemplateCell(data, colId, importTable, cachedDataIndexed);
      } else {
        // Daten aus Hilfstabellen importieren (nur vordefinierte Spalten):
        for (const rowId in data) {         // Gehe alle Zeilen von rawData durch
          if (data.hasOwnProperty(rowId)) { // reine Sicherheitsabfrage (vorgeschrieben)
            data[rowId][colId] = this.getTemplateCell(data[rowId], colId, importTable, cachedDataIndexed);
          }
        }
      }
    }
    return data;
  }
  public getTemplateCell(row, colId, importTable, cachedDataIndexed): string {
    /* Für einzelne Zelle Template anwenden.
       - wenn nötig, vorher Daten aus HelperTable importieren.
     */

    if (importTable === '') { // aus eigener Tabelle
      return this.templates(row, this.columns[colId].templateId);
    }
    // Importieren (Spalte mit Import-Id MUSS vorhanden sein und lauten: tablename + '_id'):
    const importId = row[importTable + '_id'];
    if (!importId) return '';           // ImportId nicht definiert
    // Wenn HelperTable nicht definiert (und damit nicht sicher geladen) ist oder Daten nicht vorhanden => abbrechen.
    if (!this.helperTables.includes(importTable) || !cachedDataIndexed || !cachedDataIndexed[importTable]) return '#' + importId;
    row = cachedDataIndexed[importTable][importId];
    if (!row) return '#' + importId;
    return this.tablesModel.tables[importTable].templates(row, this.columns[colId].templateId);
  }

  public makeAutoOptions(cachedData) {
    /* Options für SelectField machen */
    for (const colId of this.getColumnsByFilter((col) => col.type === 'select' && col.autoOptionsTable)) {
      const colHeader: SelectField = this.columns[colId];
      // Zur Sicherheit überprüfen, ob HelperTable definiert (und damit geladen) ist:
      if (!this.helperTables.includes(colHeader.autoOptionsTable) || !cachedData[colHeader.autoOptionsTable]) return [];
      let optionsData = cachedData[colHeader.autoOptionsTable];
      // Vorfiltern:
      if (colHeader.optionsFilters?.length) {

        for (const filter of colHeader.optionsFilters) {
          optionsData = filter.doOnpageFiltering(optionsData);
        }
      }
      colHeader.options = optionsData.map((row) => {
        const value = row[colHeader.autoOptionsCol].toString();      // TESTWEISE toString(), um Probleme mit ids als Nummern zu vermeiden
        const label = (colHeader.autoOptionsTemplate ?
          this.tablesModel.tables[colHeader.autoOptionsTable].templates(row, colHeader.autoOptionsTemplate) :
          row[colHeader.autoOptionsCol]) ?? value;    // wenn kein Label verfügbar, value als Label verwenden
        return { value, label };
      });

      // Alphanumerisch sortieren:
      if (colHeader.sortOptions) {
        colHeader.options = colHeader.options.sort((a, b) => (a.label.toString().toLowerCase() > b.label.toString().toLowerCase()) ? 1 : -1);
      }
    }
  }

  public setShowConditionalForFields(rowData) {
    this.getColumnsByFilter(col => col.guestformOnlyShowIfField && rowData.hasOwnProperty(col.guestformOnlyShowIfField)).forEach(col => {
      this.columns[col].setShowConditional(rowData);
    });
  }

  public prepareDataForApi(data, isNew: boolean, isGuestform: boolean | null): any {
    // UUID erzeugen:
    // UUIDs können auch schon früher (z.B. für Email-Versand) generiert werden und sollen dann nicht mehr überschrieben werden.
    if (isNew && this.config.useUUID && !data.id) data.id = uuidv1();

    const retData = Object.assign({}, data);  // Entkoppeln. Erst nach UUID-Erzeugung, weil die wollen wir gleich verfügbar haben.
    // Hier kein structuredClone() verwenden! Ist nicht nötig (weil es für die Daten keinen DeepClone braucht) und macht Probleme bei Date-Feldern

    // Wenn keine UUIDs verwendet werden, gibt es laufende IDs die in der DB mit AutoIncrement erzeugt werden. In diesem Fall muss ein leerer Wert übergeben werden.
    // Hinweis: Beim Import ohne IDs werden temporär UUIDs gesetzt (für die Verarbeitung des Imports), diese werden hier wieder gelöscht.
    if (isNew && !this.config.useUUID) data.id = undefined;
    Object.keys(this.columns).forEach((col) => {
      // Convert dates:
      if (this.columns[col].type === 'date' && col in retData) {
        if (!retData[col]) retData[col] = null;  // Leere Datensätze in gültigem Format für SQL abspeichern
        else {
          // wenn Date asCheckbox in Guestform, heutiges Datum abspeichern.
          if (isGuestform && this.columns[col].guestformAsCheckbox) retData[col] = moment().format('YYYY-MM-DD');
          // format date to yyyy-mm-dd:
          else retData[col] = moment(retData[col]).format('YYYY-MM-DD');
        }
      }
      // Convert arrays to JSON für multiSelect:
      if (this.columns[col].type === 'select' && Array.isArray(retData[col])) {
        retData[col] = JSON.stringify(retData[col]);
      }
      // Convert empty number or select fields to null:
      if ((this.columns[col].type === 'number' || this.columns[col].type === 'euro' || this.columns[col].type === 'select') &&
        col in retData && (retData[col] === '' || retData[col] === undefined) || (Array.isArray(retData[col]) && retData[col].length === 0)) {
        retData[col] = null;
      }
    });
    return retData;
  }

  public prepareDataFromApi(data, isSingleRow?: boolean): any {
    /* Im Unterschied zu prepareDataForApi ist data hier nicht nur eine row, sondern alle eingelesenen rows 
       Außer wenn isSingleRow = true 
    */
    if (!data) return data;
    if (isSingleRow) data = [data];
    Object.keys(this.columns).forEach((col) => {
      // Convert JSON to array für multiSelect:
      if (this.columns[col].type === 'select' && this.columns[col].multiSelect) {
        for (const row of data) {
          if (row[col]) {
            try {
              row[col] = JSON.parse(row[col]);
            } catch (e) {
              this.appError.throw(6151, { msg: `MultiSelect: JSON-Wert aus DB konnte nicht geparsed werden. Tabelle: ${this.mainTable}, Spalte: ${col}` });
            }
          }
        }
      }
    });
    if (isSingleRow) data = data[0];
    return data;
  }

  public prepareDataForExport(row: any, laufnummer: number, isMachineFormat?: boolean): any {
    // Eine Datenzeile für CSV-Export vorbereiten und ev. in menschenlesbares Exportformat umwandeln
    // EXPORT VON DATEIEN DERZEIT NOCH NICHT UMGESETZT / VERWENDBAR!
    const retData = [];
    if (laufnummer) retData.push(laufnummer);
    if (isMachineFormat) retData.push(row.id);

    for (const col of this.getColumns(false, isMachineFormat)) {
      if (col in row) {
        const field = this.columns[col] as Field;
        let retValue;

        // Für Backup / Transfer:
        if (isMachineFormat) {
          if (field.type === 'template') continue; // Template-Felder werden hier nicht exportiert
          if (row[col] === null || row[col] === undefined) retValue = "";
          else retValue = row[col];
        }

        // Menschenlesbarer Export:
        else {
          // Convert boolean:
          if (field.type === 'boolean') {
            if (field.required) retValue = row[col] ? 1 : 0;
            else retValue = row[col] ? 1 : "";
          }
          // Leere Felder:
          else if (row[col] === null || row[col] === undefined) retValue = "";
          // Convert dates:
          else if (field.type === 'date') {
            retValue = moment(row[col]).format(this.appConfig.dateFormat.moment);
          }
          // Convert selects to text:
          else if (field.type === 'select') {
            retValue = this.columns[col].getActiveOption(row[col]);
          }
          // Convert templates to text:
          else if (field.type === 'template') {
            const templateField = field as TemplateField;
            if (templateField.importFromTable) {
              retValue = this.tablesModel.tables[templateField.importFromTable].templates(row, templateField.templateId);
            }
            else {
              retValue = this.templates(row, templateField.templateId);
            }
          }
          else retValue = row[col];
        }

        // für CSV-Export in "" wrappen:
        retData.push("\"" + retValue + "\"");
      }
    }
    return retData;
  }

  public prepareDataFromImport(row: any, removeFiles: boolean, removeSelects: boolean): any {
    /* Eine Datenzeile (aus CSV-Import) kontrollieren / anpassen für Speicherung in Datenbank
       alle Daten sind strings
       removeFiles: Alle Dateieinträge löschen - IMPORT VON DATEIEN DERZEIT NOCH NICHT UMGESETZT / VERWENDBAR!
       removeSelects: Alle Selects mit Daten aus HelperTables löschen.
    */
    const retData = {};
    retData['id'] = row.id; // id kopieren
    this.getColumns(false, true).forEach((col) => {
      const field = this.columns[col] as Field;
      if (col in row) {
        const value: string = row[col];
        if (field.type !== 'string' && value === '') retData[col] = null;  // Leere Felder auf null setzen (außer leeres String-Feld)
        else {
          switch (field.type) {
            case 'string':
            case 'textarea':
              retData[col] = value;
              break;
            case 'number':
            case 'euro':
              if (field.validation === 'int') {
                retData[col] = parseInt(value);
              } else {
                // float-Zahlen konvertieren (ist notwendig, damit wir in der Eingabe "," erlauben können):
                if (typeof value === 'string' && value.indexOf(',') > -1) {
                  retData[col] = parseFloat(value.replace(',', '.'));
                }
                else retData[col] = parseFloat(value);
              }
              if (isNaN(retData[col])) retData[col] = 'kwi_error_nan:' + value;
              break;
            case 'boolean':
              retData[col] = value === 'true' ? true : false;
              break;
            case 'date':
              if (moment(value).isValid()) retData[col] = value;
              else retData[col] = 'kwi_error_invalid_date:' + value;
              break;
            case 'image':
            case 'video':
            case 'file':
              if (removeFiles) retData[col] = null;
              else retData[col] = value;
              break;
            case 'select': {
              //  Alle Selects mit Daten aus HelperTables löschen.
              const selectField = field as SelectField;
              if (removeSelects && selectField.autoOptionsTable && selectField.autoOptionsTable !== this.mainTable) retData[col] = null;
              else {
                if (this.columns[col].multiSelect) retData[col] = value.split(',');
                else retData[col] = value;
              }
              break;
            }
            case 'template':  // sollte eigentlich nicht vorkommen, weil die ja nicht exportiert werden
              break;
            default:          // sollte nicht vorkommen, weil TableConfig ja schon beim Init überprüft wird
              this.appError.throw(1115, {
                msg: 'Fehler in der Tabellen-Konfiguration (Unbekannter Feldtyp): Tabelle ' + this.config.label + ', Spalte ' + col + ', Typ ' + field.type,
                snackbar: true, snackbarMsg: 'Fehler in der Tabellen-Konfiguration'
              });
              return;
          }
        }
        if (this.isRequiredButEmpty(retData[col], col)) retData[col] = 'kwi_error_required';

      }
      else {      // Dateneintrag nicht vorhanden (!col in row):
        if (field.type !== 'template') {
          if (field.required) retData[col] = "kwi_error_missing";
          else retData[col] = null; // Wenn kein Pflichtfeld, einfach null setzen
        }
      }
    });
    return retData;
  }

  public isRequiredButEmpty(value: any, col: string): boolean {
    /* gibt true zurück, wenn die Spalte required = true hat und kein Wert vorhanden ist.
       Kein Wert heißt !!wert für alle Spalten außer type = boolean und number, dort gilt false bzw. 0 natürlich auch als Wert.
      col muss eine gültige Spalte in diesem Table sein (vorher überprüfen!)       
      */
    const field = this.columns[col] as Field;
    if (field.required && !value) {
      if (field.type === 'boolean' && value === false) return false;
      if (field.type === 'number' && value === 0) return false;
      return true;
    }
    return false;
  }
}

export class TableConfig {
  label: string;
  labelSingle: string;       // Einzahlform
  useUUID = false;           // UUID als id verwenden
  // allowguestform = false;
  anonRights: TableRights;   // Für anonyme / alle Besucher, z.B. für Forms oder Views
  guestRights: TableRights;  // Für angemeldete / identifizierte Gast-Benutzer, z.B. für Forms oder Views
  readonlyUserRights: Partial<TableRights> = { // Für vollwertige Benutzer, die im Augenblick aber nur eingeschränkten Zugriff haben (Lesemodus)
    updateRow: false,        // Das ist der Standardwert. Der kann für diese Tabelle abgeändert werden, wenn allowCustomReadonlyUserRightsForModules für das entsprechende Modul gesetzt wurde
  };
  readArgs: ReadArgs;        // für Vorsortierung (ev. auch fix definierte Filter). Diese readArgs gelten immer, für jede Abfrage via DataService.read()
  // AnzeigeOptionen:
  filterBar = false;         // FilterBar anzeigen oder ausblenden
  showVolltext = true;       // Volltextfilter anzeigen
  filters: Filter[] = [];
  // sort: KwiSort;          // bei tables kein sort in der filterBar erlauben, sondern stattdessen nur matSort verwenden!
  grouping: Grouping;        // Gruppierung der Tabelle: für jeden unterschiedlichen Wert der Spalte groupBy wird ein eigener MatTable angezeigt
  // Weitere Infos siehe Klasse GroupBy
  showPrintButton = false;   // Drucken-Button in FilterBar anzeigen
  showPaginationOnTop = false;  // Pagination auch über der Tabelle anzeigen
  matSort: MatSortable;      // Einzelne Tabellen (auch in Gruppierung) können via MatSort sortiert werden.
  laufnummer: {
    active: false;           // Laufende Nummer in der ersten Spalte der Tabelle hinzufügen
    allowUserSelection: false; // Auswahl, ob Laufnummer angezeigt werden soll oder nicht, anzeigen
  }
  autoIncrementRow = '';     // Für diese Spalte wird bei Add und Duplicate versucht, einen AI-Wert vorzuschlagen, auch wenn es eine string-Spalte ist.
  limits: {                  // Limits für z.B. Anmeldungen pro Kurs (also Formulareingaben pro Tabelle)
    active: false;           // Wird bei der Haupttabelle gesetzt (z.B. Kurse)
    countTable: string;      // Tabelle, deren Einträge gezählt werden (z.B. Anmeldungen)
    // Die Limit-DB-Tabelle muss dann [countTable]_limits heißen und benötigt die Spalten id, limit_count und current_count.
    copyCol: string;         // Name der Spalte, deren Wert beim Speichern dieser Tabelle in limits.table in die Spalte limit_count geschrieben oder dieser upgedatet wird
    overLimitText: string,   // Hinweis für den Benutzer, wenn das Limit überschritten wurde. Wird bei der Option angezeigt. (z.B. "ausgebucht")

    // Folgendes muss in der Config des CountTables eingetragen werden:
    countTableIdCol: '';     // die foreign-id-col im CountTable, die als id in den LimitTable geschrieben wird (z.B. "kurse_id")
    // wenn hier etwas angegeben wird, wissen wir automatisch, dass es sich hierbei um einen CountTable handelt.

    table: string,           // wird automatisch erstellt: [countTable]_limits 
  }
  actions = {
    active: true,            // Die Anzeige der Spalte mit den Aktionsbuttons kann hier grundsätzlich ein- und ausgeschaltet werden. 
    global: {
      new: false,
      addBatchDateCol: '',   // ColId der Datums-Spalte, für die ein AddBatchDate bereitgestellt werden soll.
      deleteBatch: false,
      exportAll: false,
      exportBatch: false,
      import: false,
      custom: [] as Action[],
    },
    row: {
      edit: false,
      delete: false,
      merge: false,
      duplicate: false,
      showSingle: false,
      custom: [] as Action[]
    },
    mobile: {                // QuickActions, werden nur unterhalb vom breakpointS als FabIcons angezeigt (fixed, rechts unten)
      new: false,
      addBatchDateCol: '',   // ColId der Datums-Spalte, für die ein AddBatchDate bereitgestellt werden soll.
      custom: [] as Action[],
    }
  };
  single = {
    active: false,
    titleTemplate: '',                           // TemplateId aus templates()
    joinedDataTables: [] as JoinedDataTable[],   // Inhalt 2. Spalte - kann hier getrennt von joinedDataTables in class Table definiert werden
    statistics: [] as Statistics[],              // ebenfalls in 2. Spalte
    actions: {                                   // Buttons in der Kopfzeile
      edit: false,
      delete: false,
      merge: false,
      duplicate: false,
      custom: [] as Action[]
    }
  };
  demoUser = {
    allowCreate: true,       // Action "new" für Demo-User anzeigen und erlauben 
  }

  dialog = {
    titleTemplate: '',       // TemplateId aus templates(), Titel "[labelSingle] [template] bearbeiten/löschen"
    // Standard ('') => [labelSingle] bearbeiten/löschen 
    // Gilt nur für edit und delete, nicht für add, duplicate und merge
    mergeRow2Template: '',   // Template für Anzeige der Row2 im Merge-Dialog (im Dropdown-Select)
    deleteJdtPreset: true,   // Vorauswahl für: Zugehörigen Einträge in anderen Tabellen ebenfalls löschen
    mergeJdtPreset: 'merge'  // Vorauswahl für Umgang mit zugehörigen Einträgen in anderen Tabellen ('merge' | 'delete' | '')
  }

  constructor(
    private tablesModel: Tables,
    tableName: string,
    init?: Partial<TableConfig>,
    hooks?: HooksService,
    appError?: ErrorService,
  ) {
    Object.assign(this, init);
    // TableRights:
    this.anonRights = Object.assign(new TableRights(), this.anonRights);
    this.guestRights = Object.assign(new TableRights(), this.guestRights);
    // Vorsortierung (ev. auch fix definierte Filter):
    this.readArgs = new ReadArgs(this.readArgs);
    // Filter:
    if (this.filters) this.filters = this.filters.map((f) => new Filter(f));
    // Grouping:
    if (this.grouping) this.grouping = new Grouping(this.grouping);
    // MatSort:
    this.matSort = Object.assign({ disableClear: false }, init.matSort);  // disableClear muss für die Kompatibilität mit MatSortable gesetzt werden

    // ACTIONS:
    if (!this.actions.active && this.actions.active !== false) this.actions.active = true; // Actions.active ist standardmäßig true, außer es wird auf false gesetzt
    // Custom actions bauen:
    if (this.actions?.global?.custom) {
      this.actions.global.custom = this.actions.global.custom.map((a) => new Action(a, hooks));
    }
    if (this.actions?.row?.custom) {
      this.actions.row.custom = this.actions.row.custom.map((a) => new Action(a, hooks));
    }
    if (this.single?.actions?.custom) {
      this.single.actions.custom = this.single.actions.custom.map((a) => new Action(a, hooks));
    }

    // Limits:
    if (this.limits?.active) {
      if (this.limits.countTable) {
        this.limits.table = this.limits.countTable + '_limits';
      } else {
        this.limits.active = false;
        appError.throw(1161);
      }
    }
    if (this.limits?.countTableIdCol) {
      this.limits.table =  tableName + '_limits';
    }

    // JoinedDataTables bauen:
    if (this.single.active && this.single.joinedDataTables) {
      setTimeout(() => {  // Sichergehen, dass ALLE Tabellen initialisiert sind, bevor die JoinedDataTable gebaut werden
        this.single.joinedDataTables = this.single.joinedDataTables.map((jdt) => new JoinedDataTable(tablesModel, jdt));
      }, 0);
    }
    else if (this.single.joinedDataTables === undefined) this.single.joinedDataTables = [];

    // Statistics bauen:
    if (this.single.active && this.single.statistics) {
      setTimeout(() => {  // Sichergehen, dass ALLE Tabellen initialisiert sind, bevor die Statistics gebaut werden
        this.single.statistics = this.single.statistics.map((stats) => new Statistics(tablesModel, stats));
      }, 0);
    }
    else if (this.single.statistics === undefined) this.single.statistics = [];
  }
}

export class JoinedDataTable {
  tableName = '';       // Name der Tabelle für die angezeigte JoinedData
  // Diese Tabelle muss eine Spalte mit der id: mainTable + '_id' haben. (z.B. Spalte kurse_id wenn die Haupttabelle von single 'kurse' ist)
  // Funktioniert auch mit multiSelect, also wenn im JoinedDataTable, Spalte "kurse_id" ein Array von ids gespeichert ist, 
  // ABER NUR wenn dabei UUID verwendet werden. Es wird dazu der API Filter "cs" (contains string) statt "eq" verwendet.
  title = '';           // Angezeigter Titel wenn leer => label von table
  displayedColumns: string[] = [];  // angezeigte Spalten (aus Layoutgründen: gering halten!)
  readArgs: ReadArgs;   // fixer, vordefinierter Filter / Sortierung als ReadArgs.
  actions = {           // Buttons unter JoinedDataTable
    show: false,        // JoinedData in tableComponent anzeigen. Funktioniert nur, wenn in dem dortigen Table ein entsprechender Filter definiert ist
    // add: true,       // Neuen Datensatz in JoinedDataTable hinzufügen => Funktion entfernt, weil die nötigen Daten dafür in single nicht verlässlich verfügbar sind
    // custom braucht es hier glaube ich nicht. Falls doch => erweitern!
  }
  sumCols: string[];    // Für die Anzeige der Summen im JDT
  sums: number[];
  tableModel: Table;
  stats: Statistics;    // hängt in jeder Tabellenzeile noch die in statsColumns definierten Spalten mit Statistik-Daten (ohne Diagramme) an.
  // außerdem wird der etwaig in Statistics definierte Filter oberhalb der Tabelle angezeigt.
  // In dem Fall MUSS der table von Statistics sowohl eine Spalte [JoinedDataTable]_id als auch eine [SingleTable]_id haben

  constructor(
    private tablesModel: Tables,
    init?: Partial<JoinedDataTable>,
  ) {
    Object.assign(this, init);
    this.tableModel = this.tablesModel.tables[this.tableName];
    this.readArgs = new ReadArgs(this.readArgs);
    if (this.stats) {
      this.stats = new Statistics(this.tablesModel, this.stats);
      if (!this.stats.id) this.stats.id = `jdtStats_${this.stats.tableName}`;
      this.stats.isJdtStats = true;
    }
    this.sumCols = this.tableModel.getColumnsByFilter((col: Field) => (col.showSum && this.displayedColumns.includes(col.id)));
  }
}

export class Statistics {
  id: string;           // Wichtig für die Identifizierung des zugehörigen Filters. Wenn nicht angegeben: "stats_${this.tableName}"
  tableName = '';       // Name der Tabelle für die angezeigte Statistik
  // Diese Tabelle muss eine Spalte mit der id: mainTable + '_id' haben. (z.B. Spalte kurse_id wenn die Haupttabelle von single 'kurse' ist)
  // Funktioniert auch mit multiSelect, also wenn im StatisticsTable, Spalte "kurse_id" ein Array von ids gespeichert ist, 
  // ABER NUR wenn dabei UUID verwendet werden. Es wird dazu der API Filter "cs" (contains string) statt "eq" verwendet.
  title = '';           // Angezeigter Titel wenn leer => label von table
  readArgs: ReadArgs;   // fixer, vordefinierter Filter / Sortierung als ReadArgs.
  statsColumns: string[] = [];  // angezeigte Spalten (aus Layoutgründen: gering halten!)
  filters: Filter[] = [];

  filtersReadArgs: ReadArgs;    // wird für filters verwendet
  statsReload = new Subject<void>(); // triggert Neuladen der Statistik
  tableModel: Table;

  isJdtStats = false;     // Wird (automatisch) true gesetzt, wenn es sich um eine Statistik in einem JDT handelt. Braucht es für die korrekte Filterung der Statistikdaten.

  constructor(
    private tablesModel: Tables,
    init?: Partial<Statistics>,
  ) {
    Object.assign(this, init);
    if (!this.id) this.id = `stats_${this.tableName}`;
    this.tableModel = this.tablesModel.tables[this.tableName];
    this.readArgs = new ReadArgs(this.readArgs);
    // Filter:
    if (this.filters) this.filters = this.filters.map((f) => new Filter(f));
  }
}

export class TableRights {
  readRow = false;
  readAll = false;
  updateRow = false;
  create = false;
}

export class Action {
  id: string;
  icon: string;
  buttonText?: string;            // wenn nicht vorhanden, nur Icon anzeigen (icon-button)
  color: string;                  // primary, accent, ...
  tooltip: string;
  link = (...params): Partial<Link> | null => null;
  // Verlinkung des Action-Icons (in Tabellenansicht) als Klasse Link (siehe unten)
  constructor(
    init?: Partial<Action>,
    hooks?: HooksService,
  ) {
    Object.assign(this, init);
    if (hooks !== null && hooks !== undefined) {
      this.link = hooks.configMethod('actionLink_' + this.id);
    }
  }
}

export class Link {
  /* Link für verschiedene Zwecke:
     - 'intern': so wie wir ihn für router.navigate brauchen (type: intern wird automatisch angenommen, wenn route vorhanden ist)
        router.navigate( route: [], options: { queryParams: { param1: '', ... }, ... } )
     - 'extern': eine externe URL in value
     - 'clipboard': Wert in value ins Clipboard kopieren
     - 'clipboardRow': alle Werte aus der angegeben Spalte oder Spalten (in value) ins Clipboard zu kopieren (wird direkt in table.component gemacht)
     - 'snackbar': Nachricht in Snackbar ausgeben
  */
  type: 'intern' | 'extern' | 'clipboard' | 'clipboardRow' | 'snackbar';
  value: string | string[] = '';  // für alle Typen außer intern;
  // Nur für internen Link:
  route: any[];
  options?: any;    // hier wird derzeit nur queryParams verwendet
  // Snackbar-Options:
  sbOptions?: {
    action: string,
    duration: number
  };
  // Clipboard-Options:
  cbOptions?: {
    emptyClipboardBeforeInsert?: boolean;
    copyInsertImmediately?: boolean;
  };


  constructor(init?: Partial<Link>) {
    Object.assign(this, init);
    if (init.route) {
      this.type = 'intern';
    }
  }
  followLink(router: Router, rootLoad?: LoadingService, clipboard?: ClipboardService, snackbar?: SnackbarService) {
    /* wenn rootLoad übergeben wird, bei interner Navigation Warteanimation starten */
    console.log("Follow Link type", this.type);
    // Hinweis: Typ clipboardRow wird direkt in table.component verarbeitet
    if (this.type === 'intern') {
      // internem Link folgen:
      if (rootLoad) rootLoad.set(true);
      if (this.options !== undefined) router.navigate(this.route, this.options);
      else router.navigate(this.route);
    }
    else if (this.type === 'extern') {
      // externen Link öffnen:
      window.open(this.value as string, '_blank');
    }
    else if (this.type === 'clipboard' && clipboard) {
      // In die Zwischenablage kopieren (eigene und OS):
      clipboard.addToClipboard(this.value as string, this.cbOptions?.emptyClipboardBeforeInsert, this.cbOptions?.copyInsertImmediately);
    }
    else if (this.type === 'snackbar' && snackbar) {
      snackbar.showMessage(this.value as string, this.sbOptions?.action ? this.sbOptions.action : null, this.sbOptions?.duration ? this.sbOptions.duration : null);
    }
  }
}


/*********************************************/
/* TABLES
/* Objekt für alle Tabellen:                 */
/*********************************************/

@Injectable()
export class Tables {
  tables = {};

  constructor(
    appConfigService: AppConfig,
    hooks: HooksService,
    auth: AuthenticationService,
    appError: ErrorService,
  ) {
    const config = appConfigService.getConfig();
    this.loadTables(config, hooks, auth, appError);

    // Wenn sich die Config ändert, müssen auch die Tables neu geladen werden:
    appConfigService.configChanges$.subscribe(() => {
      this.loadTables(config, hooks, auth, appError);
    });


  }

  loadTables(config, hooks, auth, appError) {
    const tableConfig = config.tableConfig;
    if (tableConfig) {
      for (const tablename of Object.keys(tableConfig)) {
        this.tables[tablename] = new Table(this, tableConfig[tablename], config.appConfig, hooks, auth, appError);
      }
    } else {
      appError.throw(1110, { snackbar: true });
    }
  }

  getTablesWithRight(type: "anonRights" | "guestRights" | "readonlyUserRights", right: string): string[] {
    const ret = [];
    for (const tablename of Object.keys(this.tables)) {
      if (this.checkTableRight(tablename, type, right)) ret.push(tablename);
    }
    return ret;
  }
  checkTableRight(tablename: string, type: "anonRights" | "guestRights" | "readonlyUserRights", right: string): boolean {
    const table: Table = this.tables[tablename];
    return table.config[type][right];
  }

}
