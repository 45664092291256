<div class="wrapper">
    <h4><span class="material-icons">content_paste</span> Zwischenablage</h4>
    <div>
        <button mat-button (click)="copy()" color="primary" matTooltip="Kopieren">
            <span class="material-icons">content_copy</span>
            <span class="button-text"> Kopieren</span>
        </button>
        <button mat-button (click)="empty()" color="primary" matTooltip="Leeren">
            <span class="material-icons">delete_outline</span>
            <span class="button-text"> Leeren</span>
        </button>
        <button mat-button (click)="close()" color="primary" matTooltip="Schließen">
            <span class="material-icons">close</span>
            <span class="button-text"> Schließen</span>
        </button>
    </div>
</div>
<textarea #textarea rows="4">{{content}}</textarea>