<div class="loading-overlay">
    <h1>Uups... diese Seite fehlt.
        <span class="material-icons">face</span>
    </h1>
    <div *ngIf="appConfig.variant !== 'ptf'" class="wrapper">
        <img src="/assets/images/kwimo-logo.png"  data-loading="eager"/>
        <div class="shine"></div>
    </div>
    <div  *ngIf="appConfig.variant === 'ptf'" class="msg">
        <p>Leider konnten wir die von dir gewünschte Seite nicht finden.</p>
    </div>
</div>
<app-background-image></app-background-image>
