
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";

import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { AppConfig } from './app/config/app.config';


if (environment.production) {
  console.log("production mode!");
  enableProdMode();
  /* Console.logs aus Production Build entfernen: */
  if (window) {
    window.console.log = () => { };
  }

  Sentry.init({

    dsn: (environment.variant === 'ptf' ? "https://05f6f011999249389fde2fe6b64334d9@o567076.ingest.sentry.io/5761680" :
      (environment.variant === 'sport' ? "" : "https://0d159c75e4a5499e8aa306406f9f433c@o567076.ingest.sentry.io/5710632")),
    release: environment.variant + "@" + environment.version,
    ignoreErrors: [  // kann verwendet werden, um bestimmte Fehler grundsätzlich auszusortieren
      'Non-Error exception captured'
    ],
    beforeBreadcrumb(breadcrumb, hint) {
      if (breadcrumb.category === 'xhr') {
        breadcrumb.data = Object.assign(breadcrumb.data, hint.xhr);
        // hint.xhr is a whole XHR object that you can use to modify breadcrumb
      }
      return breadcrumb;
    },
    beforeSend(event, hint) {
      // Nur Senden, wenn Sentry aktiviert ist
      // console.log(12, event, hint);

      if (!AppConfig?.standardConfig?.appConfig?.sentryActive) return null;
      // console.log(1, event, hint);
      /* Hier ev. weitere Fehler aussortieren, die nicht gesendet werden sollen:
         weitere Möglichkeiten: 
         - appError mit noSentryCapture: true aufrufen
         - Sentry.init ignoreErrors (siehe https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry)
      */
      // Fehler Server Response von /me vor Login 
      // 500 Server Response von guestApiLogout
      return event;
    },

  });

}



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
