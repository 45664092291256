/* HAUPTMENÜ */

import { Injectable } from '@angular/core';

import { AppConfig } from '../config/app.config';
import { AuthenticationService } from '../login/authentication.service';


@Injectable()
export class MenuService {
    appConfig: any;
    public menugroups: MenuPoint[][];  // Menüpunkte in Gruppen gruppiert
    public menulist: MenuPoint[];             // Alle Menüpunkte in einer Liste
    public invalidMenu = false;
    public isLoaded = false;
    constructor(
        private auth: AuthenticationService,
        private appConfigService: AppConfig
    ) { }


    public loadMenu() {
        /* wird beim initialen Laden der App und beim Login neu durchgeführt */
        this.appConfig = this.appConfigService.getConfig().appConfig;

        this.menugroups = [];
        this.menulist = [];
        this.invalidMenu = false;
        // Menugroups aus Config laden:
        try {
            for (const group of this.appConfig.menu) {
                let menuGroup = group as MenuPoint[];
                if (this.auth.user.isDemo) {
                    menuGroup = menuGroup.filter((mp: MenuPoint) => mp.showForDemoUser !== false);
                }
                if (menuGroup.length) {
                    this.menugroups.push(menuGroup);
                    this.menulist.push(...menuGroup);
                }
            }
            this.isLoaded = true;
        } catch {
            this.invalidMenu = true;
        }
    }

    public unloadMenu() {
        this.menugroups = [];
        this.menulist = [];
        this.isLoaded = false;
    }
}


export class MenuPoint {
    route: string;
    name: string;
    icon: string;
    mobile?= false;
    showForDemoUser?= true;
    color?= 'primary';
}
