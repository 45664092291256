/* ROOT COMPONENT
   -------------------------------
   includes Toolbar
*/

import { ChangeDetectorRef, Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { DeviceDetectorService } from 'ngx-device-detector';

import { environment } from 'src/environments/environment';
import { IAppConfig } from './config/app-config.model';
import { AppConfig } from './config/app.config';
import { DataService } from './shared/data.service';
import { AuthenticationService } from './login/authentication.service';
import { LoadingService } from './shared/loading.service';
import { MenuService } from './menu/menu.service';
import { SnackbarService } from './shared/snackbar.service';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { IdleTimeoutSheetComponent } from './shared/idle-timeout/idle-timeout-sheet.component';
import { MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: false
})

export class AppComponent implements OnInit {
  appConfig: IAppConfig;
  activeRoute = '';
  activeModule = '';
  nextTitleFromGuestToolbar = '';
  isLoggedIn = false;
  isLoginPage = false;
  isEmbedded = false;               // wenn embedded=1 als QueryParam übergeben wird, wird auch die UserToolbar nicht angezeigt
  isLoading = true;
  loadingSub$: Subscription;
  configChangesSub$: Subscription;
  idleTimer;
  idleTimeoutSheetRef: MatBottomSheetRef;
  autoLogoutSnackbarRef: MatSnackBarRef<TextOnlySnackBar>;

  username = '';
  kwiVersion = environment.version;
  public bgPic = '';
  public screenHeight: number;
  public screenWidth: number;
  public currentRouteHasUnsavedChanges: boolean;  // wird von AppComponent bei jeder Navigation zurückgesetzt
  public printTitle = '';
  public screenSize = 'XL';
  public isMobileDevice = false;

  constructor(
    private router: Router,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    public snackbar: SnackbarService,
    private deviceService: DeviceDetectorService,
    private titleService: Title,
    public auth: AuthenticationService,
    public dataService: DataService,
    public loading: LoadingService,
    private appConfigService: AppConfig,
    public appMenu: MenuService,
    private idleTimeoutSheet: MatBottomSheet,
  ) {
    this.appConfig = this.appConfigService.getConfig().appConfig;

  }

  ngOnInit() {
    this.isMobileDevice = this.deviceService.isMobile();
    if (this.isMobileDevice) {
      this.renderer.addClass(document.body, 'is-mobile-device');
    }
    this.getScreenSize();

    // Warteanimation Subscription:
    this.loadingSub$ = this.loading.subject.subscribe((value) => {
      this.isLoading = value;
      this.cdr.detectChanges();
    });
    // ConfigChanges Subscription (eigentlich nur bei Login / Logout):
    this.configChangesSub$ = this.appConfigService.configChanges$.subscribe(() => {
      console.log('configChanges');
      this.appConfig = this.appConfigService.getConfig().appConfig;
      this.cdr.detectChanges();
    });
    this.init();
    // Initial CheckUserRights und IdleTimer-Start (durch timeout sicherstellen, dass auth-Daten bereits aus Session gelesen wurden (bei reload)
    window.setTimeout(() => {
      this.dataService.checkUserRights(true);
      this.resetIdleTimer();
    }, 0);
    // CheckUserRights und Keepalive - Intervall starten:
    window.setInterval(() => this.dataService.checkUserRights(), this.appConfig.session.keepaliveInterval * 60000);


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) { // bei jedem Pfadwechsel
        // console.log('App Component / NavigationEnd', event.urlAfterRedirects);
        this.isLoginPage = event.urlAfterRedirects.startsWith('/login');
        this.init(event.urlAfterRedirects);
      }
      // Check for 'embedded' query Parameter, add css class to body
      if (event instanceof ActivationEnd) {
        const embedded = event.snapshot.queryParams['embedded'];
        if (embedded === '1') {
          this.isEmbedded = true;
          this.renderer.addClass(document.body, 'is-embedded');
          // Remove query param 'embedded'
          this.router.navigate([], {
            queryParams: { embedded: null },
            queryParamsHandling: 'merge'
          });
        }
        else this.isEmbedded = false;
      }
    });

    this.auth.userlogin$.subscribe(() => {
      this.resetIdleTimer();  // IdleTimer nach Login starten
    });
  }

  init(route?: string) {
    /* wird beim erstmaligen Laden der Komponente sowie bei jedem Pfadwechsel ausgeführt
       setzt einige wichtige "globale" Variablen */
    this.isLoggedIn = this.auth.userLoggedin;
    this.username = this.auth.user.name;
    // wenn eingeloggt, dataService initialisieren, falls dies noch nicht geschehen ist:
    if (this.isLoggedIn && !this.dataService.isInitialized) this.dataService.init();

    // Menü laden, falls notwendig:
    if (this.isLoggedIn && !this.appMenu.isLoaded) this.appMenu.loadMenu();

    // Seitentitel setzen:
    let pageTitle = this.appConfig.appTitle + (environment.variant === 'kwimo' ? ' | by Kwimo' : '');
    if (route !== undefined) {
      this.activeRoute = route;
      this.activeModule = route.split("/")[1];
      // Titel aus Hauptmenü holen
      const menuPoint = this.appMenu?.menulist?.find(l => this.isCurrentRoute(route, l.route));

      if (menuPoint !== undefined && menuPoint.name) {
        pageTitle = menuPoint.name + ' · ' + this.appConfig.appTitle + (environment.variant === 'kwimo' ? ' | by Kwimo' : '');
      }
      else if (this.nextTitleFromGuestToolbar) {
        pageTitle = this.nextTitleFromGuestToolbar + ' | by ' + environment.variantName;
      }

      console.log('Navigating to', route, this.printTitle);

    }
    this.titleService.setTitle(pageTitle);

    // UnsavedChanges zurücksetzten:
    this.auth.currentRouteHasUnsavedChanges = false;
  }

  public goTo(route, titleFromGuestToolbar?: string) {
    this.nextTitleFromGuestToolbar = titleFromGuestToolbar;
    if (this.activeRoute === route) return;
    this.activeRoute = route;
    this.loading.set(true);
    this.isLoading = true;
    // Timeout notwendig, damit Warteanimation richtig angezeigt wird:
    window.setTimeout(() => this.router.navigate([route]), 100);
  }

  logout() {
    this.loading.set(true);
    this.isLoading = true;
    this.dataService.logout();
    this.appMenu.unloadMenu();
  }

  // Schreibberechtigung anfragen
  requestWriteAccess() {
    // Zuerst sichergehen, dass wir die aktuellsten Benutzerdaten haben:
    this.auth.getUserDataFromLocalStorage();
    // interne Werte setzen:
    this.auth.forceReadonly = false;
    // in LocalStorage speichern:
    this.auth.saveUserData();
    // User Rights anpassen:
    this.dataService.checkUserRights(false, false, null, true);
  }

  // Schreibberechtigung abgeben:
  readonlyMode() {
    // Zuerst sichergehen, dass wir die aktuellsten Benutzerdaten haben:
    this.auth.getUserDataFromLocalStorage();
    if (this.auth.forceReadonly) return;
    // interne Werte setzen:
    this.auth.forceReadonly = true;
    this.auth.user.readonly = true;
    // in LocalStorage speichern:
    this.auth.saveUserData();
    // User Rights anpassen:
    this.dataService.checkUserRights(false, false, null, true);
  }

  isCurrentRoute(activeRoute: string, menuRoute: string): boolean {
    // Alle Pfadsegmente von menuRoute (vor "?") müssen mit den jeweiligen Pfadsegmenten von activeRoute übereinstimmen
    const menuRouteSegments = menuRoute.split("?")[0].split("/");
    const activeRouteSegments = activeRoute.split("?")[0].split("/");
    if (menuRouteSegments.length > activeRouteSegments.length) return false;
    let ret = true;
    for (let i = 0; i < menuRouteSegments.length; i++) {
      ret = ret && menuRouteSegments[i] === activeRouteSegments[i];
    }
    return ret;
  }

  public isMobile(): boolean {
    return this.isMobileDevice || this.screenSize === 'S';
  }

  // Bildschirmgröße abfragen
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    let newSize = 'XL'
    // CSS-Klassen in body setzen:
    if (this.appConfig?.responsive?.breakpointL && this.screenWidth <= this.appConfig.responsive.breakpointL) {
      newSize = 'L';
      this.renderer.addClass(document.body, 'kwi-size-L');
    }
    else this.renderer.removeClass(document.body, 'kwi-size-L');
    if (this.appConfig?.responsive?.breakpointM && this.screenWidth <= this.appConfig.responsive.breakpointM) {
      newSize = 'M';
      this.renderer.addClass(document.body, 'kwi-size-M');
    }
    else this.renderer.removeClass(document.body, 'kwi-size-M');
    if (this.appConfig?.responsive?.breakpointS && this.screenWidth <= this.appConfig.responsive.breakpointS) {
      newSize = 'S';
      this.renderer.addClass(document.body, 'kwi-size-S');
    }
    else this.renderer.removeClass(document.body, 'kwi-size-S');
    this.screenSize = newSize;
    console.log('Viewport changed to: ' + this.screenWidth + ' x ' + this.screenHeight + ' px. ScreenSize is', this.screenSize);
  }

  // IdleTimout:
  resetIdleTimer(): void {
    // console.log('resetIdleTimer', this.appConfig.session.idleTimeout);
    clearTimeout(this.idleTimer);
    if (this.idleTimeoutSheetRef) this.idleTimeoutSheetRef.dismiss(true);
    if (this.autoLogoutSnackbarRef) {   // Auto-Logout-Nachricht ("Du .. wurdest ... abgemeldet. ...") nach Userlogin ausblenden
      const login$ = this.auth.userlogin$.subscribe(() => {
        this.autoLogoutSnackbarRef.dismiss();
        login$.unsubscribe();
      });
    }
    if (this.appConfig.session.idleTimeout && this.auth.userLoggedin) {
      this.idleTimer = setTimeout(() => {
        this.confirmIdleTimeout();
      }, this.appConfig.session.idleTimeout * 60000);
    }
  }
  confirmIdleTimeout(): void {
    if (!this.appConfig.session.idleTimeout || !this.auth.userLoggedin) return; // Zur Sicherheit noch einmal abfragen
    this.idleTimeoutSheetRef = this.idleTimeoutSheet.open(IdleTimeoutSheetComponent);
    this.idleTimeoutSheetRef.afterDismissed().subscribe((abort: boolean) => {
      if (abort) this.resetIdleTimer();
      else {
        this.logout();
        clearTimeout(this.idleTimer);
        this.autoLogoutSnackbarRef = this.snackbar.showMessage('Du warst zu lange inaktiv und wurdest automatisch abgemeldet. Bitte melde dich erneut an!', 'OK', -1);
        this.autoLogoutSnackbarRef.afterDismissed().subscribe(() => {
          this.autoLogoutSnackbarRef = undefined;
        });
      }
    });
  }

  @HostListener('document:click', ['$event'])
  // @HostListener('document:mousemove', ['$event'])  // ev. ebenfalls verwenden, falls keypress zu ungenau ist. Aus Performance-Gründen jetzt einmal auskommentiert
  @HostListener('document:touchstart', ['$event'])
  @HostListener('document:keydown', ['$event'])
  onUserActivity() {
    setTimeout(() => this.resetIdleTimer());
  }
}
