<div id="login-container">
    <form class="login-form" #formControl="ngForm">
        <mat-card class="login-card">
            <mat-card-header>
                <div mat-card-avatar><img src="/assets/images/{{appConfig.logo || 'kwimo-logo.png'}}" data-loading="eager"></div>
                <mat-card-title>{{appConfig.appTitle}}{{isSignup ? ' - Erstanmeldung' : ''}}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p *ngIf="isVerified" class="info-msg mat-elevation-z6">
                    <i class="material-icons">done_outline</i>
                    <strong>Gratuliere!</strong> Dein Zugang ist nun freigeschaltet.<br>
                    Bitte melde dich mit dem von dir gewählten Passwort an!
                </p>
                <p *ngIf="isPWChanged" class="info-msg mat-elevation-z6">
                    <i class="material-icons">done_outline</i>
                    <strong>Gratuliere!</strong> Dein Passwort wurde geändert.<br>
                    Bitte melde dich jetzt mit deinem neuen Passwort an!
                </p>
                <ng-container *ngIf="!isSignup">
                    <mat-form-field>
                        <mat-label>Benutzername</mat-label>
                        <input matInput [(ngModel)]="username" name="username" required>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Passwort</mat-label>
                        <input matInput [(ngModel)]="password" type="password" name="password" required>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="isSignup">
                    <p>Bitte gib deinen vordefinierten Benutzernamen und E-Mail-Adresse an:
                    </p>
                    <mat-form-field>
                        <mat-label>Benutzername</mat-label>
                        <input matInput #mUser="ngModel" [(ngModel)]="username" name="username" required>
                        <mat-error *ngIf="mUser.invalid">{{cvd.getValidationErrorMsg(mUser)}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>E-Mail-Adresse</mat-label>
                        <input matInput #mail="ngModel" [(ngModel)]="email" name="email" required
                            appCustomValidation="email">
                        <mat-error *ngIf="mail.invalid">{{cvd.getValidationErrorMsg(mail)}}</mat-error>
                    </mat-form-field>
                    <p>Wähle ein Passwort! Es muss mindestens 10 Zeichen lang sein.<br>
                        Verwende nach Möglichkeit Klein- und Großbuchstaben, Ziffern und Sonderzeichen $%&_-/.:;<>
                            |#+*?&#64;!
                    </p>
                    <mat-form-field>
                        <mat-label>Passwort</mat-label>
                        <input matInput #mPw="ngModel" [(ngModel)]="pw1" type="password" name="pw1" required
                            appCustomValidation="pw" (change)="checkPwMatch(formControl.form.controls['pw2'])">
                        <mat-error *ngIf="mPw.invalid">{{cvd.getValidationErrorMsg(mPw)}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Passwort wiederholen</mat-label>
                        <input matInput #mPw2="ngModel" [(ngModel)]="pw2" type="password" name="pw2" required
                            (change)="checkPwMatch(formControl.form.controls['pw2'])">
                        <mat-error *ngIf="pw1 !== pw2">Passwörter stimmen nicht überein</mat-error>
                    </mat-form-field>
                    <p><strong>Wenn die von dir angegebenen Daten stimmen, erhältst du in Kürze ein E-Mail von uns.<br>
                            Klicke auf den Bestätigungslink in der E-Mail, um deinen Zugang
                            freizuschalten!</strong><br>&nbsp;
                    </p>
                </ng-container>

                <p *ngIf="errorMsg !== ''" class="error-msg mat-elevation-z6">
                    <i class="material-icons">error</i>
                    {{errorMsg}}
                </p>
                <p *ngIf="isMailSent" class="info-msg mat-elevation-z6">
                    <i class="material-icons">done_outline</i>
                    Das E-Mail mit dem Bestätigungslink wurde an dich gesendet.<br>
                    Du kannst dieses Fenster nun schließen.
                </p>
            </mat-card-content>
            <mat-card-actions>
                <ng-container *ngIf="!isSignup">
                    <button mat-flat-button (click)="login(formControl.valid)" [disabled]="isChecking"
                        color="{{appConfig.variant === 'ptf' ? 'primary' : 'accent'}}">Login</button><br>
                    <button *ngIf="appConfig.allowPasswordChange" mat-button (click)="changeMode(true)">Erstanmeldung /
                        Passwort ändern</button>
                </ng-container>
                <ng-container *ngIf="isSignup">
                    <button mat-flat-button (click)="signup(formControl.valid)" [disabled]="isChecking"
                        color="{{appConfig.variant === 'ptf' ? 'primary' : 'accent'}}">E-Mail anfordern</button><br>
                    <button mat-button (click)="changeMode(false)">Login mit bestehenden Zugangsdaten</button>
                </ng-container>
            </mat-card-actions>
            <mat-progress-bar *ngIf="isChecking" mode="indeterminate" color="accent"></mat-progress-bar>
        </mat-card>
    </form>
</div>
<app-background-image></app-background-image>